import React from "react";
import notfound from "../Assets/images/404.avif";

export default function NotFound() {
  return (
    <section className={`work-img `}>
      <img src={notfound} alt="" />
    </section>
  );
}
