import React, { useRef, useEffect, useState } from "react";
import "../../Components/ChatWith/ChatLayout.css";
import _http from "../../Utils/Api/_http";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Two from "./Two";
import Three from "./Three";
import "./chatgpt.css";

const Gpt = () => {
  const fileInputRef = useRef(null);
  const [pdfDataList, setPdfDataList] = useState([]);
  const [selectedPdfId, setSelectedPdfId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAns, setIsLoadingAns] = useState(false);
  const [chatHistories, setChatHistories] = useState({});
  const [fileName, setFileName] = useState("");
  const [showLocal, setShowLocal] = useState(true);
  const [bookmarks, setBookmarks] = useState([]);
  const [index, setIndex] = useState("");
  const [displayFile, setDisplayFile] = useState(null);
  const [textFile, setTextFile] = useState(null);
  const [cachedResponses, setCachedResponses] = useState({});
  const [displayedServerFileId, setDisplayedServerFileId] = useState(null);
  //1
  const getBoomarks = () => {
    setPdfDataList([]);
    _http
      .get(`/api/readbookmarks/${sessionStorage.getItem("Name")}`)
      .then((response) => {
        setBookmarks(response.data);
        if (response.data.length > 0) {
          setIndex(response.data[0].serial_no);
        }
      })
      .catch((error) => {
        console.error("Error fetching bookmarks:", error);
      });
  };

  useEffect(() => {
    getBoomarks();
  }, []);

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  // calling 1st api for file summary
  const handleFileChange = async (event) => {
    const files = event.target.files;

    if (!files || files.length === 0) {
      alert("Select one or more files.");
      return;
    }

    let allFilesValid = true;

    if (!allFilesValid) {
      return;
    }

    setIsLoading(true);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await _http.post(`/api/chat_pdf_summary`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        const pdfUrl = URL.createObjectURL(file);
        const pdfName = file.name;
        const pdfId = Date.now().toString();
        const chatMessage = {
          author: "bot",
          type: "text",
          data: { text: response.data.ai_answer || "Something went wrong" },
        };
        const newPdf = {
          id: pdfId,
          url: pdfUrl,
          name: pdfName,
          chat: [],
          file: file,
        };
        setPdfDataList((prevList) => [newPdf, ...prevList]);
        setSelectedPdfId(pdfId);

        setChatHistories((prevHistories) => ({
          ...prevHistories,
          [pdfId]: [chatMessage],
        }));
        // post new uploaded file to bookmark

        const chatBlob = new Blob([chatMessage?.data?.text], {
          type: "text/plain;charset=utf-8",
        });
        const chatFile = new File([chatBlob], "chat.txt");

        const localIndex = Math.random().toString(36).substring(2); // Generate local index
        setIndex(localIndex); // Set the local index

        try {
          const formData = new FormData();
          formData.append("id", localIndex);
          formData.append("name", sessionStorage.getItem("Name"));
          formData.append("description", "pdf File");
          formData.append("app_name", "PDF Converse");
          formData.append("pdffile", file);
          formData.append("chatfile", chatFile);
          await _http.post("/api/postbookmark", formData);
        } catch (error) {
          alert(error);
        }
      } catch (error) {
        alert(error);
      } finally {
        setIsLoading(false);
        setShowLocal(true);
      }
    }

    setIsLoading(false);
    event.target.value = null;
  };

  // this is the second api which is passing file and userquestion to api

  const updateChat = async (userInput) => {
    setIsLoadingAns(true);

    const userMessage = {
      author: "user",
      type: "text",
      data: { text: userInput },
    };

    // Update the chat history for the selected PDF with the user's message
    const updatedChatHistories = {
      ...chatHistories,
      [selectedPdfId]: [...(chatHistories[selectedPdfId] || []), userMessage],
    };

    setChatHistories(updatedChatHistories);

    const selectedPdfData = pdfDataList.find(
      (pdfData) => pdfData.id === selectedPdfId
    );

    if (!selectedPdfData) {
      setIsLoadingAns(false);
      return;
    }

    const file = selectedPdfData.file;
    const question = userInput.toString();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("question", question);

    try {
      const response = await _http.post(
        `/api/chat_pdf_userquestion`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const botmessage = response?.data?.ai_answer || "No results";

      const botMessage = {
        author: "bot",
        type: "text",
        data: { text: botmessage },
      };

      // Update the chat history for the selected PDF with the bot's message
      const updatedChatHistoriesBot = {
        ...updatedChatHistories,
        [selectedPdfId]: [
          ...(updatedChatHistories[selectedPdfId] || []),
          botMessage,
        ],
      };

      setChatHistories(updatedChatHistoriesBot);

      // Construct chat content from updated chat histories for the selected PDF
      const chatContent = updatedChatHistoriesBot[selectedPdfId].map(
        (message) => `${message.data.text}\n`
      );
      console.log(chatContent, "chatContent");
      const chatBlob = new Blob([chatContent], {
        type: "text/plain;charset=utf-8",
      });
      const chatFile = new File([chatBlob], "chat.txt");

      // Send chat history to API endpoint
      try {
        const formData = new FormData();
        formData.append("number", index); // Make sure index is defined correctly
        formData.append("name", sessionStorage.getItem("Name"));
        formData.append("chatfile", chatFile);

        await _http.post("/api/updatebook", formData);
      } catch (error) {
        alert(error);
      }

      setIsLoadingAns(false);
    } catch (error) {
      alert(error);
      setIsLoadingAns(false);
    }
  };

  const handlePdfSelect = (pdfId) => {
    setSelectedPdfId(pdfId);
  };

  // Delete bookmark for local
  const handlePdfDelete = (pdfId) => {
    const updatedPdfDataList = pdfDataList.filter(
      (pdfDataItem) => pdfDataItem.id !== pdfId
    );
    setPdfDataList(updatedPdfDataList);
    //  deleting from server
    handleDeleteBookmark(pdfId);
  };

  //from server
  const handleDeleteBookmark = async (serialNo) => {
    try {
      await _http.post("/api/deletebookmark", {
        serial_no: serialNo,
        name: sessionStorage.getItem("Name"),
      });

      const updatedBookmarks = bookmarks.filter(
        (bookmark) => bookmark.serial_no !== serialNo
      );

      setBookmarks(updatedBookmarks);

      // Check if the deleted server file matches the currently displayed server file
      if (displayedServerFileId === serialNo && !showLocal) {
        setTextFile(null);
        setDisplayFile(null);
        setDisplayedServerFileId(null); // Clear the displayed server file ID
      }
    } catch (error) {
      console.error("Error deleting bookmark:", error);
    }
  };

  // Function to update the displayed server file ID
  const updateDisplayedServerFileId = (id) => {
    setDisplayedServerFileId(id);
  };

  useEffect(() => {
    // Check if the selected PDF is not present in the updated list
    const selectedPdfExists = pdfDataList.some(
      (pdfDataItem) => pdfDataItem.id === selectedPdfId
    );

    // If the selected PDF is not present, set the next PDF as selected
    if (!selectedPdfExists && pdfDataList.length > 0) {
      const nextPdfId = pdfDataList[0].id;
      setSelectedPdfId(nextPdfId);
    }
  }, [pdfDataList, selectedPdfId]);

  useEffect(() => {
    // Check if there are no server files remaining
    if (bookmarks.length === 0) {
      // Clear selected PDF data when all server files are deleted
      setSelectedPdfId(null);
      setTextFile(null);
    }
  }, [bookmarks]);

  const showBookmark = async (index, pdf) => {
    setIndex(index);
    setFileName(pdf);

    // Check if the response is already cached
    if (cachedResponses[index]) {
      setDisplayFile(cachedResponses[index].displayFile);
      setTextFile(cachedResponses[index].textFile);
      return;
    }

    try {
      const displayFileResponse = await _http.post(
        "/api/getpdfbookmark",
        { serial_no: index, name: sessionStorage.getItem("Name") },
        { responseType: "blob" }
      );

      const textFileResponse = await _http.post(
        "/api/gettextbookmark",
        { serial_no: index, name: sessionStorage.getItem("Name") },
        { responseType: "blob" }
      );

      // Cache the response
      const newCachedResponses = {
        ...cachedResponses,
        [index]: {
          displayFile: displayFileResponse.data,
          textFile: textFileResponse.data,
        },
      };
      setCachedResponses(newCachedResponses);

      setDisplayFile(displayFileResponse.data);
      setTextFile(textFileResponse.data);
    } catch (error) {
      console.log("Error fetching files:", error);
    }
  };

  // issue
  const handelCloseLocal = () => {
    setShowLocal(false);
  };

  const Handelopenlocal = () => {
    setShowLocal(true);
  };

  return (
    <section>
      <div>
        <div className="sidebar-gpt">
          <div className="Upload-newfile-box">
            <button onClick={handleFileUpload}>
              Drag and drop <br /> or <br /> Upload a PDF file(max. 32 MB)
            </button>
            <input
              type="file"
              accept=".pdf"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              multiple
            />
          </div>

          <ul className="uploaded-files-list-section">
            <div className="local-files">
              {pdfDataList.length !== 0 && <h6>Today</h6>}
              {pdfDataList.map((pdfData) => (
                <li key={pdfData.id} onClick={Handelopenlocal}>
                  <div>
                    <div
                      className="file-name"
                      onClick={() => handlePdfSelect(pdfData.id)}
                    >
                      {pdfData.name}
                    </div>
                    <div className="delete-file-btn">
                      <button onClick={() => handlePdfDelete(pdfData.id)}>
                        <DeleteOutlineIcon />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </div>
            <div className="server-files">
              {bookmarks.length !== 0 && <h6>Recent</h6>}

              {bookmarks.map((bookmark, index) => (
                <li key={bookmark.id} onClick={handelCloseLocal}>
                  <div>
                    <div
                      className="file-name"
                      onClick={() => {
                        updateDisplayedServerFileId(bookmark.serial_no);
                        showBookmark(bookmark.serial_no, bookmarks[index].pdf);
                      }}
                    >
                      {bookmark.pdf}
                    </div>
                    <div className="delete-file-btn">
                      <button
                        onClick={() => handleDeleteBookmark(bookmark.serial_no)}
                      >
                        <DeleteOutlineIcon />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>
      </div>
      {isLoading ? (
        <div>Loading....</div>
      ) : (
        <>
          {showLocal ? (
            <div className="show-local-file">
              {pdfDataList.map(
                (fileData) =>
                  fileData.id === selectedPdfId && (
                    <Three
                      key={fileData.id}
                      fileData={fileData}
                      chatHistories={chatHistories}
                      updateChat={updateChat}
                      uniqueIdentifier={fileData.id}
                      isLoadingAns={isLoadingAns}
                    />
                  )
              )}
            </div>
          ) : (
            <div className="show-file-from-server">
              {textFile && (
                <Two
                  displayFile={displayFile}
                  textFile={textFile}
                  index={index}
                  chatHistories={chatHistories}
                  fileName={fileName}
                />
              )}
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default Gpt;
