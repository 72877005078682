import React, { useState, useEffect } from "react";
import "./Sidebar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "../../../Features/DarkMode/ThemeContext";
//Dropdownimages
import Manual from "./../../../Assets/DropDownImages/20-manual.svg";
import Waranty from "./../../../Assets/DropDownImages/Warranty.svg";
import Policies from "./../../../Assets/DropDownImages/Policies.svg";
import ERP from "./../../../Assets/DropDownImages/ERP.svg";
import CRM from "./../../../Assets/DropDownImages/CRM.svg";
import BOM from "./../../../Assets/DropDownImages/BOM.svg";
import dataplotter from "./../../../Assets/DropDownImages/ddcsv.svg";

// import DropDownData from "./DropDown/DropDown";
import RightArrow from "../../../Assets/HeroImages1/Frame 1449.svg";
import dRightArrow from "../../../Assets/HeroImages1/dsidebar.svg";
import lam from "../../../Assets/lam.svg";
import stcaklogo from "../../../Assets/stacklogo.png";

// Light images
import home from "../../../Assets//Sidebar/home.svg";
import projects from "../../../Assets/Sidebar/projects.svg";
import templates from "../../../Assets/Sidebar/templates.svg";
import apps from "../../../Assets/Sidebar/app.svg";
// dark images
import darkHome from "../../../Assets/Sidebar/DarkHome.svg";
import darkApps from "../../../Assets/Sidebar/DarkTemp.svg";
import darkProject from "../../../Assets/Sidebar/Darkprojects.svg";
import darkTemp from "../../../Assets/Sidebar/Darkapp.svg";
import { Tooltip } from "react-tooltip";
import {
  selectIsMainSidebarOn,
  toggleMainSidebar,
} from "../../../Redux/ReduceSidebar";
import { useSelector, useDispatch } from "react-redux";

export default function SidebarData({ userImage, responseData }) {
  const userName = sessionStorage.getItem("Name");
  const [companyLogo, setCompanyLogo] = useState("");
  const [isLogoImage, setIsLogoImage] = useState(false);
  const location = useLocation();
  // const SidebarData = DropDownData;
  const isMainbar = useSelector(selectIsMainSidebarOn);
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { theme } = useTheme();
  const allowedsidebar = responseData?.id === 1;
  const isMainSidebarOn = useSelector(selectIsMainSidebarOn);
  const dispatch = useDispatch();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  useEffect(() => {
    const companyData = [
      {
        companyName: "lamons.aisuite.dtskill.com",
        companyLogoName: lam,
        isImage: true,
      },
      {
        companyName: "stackteck.aisuite.dtskill.com",
        companyLogoName: stcaklogo,
        isImage: true,
      },
      {
        companyName:
          "localhost" || "aisuite.dtskill.com" || "qa.aisuite.dtskill.com",
        companyLogoName: stcaklogo,
        isImage: false,
      },
    ];

    const currentDomain = window.location.hostname;

    const selectedCompany = companyData.find(
      (company) => company.companyName === currentDomain
    );
    if (selectedCompany) {
      setCompanyLogo(selectedCompany.companyLogoName);
      setIsLogoImage(selectedCompany.isImage);
    }
  }, []);
  const handleDropdown = (item) => {
    if (item && isMainSidebarOn) {
      toggleDropdown();
    } else if (item && !isDropdownOpen) {
      dispatch(toggleMainSidebar());
      toggleDropdown();
    } else {
      return;
    }
  };
  const DropDownData = [
    {
      title: "Autodesk BIM360",
      icon: <img className="web-icon" src={Manual} alt="bio" />,
      link: "interactwith/autodesk",
      value: responseData && responseData["customai"] ? true : false,
    },
    {
      title: "Safe Digging",
      icon: <img className="web-icon" src={Manual} alt="bio" />,
      link: "interactwith/Safe_digging",
      value: responseData && responseData["customai"] ? true : false,
    },
    {
      title: "Hand-written OCR",
      icon: <img className="web-icon" src={Manual} alt="bio" />,
      link: "interactwith/Hand-written_OCR",
      value: responseData && responseData["customai"] ? true : false,
    },
    {
      title: "Data plotter",
      icon: <img className="web-icon" src={dataplotter} alt="bio" />,
      link: "/interactwith/data-plotter",
      value: responseData && responseData["chat_csv"] ? true : false,
    },
    {
      title: "LinguaLink",
      icon: <img className="web-icon" src={Manual} alt="bio" />,
      link: "interactwith/LinguaLink",
      value: responseData && responseData["customai"] ? true : false,
    },
    {
      title: "ResourceRover",
      icon: <img className="web-icon" src={ERP} alt="bio" />,
      link: "chatwith/ResourceRover",
      value: responseData && responseData["Chatpdf"] ? true : false,
    },
    {
      title: "CRMConverse",
      icon: <img className="web-icon" src={CRM} alt="bio" />,
      link: "chatwith/CRMConverse",
      value: responseData && responseData["Chatpdf"] ? true : false,
    },
    {
      title: "ManualDialogue",
      icon: <img className="web-icon" src={Manual} alt="bio" />,
      link: "chatwith/ManualDialogue",
      value: responseData && responseData["Chatpdf"] ? true : false,
    },
    {
      title: "WarrantyWords",
      icon: <img className="web-icon" src={Waranty} alt="bio" />,
      link: "chatwith/WarrantyWords",
      value: responseData && responseData["Chatpdf"] ? true : false,
    },
    {
      title: "PolicyPilot",
      icon: <img className="web-icon" src={Policies} alt="bio" />,
      link: "chatwith/PolicyPilot",
      value: responseData && responseData["Chatpdf"] ? true : false,
    },
    {
      title: "BOMBlueprint",
      icon: <img className="web-icon" src={BOM} alt="bio" />,
      link: "chatwith/BOMBlueprint",
      value: responseData && responseData["Chatpdf"] ? true : false,
    },
  ];

  const sidebarData = [
    {
      pathname: "/Home",
      text: "Home",
      imgSrc: theme === "dark" ? darkHome : home,
      alt: "side-img",
      dropdown: false,
      permission: true,
      arrowImage: false,
      tooltip: "Home",
    },
    {
      pathname: "/MyAIproject",
      text: "My AI Projects",
      imgSrc: theme === "dark" ? darkProject : projects,
      alt: "side-img",
      dropdown: false,
      permission: true,
      arrowImage: true,
      tooltip: "My AI Projects",
    },

    {
      pathname: "/PortKeyLogs",
      text: "PortKey Logs",
      imgSrc: theme === "dark" ? darkApps : apps,
      alt: "side-img",
      permission: allowedsidebar,
      dropdown: false,
      arrowImage: true,
      tooltip: "PortKeyLogs",
    },
    {
      pathname: "/TokenUsage",
      text: "Token Usage",
      imgSrc: theme === "dark" ? darkApps : apps,
      alt: "side-img",
      permission: allowedsidebar,
      dropdown: false,
      arrowImage: true,
      tooltip: "Token Usage",
    },
    {
      pathname: "/logs",
      text: "Logs",
      imgSrc: theme === "dark" ? darkTemp : templates,
      alt: "side-img",
      permission: allowedsidebar,
      dropdown: false,
      arrowImage: true,
      tooltip: "Logs",
    },

    {
      text: "Try",
      imgSrc: theme === "dark" ? darkApps : apps,
      alt: "side-img",
      permission: true,
      dropdown: true,
      arrowImage: true,
      tooltip: "Try",
    },
  ];
  const values = responseData && responseData["Chatpdf"] ? true : false;
  const permittedItems = sidebarData.filter((item) => item.permission);

  const sidebarElements = permittedItems.map((item, i) => (
    <li
      key={i}
      className={`${theme === "dark" ? "dark-back" : "light-back"} ${
        location.pathname === item.pathname
          ? `${theme === "dark" ? "darkActive" : "active"}`
          : ""
      } ${
        item.dropdown && isDropdownOpen
          ? `dropdown-link ${theme === "dark" ? "darkActive " : "active"}`
          : ""
      }`}
    >
      <Link
        className={`${theme === "dark" ? "dark-text" : "light-text"}`}
        to={item?.pathname}
        onClick={() => handleDropdown(item.dropdown)}
      >
        <div className="sidebar-link">
          <div
            data-tooltip-id="sidebar-tooltip"
            data-tooltip-content={isMainbar ? "" : item.tooltip}
          >
            <img src={item.imgSrc} alt={item.alt} className="home-img" />
            <p
              style={{ padding: "0" }}
              className={` ${theme === "dark" ? "dark-text" : "light-text"}`}
            >
              {item.text}
            </p>
          </div>
          {item.arrowImage && (
            <div
              className={`homesidebar-rightarrow ${
                item.dropdown && isDropdownOpen && "arrow-down"
              }`}
            >
              <img src={theme === "dark" ? dRightArrow : RightArrow} alt="" />
            </div>
          )}
        </div>
      </Link>
      {item.dropdown && values && isDropdownOpen ? (
        <div
          className={`sidebar-dropdown ${theme === "dark" ? "" : "drop-color"}`}
        >
          <p className="sidebar-dropdown-heading">Try our newest features</p>
          {DropDownData.map((item, index) => (
            <span key={index} onClick={() => navigate(item.link)}>
              <Link>
                <div className="sidebar-link">
                  <div style={{ height: "20px", width: "20px" }}>
                    {item.icon}
                  </div>
                  <p
                    className={`drop-title ${
                      theme === "dark" ? "dark-text" : "light-text"
                    }`}
                  >
                    {item.title}
                  </p>
                </div>
              </Link>
            </span>
          ))}
        </div>
      ) : null}
    </li>
  ));

  return (
    <aside>
      <div
        className={` showSidebar ${theme === "dark" ? "dark-header " : ""} `}
      >
        {isLogoImage ? (
          <div className="comp_logo">
            {" "}
            <img src={companyLogo} alt="companyLogo" />{" "}
          </div>
        ) : (
          ""
        )}
        <div className="side-user-info">
          <span className="user-img">
            <img src={userImage} alt="UserImg" />
          </span>
          <span
            className={`user-name  ${
              theme === "dark" ? "dark-text" : "user-name-light-text"
            }`}
          >
            {userName}
          </span>
        </div>
        <ul className="home-sidebar">{sidebarElements}</ul>
      </div>
      <Tooltip id="sidebar-tooltip" place="right" style={{ zIndex: "1" }} />
    </aside>
  );
}
