import React, { useEffect, useState } from "react";
import "./../../Components/ChatSection/ChatSection.css";
import { useTheme } from "../../Features/DarkMode/ThemeContext";
import _http from "../../Utils/Api/_http";
import closemainSidebar from "./../../Assets/group1.svg";
import openmainSidebar from "./../../Assets/group2.svg";
import openSidebar from "./../../Assets/Group 1151.svg";
import {
  selectIsSidebarOn,
  selectIsMainSidebarOn,
  toggleMainSidebar,
} from "../../Redux/ReduceSidebar";
import { useSelector, useDispatch } from "react-redux";
import { toggleSidebar, selectIsToggleOn } from "../../Redux/ReduceSidebar";
import FileViewer from "react-file-viewer";
import PdfViewer from "../Interact-With-File/ChatWith/PdfViewer";
import { InlineMath } from "react-katex";
import BookmarkChatSection from "./BookmarkChatSection";
import { Tooltip } from "react-tooltip";
const BookmarkChatWith = (props) => {
  const {
    displayFile,
    textFile,
    appname,
    fileUrl,
    index,
    apiToBeUsed,
    fileName,
    fileType,
  } = props;
  console.log(props);
  const [fileDataList, setFileDataList] = useState([]);
  const [message, setMessage] = useState(null);
  const [selectedFileId, setSelectedFileId] = useState(null);

  const [isLoadingAns, setIsLoadingAns] = useState(false);
  const [chatHistories, setChatHistories] = useState({});
  const [minimizePdfView, setMinimizePdfView] = useState(false);
  const [texContent, setTexContent] = useState(null);

  const isMainSidebarOn = useSelector(selectIsMainSidebarOn);
  const { theme } = useTheme();
  const isSideBar = useSelector(selectIsSidebarOn);
  const isToggleOn = useSelector(selectIsToggleOn);
  const dispatch = useDispatch();
  const [tokens, setTokens] = useState({
    prompt_tokens: "",
    completion_tokens: "",
    total_tokens: "",
  });

  const handelMnimizePdf = () => {
    setMinimizePdfView(!minimizePdfView);
  };

  const readFile = (displayFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(displayFile);
    });
  };

  useEffect(() => {
    const handleFileChange = async (event) => {
      const displayAsFile = new File([displayFile], "your_file", {
        type: "application/pdf",
      });

      if (appname === "Chat TEX") {
        const content = await readFile(displayAsFile);
        setTexContent(content);
      }

      const textFileAsFile = new File([textFile], "your_text_file", {
        type: "text/plain",
      });

      const displayFileUrl = { fileUrl };

      const fileName = displayAsFile.name;
      const fileId = Date.now().toString();
      // //Logs

      //     const filesize = `${(displayAsFile.size / (1024 * 1024)).toFixed(2)} MB`;

      // const currentTime = new Date().toLocaleString('en-US', { timeZoneName: 'short', hour12: true });
      // const userName = sessionStorage.getItem("Name");

      // const newLog = {
      //     currentTime,
      //     userName,
      //     application: appname,
      //     filename: fileName,
      //     filesize,
      // };

      // let storedLogs = JSON.parse(localStorage.getItem("UserLogsArray"));

      // if (!Array.isArray(storedLogs) || !storedLogs.length) {
      //     storedLogs = [];
      // }

      // storedLogs.unshift(newLog);

      // localStorage.setItem("UserLogsArray", JSON.stringify(storedLogs));

      const newPdf = {
        id: fileId,
        url: displayFileUrl,
        name: fileName,
        chat: [],
        file: displayAsFile,
      };

      setFileDataList((prevList) => [newPdf, ...prevList]);
      setSelectedFileId(fileId);

      const textContent = await readFile(textFileAsFile);

      const dataArray = textContent.split("\n,");

      if (dataArray.length === 1 && dataArray[0] === "") {
        setChatHistories({ [fileId]: [] });
        return;
      }

      dataArray.forEach((text, index) => {
        const chatMessage = {
          author: index % 2 === 0 ? "bot" : "user",
          type: "text",
          data: { text },
        };

        setChatHistories((prevHistories) => ({
          ...prevHistories,
          [fileId]: [...(prevHistories[fileId] || []), chatMessage],
        }));
      });
    };

    handleFileChange();
    console.log("File Type:", fileType);
  }, []);

  const updateChat = async (userInput) => {
    setIsLoadingAns(true);
    const userMessage = {
      author: "user",
      type: "text",
      data: { text: userInput },
    };
    setChatHistories((prevHistories) => ({
      ...prevHistories,
      [selectedFileId]: [...(prevHistories[selectedFileId] || []), userMessage],
    }));
    const selectedFileData = fileDataList.find(
      (fileData) => fileData.id === selectedFileId
    );

    if (!selectedFileData) {
      setIsLoadingAns(false);
      return;
    }
    const file = selectedFileData.file;
    const question = userInput.toString();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("question", question);
    console.log("api to be used " + apiToBeUsed);

    try {
      const response = await _http.post(apiToBeUsed, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const botmessage = response?.data?.ai_answer || "No results";

      // //Logs
      const prompt_tokens =
        response?.data?.prompt_tokens || "No prompt_tokens ";
      const completion_tokens =
        response?.data?.completion_tokens || "No completion_tokens";
      const total_tokens = response?.data?.total_tokens || "No total_tokens";

      setTokens((prevTokens) => ({
        ...prevTokens,
        prompt_tokens,
        completion_tokens,
        total_tokens,
      }));

      const botMessage = {
        author: "bot",
        type: "text",
        data: { text: botmessage },
      };

      setChatHistories((prevHistories) => ({
        ...prevHistories,
        [selectedFileId]: [
          ...(prevHistories[selectedFileId] || []),
          botMessage,
        ],
      }));

      setIsLoadingAns(false);
      setMessage({ ...botMessage, fileId: selectedFileId });
    } catch (error) {
      console.error("Error updating chat:", error);
      setIsLoadingAns(false);
    }
  };

  const updateChatHistories = (newChatHistories) => {
    setChatHistories(newChatHistories);
  };

  const CloseSidebar = () => {
    dispatch(toggleSidebar());
  };

  return (
    <section>
      <div
        className={`${isSideBar ? "sidebar" : "hideSideBar"} ${
          theme === "dark" ? "sidebar-dark-border " : "sidebar-light-border "
        } ${theme === "dark" ? "" : "light-background"}`}
      >
        {isToggleOn && (
          <div className="arrow">
            <img
              src={!isMainSidebarOn ? openmainSidebar : closemainSidebar}
              alt="icon"
              className={
                isMainSidebarOn ? "open-mainsidebar" : "close-mainsidebar"
              }
              data-tooltip-id="tooltip-arrow"
              data-tooltip-content={
                isMainSidebarOn ? "Close Sidebar" : "Open Sidebar"
              }
              onClick={() => {
                dispatch(toggleMainSidebar());
              }}
            />
          </div>
        )}

        <div className="heading-containerr">
          {" "}
          <div className="page-info-headingg">{appname}</div>
          <div
            onClick={CloseSidebar}
            data-tooltip-id="tooltip-arrow"
            data-tooltip-content={"Close"}
          >
            <img style={{ height: "21px" }} src={openSidebar} alt="icon" />
          </div>
        </div>

        <div>
          <div className="uploaded-pdfs">
            {/* {fileDataList.map((fileData) => ( */}
            <div
              //  key={fileData.id}
              className="uploaded-pdf"
            >
              <div className="pdf-info">
                <div
                  className={`uploaded-pdf-button  selected-pdf-button 
                                            `}
                  title={fileName}
                >
                  <div>{fileName}</div>
                </div>
              </div>
            </div>
            {/* ))} */}
          </div>
        </div>
      </div>

      <div style={{ display: "flex" }} className="pdf-output">
        <div>
          {!isSideBar && (
            <div className="heading-containerr1">
              <div onClick={() => dispatch(toggleSidebar())}>
                <img
                  style={{ height: "21px" }}
                  src={openSidebar}
                  alt="icon"
                  data-tooltip-id="tooltip-arrow"
                  data-tooltip-content={"Open File Uploader"}
                />
              </div>
              <div className="page-info-headingg">{appname}</div>
            </div>
          )}
          <div className={isSideBar ? "pdf-viewer" : "pdf-viewer1"}>
            <span style={{ height: "inherit" }}>
              {appname === "PDF Converse" && (
                <embed
                  src={fileUrl}
                  width="100%"
                  height="100%"
                  type="application/pdf"
                />
              )}

              {appname === "DataDialogue CSV" && (
                <div
                  style={{ height: "100%" }}
                  className={`${theme === "dark" ? "light-text" : " "} `}
                >
                  <PdfViewer url={fileUrl} fileType="csv" />
                </div>
              )}
              {appname === "TextTalk" && (
                <embed
                  src={fileUrl}
                  width="100%"
                  height="100%"
                  type="application/pdf"
                  className={`${theme === "dark" ? "light-theme" : " "} `}
                />
              )}
              {appname === "WordWhisper" && (
                <div
                  style={{ height: "calc(100vh - 67px)", overflowY: "scroll" }}
                  className={`${theme === "dark" ? "light-text" : " "} `}
                >
                  <PdfViewer url={fileUrl} fileType="docx" />
                </div>
              )}
              {appname === "SlideSage" && (
                <div
                  style={{
                    overflow: "scroll",
                    height: "100%",
                  }}
                >
                  <FileViewer
                    fileType="pptx"
                    filePath={fileUrl}
                    style={{ overflow: "auto", height: "100%" }}
                  />
                </div>
              )}
              {appname === "DataDialogue Excel" && (
                <div
                  style={{ height: "100%" }}
                  className={`${theme === "dark" ? "light-text" : " "} `}
                >
                  <PdfViewer url={fileUrl} fileType="xlsx" />
                </div>
              )}
              {appname === "JSON Jargon" && (
                <embed
                  src={fileUrl}
                  width="100%"
                  height="100%"
                  type="application/pdf"
                  className={`${theme === "dark" ? "light-theme" : " "} `}
                />
              )}
              {appname === "XML Exchange" && (
                <embed
                  src={fileUrl}
                  width="100%"
                  height="100%"
                  type="application/pdf"
                  className={`${theme === "dark" ? "light-theme" : " "} `}
                />
              )}
              {appname === "Markdown Mentor" && (
                <embed
                  src={fileUrl}
                  width="100%"
                  height="100%"
                  type="application/pdf"
                  className={`${theme === "dark" ? "light-theme" : " "} `}
                />
              )}
              {appname === "PyTalk" && (
                <embed
                  src={fileUrl}
                  width="100%"
                  height="100%"
                  type="application/pdf"
                  className={`${theme === "dark" ? "light-theme" : " "} `}
                />
              )}
              {appname === "CodeChat JS" && (
                <embed
                  src={fileUrl}
                  width="100%"
                  height="100%"
                  type="application/pdf"
                  className={`${theme === "dark" ? "light-theme" : " "} `}
                />
              )}
              {appname === "Chat TEX" && (
                <div
                  style={{ height: "100%" }}
                  className={`${
                    theme === "dark" ? "dark-text" : " light-text"
                  } `}
                >
                  {texContent && (
                    <div className="tex-viewer">
                      {/* <BlockMath>{texContent}</BlockMath> */}

                      <InlineMath>{texContent}</InlineMath>
                    </div>
                  )}
                </div>
              )}
              {appname === "LogLingo" && (
                <embed
                  src={fileUrl}
                  width="100%"
                  height="100%"
                  type="application/pdf"
                  className={`${theme === "dark" ? "light-theme" : " "} `}
                />
              )}

              {(appname === "ManualDialogue" ||
                appname === "BOMBlueprint" ||
                appname === "CRMConverse" ||
                appname === "ResourceRover" ||
                appname === "PolicyPilot" ||
                appname === "WarrantyWords") && (
                <>
                  {fileType === "pdf" && (
                    <embed
                      src={fileUrl}
                      width="100%"
                      height="100%"
                      type="application/pdf"
                    />
                  )}

                  {fileType === "xlsx" && (
                    <div
                      style={{ height: "100%" }}
                      className={`${theme === "dark" ? "light-text" : ""} `}
                    >
                      <PdfViewer url={fileUrl} fileType="xlsx" />
                    </div>
                  )}

                  {fileType === "docx" && (
                    <div
                      style={{ height: "100%" }}
                      className={`${theme === "dark" ? "light-text" : ""} `}
                    >
                      <PdfViewer url={fileUrl} fileType="docx" />
                    </div>
                  )}

                  {fileType === "txt" && (
                    <embed
                      src={fileUrl}
                      width="100%"
                      height="100%"
                      type="application/pdf"
                      className={`${theme === "dark" ? "light-theme" : ""} `}
                    />
                  )}

                  {fileType === "pptx" && (
                    <div
                      style={{
                        overflow: "scroll",
                        height: "100%",
                      }}
                    >
                      <FileViewer
                        fileType="pptx"
                        filePath={fileUrl}
                        style={{ overflow: "auto", height: "100%" }}
                      />
                    </div>
                  )}
                </>
              )}
            </span>
          </div>
        </div>

        <div style={{ height: "inherit", minWidth: "calc(100% - 450px)" }}>
          {fileDataList.map(
            (fileData) =>
              fileData.id === selectedFileId && (
                <BookmarkChatSection
                  key={fileData.id}
                  fileData={fileData}
                  message={message}
                  updateChat={updateChat}
                  onChatUpdate={updateChat}
                  chatHistories={chatHistories}
                  uniqueIdentifier={fileData.id}
                  updateChatHistories={updateChatHistories}
                  isLoadingAns={isLoadingAns}
                  index={index}
                  handelMnimizePdf={handelMnimizePdf}
                  pdfName={fileName}
                  tpromptTokens={tokens.prompt_tokens}
                  completionTokens={tokens.completion_tokens}
                  totalTokens={tokens.total_tokens}
                />
              )
          )}
        </div>
      </div>
      <Tooltip
        id="tooltip-arrow"
        place="bottom"
        style={{ fontSize: "16px", padding: "7px" }}
      />
    </section>
  );
};

export default BookmarkChatWith;
