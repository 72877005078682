import React, { useState, useEffect, useRef } from "react";
import _http from "./../../Utils/Api/_http";
import dSendIcon from "./../../Assets/ChatSectionImages/dark-send.svg";
import SendIcon from "./../../Assets/ChatSectionImages/Send.svg";
import backimage from "./../../Assets/background-gpt.svg";
import resetIcon from "./../../Assets/ChatSectionImages/resetIcon.svg";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { useTheme } from "./../../Features/DarkMode/ThemeContext";
import SnackBar from "../../Features/SnackBar";
const ChatArea = () => {
  const config = {
    loader: { load: ["input/asciimath"] },
    asciimath: {
      displaystyle: false,
      delimiters: [
        ["$", "$"],
        ["`", "`"],
      ],
    },
  };
  const [open, setOpen] = useState({ Submit: false, error: false });
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [showInitialImage, setShowInitialImage] = useState(true);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);

  const { theme } = useTheme();
  const [errors, setError] = useState("");

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };
  const handleClose = (state) => {
    setOpen({ ...state, open: false });
  };
  const handleInputChange = (e) => {
    setCurrentQuestion(e.target.value);
    const textarea = e.target;
    const rows = Math.min(Math.max(1, textarea.value.split("\n").length), 5);
    textarea.rows = rows;
  };

  // calling api for sending user question
  const handleAsk = async () => {
    textareaRef.current.rows = 1;

    if (!currentQuestion) {
      setOpen({ Submit: true });
    } else {
      setLoading(true);
      setCurrentQuestion("");
      setShowInitialImage(false);
      const questionId = Date.now();

      addChatMessage(currentQuestion, null, questionId);
      try {
        const response = await _http.post("/api/chatquestion", {
          question: currentQuestion,
        });

        const answerReceived = await response.data.ai_answer;
        updateLastChatMessage(answerReceived, questionId);
      } catch (error) {
        setOpen({ error: true });
        if (error.response && error.response?.status === 500) {
          setError("Internal server error");
        } else {
          setError(error.message || "An error occured");
        }
      } finally {
        // for user logs
        const currentTimeUTC = new Date().toISOString();

        const options = {
          hour12: false,
          timeZone: "UTC",
          timeZoneName: "short",
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };

        const currentTime = new Date(currentTimeUTC).toLocaleString(
          "en-US",
          options
        );
        const userName = sessionStorage.getItem("Name");

        const newLog = {
          currentTime,
          userName,
          application: "CUSTOM AI",
          filename: "---",
          filesize: "---",
        };

        let storedLogs = JSON.parse(localStorage.getItem("UserLogsArray"));

        if (!Array.isArray(storedLogs) || !storedLogs.length) {
          storedLogs = [];
        }

        storedLogs.unshift(newLog);

        localStorage.setItem("UserLogsArray", JSON.stringify(storedLogs));

        setLoading(false);
      }
    }
  };

  // function for calling same question again
  const handelAskRepeat = async () => {
    setLoading(true);

    try {
      const questionToAsk =
        chatMessages[chatMessages.length - 1]?.question || currentQuestion;

      const questionId = Date.now();

      addChatMessage(questionToAsk, null, questionId);

      const response = await _http.post("/api/chatquestion", {
        question: questionToAsk,
      });
      const answerReceived = await response.data.ai_answer;
      updateLastChatMessage(answerReceived, questionId);
    } catch (error) {
      setOpen({ error: true });
      if (error.response && error.response?.status === 500) {
        setError("Internal server error");
      } else {
        setError(error.message || "An error occured");
      }
    } finally {
      const currentTimeUTC = new Date().toISOString();

      const options = {
        hour12: false,
        timeZone: "UTC",
        timeZoneName: "short",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };

      const currentTime = new Date(currentTimeUTC).toLocaleString(
        "en-US",
        options
      );

      const userName = sessionStorage.getItem("Name");

      const newLog = {
        currentTime,
        userName,
        application: "Custom AI",
        filename: "Empty",
        filesize: "Empty",
      };

      let storedLogs = JSON.parse(localStorage.getItem("UserLogsArray"));

      if (!Array.isArray(storedLogs) || !storedLogs.length) {
        storedLogs = [];
      }

      storedLogs.unshift(newLog);

      localStorage.setItem("UserLogsArray", JSON.stringify(storedLogs));

      setLoading(false);
    }
  };

  const addChatMessage = (question, answer, questionId) => {
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { question, answer, id: questionId },
    ]);
  };

  const updateLastChatMessage = (answer, questionId) => {
    setChatMessages((prevMessages) => {
      const updatedMessages = prevMessages.map((msg) =>
        msg.id === questionId ? { ...msg, answer } : msg
      );
      return updatedMessages;
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages, currentQuestion]);

  return (
    <section
      className={`${theme === "dark" ? "dark-theme " : "light-background"
        } chat-area`}
    >
      <div className="chat-window">
        <span>
          {chatMessages.map((message, index) => (
            <div
              key={message.id}
              className="chat-message"
              style={{ padding: "10px 0px" }}
            >
              <div
                className={`question  ${theme === "dark"
                    ? "user-message-dark-gpt"
                    : "user-message-light-gpt"
                  }`}
              >
                {message.question.trim()}
              </div>
              {loading && message.answer === null ? (
                <div className="loader-gpt"></div>
              ) : (
                <div ref={messagesEndRef} className="answer">
                  <MathJaxContext version={3} config={config}>
                    <MathJax hideUntilTypeset={"first"}>
                      <p style={{ margin: "0" }}>
                        {message.answer || "Error getting response"}
                      </p>
                    </MathJax>
                  </MathJaxContext>
                </div>
              )}
              {index === chatMessages.length - 1 && !loading && (
                <button className="handleaskrepeat" onClick={handelAskRepeat}>
                  <img
                    style={{ height: "25px", margin: "5px" }}
                    src={resetIcon}
                    alt="reg"
                  />
                </button>
              )}
            </div>
          ))}
          {showInitialImage && (
            <div className="gpt-image">
              <div>
                <img src={backimage} alt="img" />
              </div>
              <div>
                <h4>How can I help you today?</h4>
              </div>
            </div>
          )}
        </span>
      </div>
      <div ref={messagesEndRef} />
      <div className="input-area">
        <div className="input-wrapper">
          <textarea
            ref={textareaRef}
            type="text"
            className={`textarea-ca ${theme === "dark" ? "dark-text" : "light-text"
              } ${theme === "dark" ? "dark-background" : ""}`}
            value={currentQuestion}
            onChange={handleInputChange}
            placeholder="Ask a question..."
            onKeyPress={(event) => {
              if (event.key === "Enter" && !loading && !event.shiftKey) {
                event.preventDefault(); // Prevents the default behavior of adding a newline
                handleAsk();
              }
            }}
            rows={1}
            maxrows={5}
          />
          <button onClick={handleAsk} disabled={loading} className="send-btn">
            <img
              src={`${theme === "dark" ? dSendIcon : SendIcon}`}
              alt="icon"
            />
          </button>
          {
            <SnackBar
              message="Please ask a question..."
              severity="warning"
              Open={open.Submit}
              handleClose={handleClose}
            />
          }
          {
            <SnackBar
              message={errors}
              severity={"error"}
              handleClose={handleClose}
              Open={open.error}
            />
          }
        </div>
      </div>
    </section>
  );
};

export default ChatArea;
