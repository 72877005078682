import React, { useState, useEffect, useRef } from "react";
import "./../../Components/ChatSection/ChatSection.css";
import SendIconLight from "../../Assets/ChatSectionImages/Send.svg";
import SendIconDark from "../../Assets/ChatSectionImages/dark-send.svg";
import botIcon from "./BotsonicNew 1.svg";
import { useTheme } from "../../Features/DarkMode/ThemeContext";

const Three = ({
  fileData,
  chatHistories,
  updateChat,
  uniqueIdentifier,
  isLoadingAns,
}) => {
  const chatHistory =
    fileData.id in chatHistories ? chatHistories[fileData.id] : [];
  const [messages, setMessages] = useState(fileData.chat);
  const [userInput, setUserInput] = useState("");
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);
  const { theme } = useTheme();

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };

  isLoadingAns && scrollToBottom();

  useEffect(() => {
    const updateChatMessagesHeight = () => {
      if (textareaRef.current) {
        const textareaHeight = textareaRef.current.offsetHeight;
        const chatMessagesHeight = `calc(100% - ${textareaHeight}px - 80px)`;

        document.querySelector(".chat-messages").style.height =
          chatMessagesHeight;
      }
    };

    updateChatMessagesHeight();

    window.addEventListener("resize", updateChatMessagesHeight);

    return () => {
      window.removeEventListener("resize", updateChatMessagesHeight);
    };
  }, [userInput]);

  useEffect(() => {
    if (chatHistories && chatHistories.pdfId === uniqueIdentifier) {
      setMessages((prevList) => [...prevList, chatHistories]);
    }
    scrollToBottom();
  }, [chatHistories, uniqueIdentifier]);

  const handleUserMessage = () => {
    if (userInput.trim() === "") return;

    const newMessage = {
      author: "user",
      type: "text",
      data: { text: userInput },
    };

    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);
    setUserInput("");

    // Call the updateChat function to send the user input to the API
    updateChat(userInput);
    textareaRef.current.rows = 1;

    scrollToBottom();
  };

  const handleTextareaChange = (event) => {
    setUserInput(event.target.value);
    const textarea = event.target;
    const rows = Math.min(Math.max(1, textarea.value.split("\n").length), 5);
    textarea.rows = rows;
  };

  return (
    <div className="gpt-chat-section">
      <div className={`chat-messages`}>
        {chatHistory.map((message) => (
          <div
            key={fileData.id}
            className={`chat-message ${
              message.author === "user"
                ? ` "user-message-light"
                  `
                : "bot-message"
            }  `}
          >
            <div className="msg-content">
              {message.author !== "user" ? (
                <img src={botIcon} alt="boc" />
              ) : null}

              {message.data.text.trim()}

              {message.author === "user" ? (
                <img src={SendIconLight} alt="user" />
              ) : null}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef}>
          {isLoadingAns && <div s class="loader-gpt"></div>}
        </div>
      </div>

      <div className="user-input">
        <textarea
          ref={textareaRef}
          type="text"
          placeholder="Type your questions...  "
          value={userInput}
          onChange={handleTextareaChange}
          onKeyPress={(event) => {
            if (event.key === "Enter" && !isLoadingAns && !event.shiftKey) {
              event.preventDefault();
              handleUserMessage();
            }
          }}
          rows={1}
          maxrows={5}
        />

        <button
          disabled={isLoadingAns}
          className="send-button"
          onClick={handleUserMessage}
        >
          <img
            src={theme === "dark" ? SendIconDark : SendIconLight}
            alt="icon"
          />
        </button>
      </div>
    </div>
  );
};

export default Three;
