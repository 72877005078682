import React, { useState, useEffect, useMemo } from "react";
import "./MainHome.css";
import Clouddata from "../../Assets/HeroImages/Cloud data1.svg";
import docs from "../../Assets/HeroImages/docs1.svg";
import foryou from "../../Assets/HeroImages/foryou1.svg";
import Frame1434 from "../../Assets/HeroImages/Frame 14341.svg";
import more from "../../Assets/HeroImages/Frame 14351.svg";
import Improvement from "../../Assets/HeroImages/Improvement1.svg";
import log from "../../Assets/HeroImages/Log1.svg";
import script from "../../Assets/HeroImages/Script1.svg";
import webDev from "../../Assets/HeroImages/Web development1.svg";
import lam from "../../Assets/lam.svg";
import stcaklogo from "../../Assets/stacklogo.png";
import searchicon from "../../Assets/HeroImages/Frame 1448.svg";
import dsearchicon from "../../Assets/HeroImages/SearchIcon.svg";
import bannerarrow from "../../Assets/HeroImages/BannerArrow.svg";
import { useTheme } from "../../Features/DarkMode/ThemeContext";
import { useDispatch, useSelector } from "react-redux";
import { setFilter, selectFilter } from "../../Redux/ReduceSidebar";

export default function HomeLogo() {
  const { theme } = useTheme();
  const [companyLogo, setCompanyLogo] = useState("");
  const [isLogoImage, setIsLogoImage] = useState(false);
  const dispatch = useDispatch();
  const filter = useSelector(selectFilter);
  const handleInputChange = (event) => {
    event.preventDefault();
    const searchTerm = event.target.value;
    dispatch(setFilter(searchTerm));
  };
  const companyData = useMemo(
    () => [
      {
        companyName: "lamons.aisuite.dtskill.com",
        companyLogoName: lam,
        ourCompany: "AI Suite",
        isImage: true,
      },
      {
        companyName: "stackteck.aisuite.dtskill.com",
        companyLogoName: stcaklogo,
        ourCompany: "AI Suite",
        isImage: true,
      },
      {
        companyName: "localhost",
        companyLogoName: "AI Suite Dashboard",
        isImage: false,
      },
      {
        companyName: "aisuite.dtskill.com",
        companyLogoName: "AI Suite Dashboard",
        isImage: false,
      },
      {
        companyName: "qa.aisuite.dtskill.com",
        companyLogoName: "AI Suite Dashboard",
        isImage: false,
      },
    ],
    []
  );

  useEffect(() => {
    const currentDomain = window.location.hostname;
    console.log(currentDomain + " currentDomain name from MainHome ");

    const selectedCompany = companyData.find(
      (company) => company.companyName === currentDomain
    );

    if (selectedCompany) {
      setCompanyLogo(selectedCompany.companyLogoName);
      setIsLogoImage(selectedCompany.isImage);
    }
  }, [companyData]);

  const getBackgroundGradient = () => {
    const currentDomain = window.location.hostname;
    if (currentDomain === "lamons.aisuite.dtskill.com") {
      return "lamons";
    } else if (currentDomain === "stackteck.aisuite.dtskill.com") {
      return "stackteck";
    } else {
      return "deafult-dtskill";
    }
  };

  const data = [
    {
      img: foryou,
      name: "For you",
      link: "",
    },
    {
      img: docs,
      name: "Docs",
      link: "WordWhisper",
    },
    {
      img: Clouddata,
      name: "Data",
      link: "DataSpeak",
    },
    {
      img: webDev,
      name: "Code",
      link: "Pytalk",
    },
    {
      img: script,
      name: "Script",
      link: "CodeChat JS",
    },
    {
      img: log,
      name: "Logs",
      link: "LogLingo",
    },
    {
      img: Improvement,
      link: "MetaMorph",
      name: "Webformats",
    },
    {
      img: Frame1434,
      link: "Chat with AI",
      name: "Websites",
    },
    {
      img: more,
      link: " ",
      name: "More",
    },
  ];

  return (
    <div
      className={`${theme === "dark" ? "dark-background" : ""} 
      ${theme === "dark" ? "dark-border " : ""} 
      ${
        theme === "dark"
          ? "dark-main-home-section-one"
          : "main-home-section-one"
      } 
       ${getBackgroundGradient()}`}
    >
      <div className="main-logos">
        <div className="main-home-CompanyLogo">
          {isLogoImage ? (
            <>
              <img src={companyLogo} alt="CompanyLogo" />
              <div
                className={`ourLogo ${
                  theme === "dark" ? "dark-text" : "light-text"
                }`}
              >
                AISuite
              </div>
            </>
          ) : (
            <span className={`ourLogo `}>
              {companyData.find(
                (company) => company.companyLogoName === companyLogo
              )?.ourCompany || "AI Suite Dashboard"}
            </span>
          )}
        </div>
      </div>
      <div
        className={`search-bar  ${
          theme === "dark" ? "dark-theme" : "light-theme"
        }`}
      >
        <img
          className="search-mobile"
          src={theme === "dark" ? dsearchicon : searchicon}
          alt="searchicon"
        />
        <input
          className={`${theme === "dark" ? "dark-theme" : ""} ${
            theme === "dark" ? "dark-border" : ""
          } input-text`}
          type="text"
          placeholder="What would you like to do with our AIsuite"
          onChange={handleInputChange}
          value={filter}
        />
      </div>

      <div className="main-home-logos">
        {data.map((dataItem, index) => (
          <div onClick={() => dispatch(setFilter(dataItem?.link))} key={index}>
            <li key={index} className="main-home-lists">
              <div
                className={`main-home-icons ${
                  theme === "dark" ? "dark-theme " : "light-background"
                }`}
              >
                <img src={dataItem.img} alt="img" />
              </div>

              <div
                className={`main-home-icons-name ${
                  theme === "dark" ? "dark-text-white" : "dark-text"
                }`}
              >
                {dataItem.name}
              </div>
              {dataItem?.link === filter && (
                <img src={bannerarrow} alt="icon" style={{ height: "12px" }} />
              )}
            </li>
          </div>
        ))}
      </div>
    </div>
  );
}
