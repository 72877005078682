import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "./Features/DarkMode/ThemeContext";
import { UserDataProvider } from "./Pages/Login-Auth/UserDataProvider";
import { Provider } from "react-redux";
import { store, persistor } from "./Redux/MyStore";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider>
        <UserDataProvider>
          <App />
        </UserDataProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);
