// this tool is same as chatplots

import React, { useState, useEffect, useRef } from "react";
import _http from "../../Utils/Api/_http";
import dSendIcon from "./../../Assets/ChatSectionImages/dark-send.svg";
import SendIcon from "./../../Assets/ChatSectionImages/Send.svg";
import backimage from "./../../Assets/background-gpt.svg";
import { useTheme } from "../../Features/DarkMode/ThemeContext";
import AttachFileTwoToneIcon from "@mui/icons-material/AttachFileTwoTone";
import deleteIcon from "../../Assets/ChatSectionImages/deleteIcon.svg";
import "./../GptPrompt/customPrompt.css";
import JSZip from "jszip";
import SnackBar from "../../Features/SnackBar";
import "./CANbus.css";
import _nonAuthHttp from "./../../Utils/Api/_nonAuthHttp";

const CANbus = () => {
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [loadingQuestions, setLoadingQuestions] = useState([]);
  const [showInitialImage, setShowInitialImage] = useState(true);
  const [Cfile, setFile] = useState([]);
  const [fileName, setFileName] = useState("");
  const [hide, setHide] = useState(false);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const { theme } = useTheme();
  const [open, setOpen] = useState({ Submit: false, error: false });
  const [errors, setError] = useState("");
  // const [selectModel, setSelectModel] = useState("");
  const textareaRef = useRef(null);

  const handleClose = (state) => {
    setOpen({ ...state, open: false });
  };
  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setCurrentQuestion(value);
    const textarea = e.target;
    const rows = Math.min(Math.max(1, textarea.value.split("\n").length), 5);
    textarea.rows = rows;
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };

  //calling 1st api for file summary
  const handleFileChange = async (e) => {
    setHide(true);
    const selectedFiles = e.target.files;
    const file = selectedFiles[0];

    if (!file) {
      alert("select file");
      return;
    }
    const fileName = file.name;
    setFileName(fileName.slice(0, 10));
    setFile(selectedFiles);
    setLoading(true);
    setCurrentQuestion("");
    setLoadingQuestions([...loadingQuestions, true]);
    setShowInitialImage(false);

    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await _http.post("/api/csvdatasummary", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      addChatMessage("", response.data.ai_answer);
    } catch (error) {
      setOpen({ error: true });
      if (error.response && error.response?.status === 500) {
        setError("Internal server error");
      } else {
        setError(error.message || "An error occured");
      }
    } finally {
      setLoading(false);
      setLoadingQuestions((prevLoadingQuestions) =>
        prevLoadingQuestions.slice(1)
      );
    }
  };

  const handelDelete = () => {
    setFile([]);
    setHide(false);
  };

  // calling 2nd api for sending user question

  const handleAsk = async () => {
    textareaRef.current.rows = 1;

    if (Cfile.length === 0) {
      setOpen({ Submit: true });
      setError("Please select a file");
      return;
    } else if (!currentQuestion.trim()) {
      setOpen({ Submit: true });
      setError("Please enter a question");
      return;
    } else {
      setHide(true);

      setLoading(true);
      setCurrentQuestion("");
      setLoadingQuestions([...loadingQuestions, true]);
      setShowInitialImage(false);

      try {
        const formData = new FormData();
        // console.log(currentQuestion, " currentQuestion");
        formData.append("question", currentQuestion);
        // formData.append("selectModel", selectModel);
        // formData.append("file", Cfile[0]);

        const response = await _http.post("/api/datavisualise", formData, {
          responseType: "blob",
        });

        const contentType = response.headers["content-type"];

        if (contentType === "application/zip") {
          const zipFile = response.data;
          // console.log(zipFile, "zipFile");
          const zip = new JSZip();
          await zip.loadAsync(zipFile);

          const contents = [];

          await Promise.all(
            Object.keys(zip.files).map(async (fileName) => {
              const file = zip.files[fileName];
              const content = await file.async("base64");
              contents.push({
                name: fileName,
                content,
              });
            })
          );

          // console.log(contents, "contents");
          addChatMessage(currentQuestion, contents);
        } else if (contentType === "application/json") {
          // again changing blob response to json
          const reader = new FileReader();
          reader.onload = function () {
            const responseData = JSON.parse(reader.result);
            // console.log(responseData, "responseData json");

            if (responseData && responseData.ai_answer) {
              addChatMessage(currentQuestion, responseData.ai_answer);
            } else {
              addChatMessage(currentQuestion, "No AI answer available");
            }
          };
          reader.readAsText(response.data);
        } else {
          addChatMessage(currentQuestion, "No AI answer available");
        }
      } catch (error) {
        setOpen({ error: true });
        if (error.response && error.response?.status === 500) {
          setError("Internal server error");
        } else {
          setError(error.message || "An error occured");
        }
      } finally {
        // for user logs
        const currentTimeUTC = new Date().toISOString();
        const options = {
          hour12: false,
          timeZone: "UTC",
          timeZoneName: "short",
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };

        const currentTime = new Date(currentTimeUTC).toLocaleString(
          "en-US",
          options
        );

        const file = Cfile[0];
        const filename = file?.name?.slice(0, 20) || "---";
        const filesize =
          `${(file?.size / (1024 * 1024)).toFixed(2)} MB` || "---";

        const userName = sessionStorage.getItem("Name");

        const newLog = {
          currentTime,
          userName,
          application: "Chat with MF4",
          filename,
          filesize,
        };

        let storedLogs = JSON.parse(localStorage.getItem("UserLogsArray"));

        if (!Array.isArray(storedLogs) || !storedLogs.length) {
          storedLogs = [];
        }

        storedLogs.unshift(newLog);

        localStorage.setItem("UserLogsArray", JSON.stringify(storedLogs));

        setLoading(false);
        setLoadingQuestions((prevLoadingQuestions) =>
          prevLoadingQuestions.slice(1)
        );
      }
    }
  };

  const addChatMessage = (question, answer) => {
    setChatMessages((prevMessages) => [...prevMessages, { question, answer }]);
  };

  const handelContentDownload = async () => {
    try {
      const res = await _nonAuthHttp.get("/api/downloadcsv", {
        responseType: "blob",
      });
      const blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "decoded_csv.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handelImgDownload = async () => {
    try {
      const res = await _nonAuthHttp.get("/api/downloadimages", {
        responseType: "blob",
      });
      const blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "plot_Images.zip";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages, currentQuestion]);

  return (
    <section
      className={`${
        theme === "dark" ? "dark-theme " : "light-background"
      } chat-area`}
    >
      {/* <span className="select-model">
          <select
            className="form-select"
            aria-label="Default select example"
            value={selectModel}
            onChange={(e) => {
              setSelectModel(e.target.value);
            }}
          >
            <option selected>Open this</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </span> */}
      <div className="chat-window">
        <span>
          {chatMessages.map((message, index) => (
            <div
              key={index}
              className="chat-message"
              style={{ padding: "10px 0px" }}
            >
              {message.question === "" ? (
                <div className="content-dis-div">
                  <button
                    onClick={handelContentDownload}
                    className="btn btn-secondary  btn-secondary content-dis "
                  >
                    Download Decoded CSV
                  </button>
                </div>
              ) : (
                <div
                  className={`question ${
                    theme === "dark"
                      ? "user-message-dark-gpt"
                      : "user-message-light-gpt"
                  }`}
                >
                  {message.question.trim()}
                </div>
              )}
              <div ref={messagesEndRef} className="answer">
                {Array.isArray(message.answer) ? (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {message.answer.map((imageData, i) => (
                      <div
                        key={i}
                        style={{
                          width: "50%",
                          boxSizing: "border-box",
                          padding: "5px",
                        }}
                      >
                        <img
                          style={{
                            width: "100%",
                            height: "auto",
                          }}
                          src={`data:image/png;base64,${imageData.content}`}
                          alt={imageData.name}
                        />
                        {i === message.answer.length - 1 && (
                          <button
                            onClick={handelImgDownload}
                            className="btn btn-secondary my-3 "
                          >
                            Download plots
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                ) : message.answer.startsWith("data:image") ? (
                  <div>
                    <img src={message.answer} alt="Plot_image" />
                    <button
                      onClick={handelImgDownload}
                      className="btn btn-primary  "
                    >
                      Download plots startsWith
                    </button>
                  </div>
                ) : (
                  <p style={{ margin: "0" }}>{message.answer}</p>
                )}
              </div>
            </div>
          ))}

          {showInitialImage && (
            <div className="gpt-image">
              <div>
                <img src={backimage} alt="img" />
              </div>
              <div>
                <h4>How can I help you today?</h4>
              </div>
            </div>
          )}
        </span>
        {loadingQuestions.length > 0 && (
          <div ref={messagesEndRef}>
            <div className="loader-gpt"></div>
          </div>
        )}
      </div>

      <div
        className={`${
          hide
            ? "custom-prompt-input-area-without-pdf"
            : "custom-prompt-input-area-with-pdf"
        }`}
      >
        {hide ? (
          <>
            <span className="file-name mx-2">
              {fileName}
              <button className="px-3 del-btn" onClick={handelDelete}>
                <img src={deleteIcon} alt="deleteIcon" />
              </button>
            </span>
          </>
        ) : null}
        <div className="input-wrapper">
          <div className="files-section">
            <label htmlFor="fileInput" style={{ display: "none" }}>
              <input
                type="file"
                id="fileInput"
                ref={fileInputRef}
                onChange={handleFileChange}
                multiple
                accept=".csv, .mf4 , .zip"
              />
            </label>
            <button onClick={handleIconClick}>
              <AttachFileTwoToneIcon />
            </button>
          </div>
          <textarea
            ref={textareaRef}
            type="text"
            className={`textarea-ca1 ${
              theme === "dark" ? "dark-text" : "light-text"
            } ${theme === "dark" ? "dark-background" : ""}`}
            value={currentQuestion}
            onChange={handleInputChange}
            placeholder="Ask a question..."
            onKeyPress={(event) => {
              if (event.key === "Enter" && !loading && !event.shiftKey) {
                event.preventDefault(); // Prevents the default behavior of adding a newline
                handleAsk();
              }
            }}
            rows={1}
            maxrows={5}
          />
          <button className="send-btn" onClick={handleAsk} disabled={loading}>
            <img src={`${theme === "dark" ? dSendIcon : SendIcon}`} alt="img" />
          </button>
          {
            <SnackBar
              message={errors}
              severity="warning"
              Open={open.Submit}
              handleClose={handleClose}
            />
          }
          {
            <SnackBar
              message={errors}
              severity={"error"}
              handleClose={handleClose}
              Open={open.error}
            />
          }
        </div>
      </div>
    </section>
  );
};

export default CANbus;
