//General Import
import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import _http from "./Utils/Api/_http";
import { useTheme } from "./Features/DarkMode/ThemeContext";
import { useUserData } from "./Pages/Login-Auth/UserDataProvider";
import Home from "./Pages/Home-page/Home";
import NotFound from "./Features/NotFound";
import NotAccess from "./Features/NotAccess";
import Settings from "./Pages/Home-page/NavBar/Settings";
import {
  ProtectedRoutes,
  AdminProductedRoutes,
} from "./Utils/Auth/ProtectedRoutes";
import NavbarController from "./Pages/Home-page/NavBar/NavbarController";
import Notification from "./Pages/Home-page/NavBar/Notification";
//Login
import Login from "./Pages/Login-Auth/Login";
import Register from "./Pages/Login-Auth/Register";
import EmailVerify from "./Pages/Login-Auth/EmailVerify";
import PasswordReset from "./Pages/Login-Auth/PasswordReset";

//Bookmark
import DisplayBookmark from "./Pages/MyAIProjects/DisplayBookmark";
import SetBookmark from "./Pages/MyAIProjects/SetBookmark";
import BookmarkChatSection from "./Pages/MyAIProjects/BookmarkChatSection";
import UserLogs from "./Pages/User-Logs/User-Logs-QA/UserLogs";
import TokenUsage from "./Pages/User-Logs/Token Usage/TokenUsage";
import GenerativeAI from "./Pages/GenerativeAI/GenerativeAI";
import dynamicRoutes from "./Pages/RoutingData/RoutingData";
import PortLogs from "./Pages/PortKeyLogs/PortLogs";
import One from "./Pages/ChatGPT/One";

function App() {
  const { theme } = useTheme();
  const [dataFetched, setDataFetched] = useState(false);
  const { updateResponseData } = useUserData();
  const { responseData } = useUserData();

  useEffect(() => {
    if (theme === "dark") {
      document.body.classList.add("dark-theme", "text-white");
    } else {
      document.body.classList.add("light-theme", "text-dark");
    }
  }, [theme]);

  // calling user app api from here if user login with specific username ,
  // we will show them tools according to it
  useEffect(() => {
    const isLoggedIn = sessionStorage.getItem("key") === "UserLogedIN";

    if (isLoggedIn && !responseData) {
      const fetchData = async (name) => {
        try {
          const response = await _http.post("/api/user_app", {
            username: name,
          });
          const responseData = response.data[0];
          updateResponseData(responseData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      const username = sessionStorage.getItem("Name");
      if (!dataFetched) {
        fetchData(username, updateResponseData);
        setDataFetched(true);
      }
    }
  }, [responseData, updateResponseData, dataFetched]);

  //maping routes
  const generateDynamicRoutes = () => {
    return dynamicRoutes.map(({ path, prop, component }) => {
      const hasAccess = responseData && responseData[prop];
      const routePath = path;
      return (
        <Route
          key={path}
          path={routePath}
          element={hasAccess ? component : <NotAccess />}
        />
      );
    });
  };

  return (
    <>
      <Router>
        <NavbarController
          allowedPaths={[
            "/Login",
            "/Register",
            "/PasswordReset",
            "/EmailVerify",
          ]}
        ></NavbarController>

        {/* NonAuth Routes */}

        <Routes>
          <Route path="/" element={<Navigate to="/Login" />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/EmailVerify" element={<EmailVerify />} />
          <Route path="/reset-password/*" element={<PasswordReset />} />
          <Route path="/Register" element={<Register />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/NotFound" element={<NotAccess />} />
          <Route
            path="/"
            element={<ProtectedRoutes responseData={responseData} />}
          >
            <Route path="Home" element={<Home />} />
            <Route path="MyAIproject" element={<DisplayBookmark />} />
            <Route path="Settings" element={<Settings />} />
            <Route exact path="SetMyAIproject" element={<SetBookmark />} />
            <Route path="/" element={<AdminProductedRoutes />}>
              <Route path="logs" element={<UserLogs />} />
              <Route path="TokenUsage" element={<TokenUsage />} />
              <Route path="PortKeyLogs" element={<PortLogs />} />
              <Route path="demo" element={<One />} />
            </Route>
            <Route path="generativeAI" element={<GenerativeAI />} />
            <Route path="Notification" element={<Notification />} />

            <Route
              exact
              path="BookmarkChatSection"
              element={<BookmarkChatSection />}
            />
            {generateDynamicRoutes()}
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
