import React, { useEffect, useState, useRef } from "react";
import "./autodesk.css";
import ReactPaginate from "react-paginate";
import yes from "../../Assets/autodesk/Check.svg";
import no from "../../Assets/autodesk/inprocess.svg";
import _http from "../../Utils/Api/_http";

function Autodesk() {
  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 10;
  const [Loading, setLoading] = useState(true);
  const isMounted = useRef(true);

  // Pagination
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayedData = tableData.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );

  const pageCount = Math.ceil(tableData.length / itemsPerPage);

  const fetchData = async () => {
    try {
      const response = await _http.get(
        "https://dev.dtskill.com/api/autodesk_bim360"
      );
      const Data = response.data;
      if (isMounted.current) {
        setTableData(
          Data.sort(
            (a, b) =>
              new Date(b.email_received_time) - new Date(a.email_received_time)
          )
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => {
      clearInterval(interval);
      isMounted.current = false;
    };
  }, []);

  return (
    <section className={`safe-digging-back `} style={{ overflowX: "scroll" }}>
      <div>
        <div className="safe-digging-header">
          <div className="uplaod-box">
            <div className="uplaod-box-heading">Process steps</div>

            <div className="autodesk-box">
              This bot automatically captures the incoming emails and uploads
              the drawings in the respective folder under each project. To see a
              demo, send an email to bim360auto@dtskill.com.
            </div>
          </div>
        </div>
        <div>
          <table className="custom-table">
            <thead className="custom-table-header ">
              <th>Email received time</th>
              <th>Email receive from</th>
              <th>Subject</th>
              <th>No of attachments</th>
              <th>Project name</th>
              <th>Type of drawing</th>
              <th>Email extraction</th>
              <th>Uploading docs in BIM 360</th>
              <th>Transmittals</th>
              <th>Overall status</th>
            </thead>
            <tbody className="custom-table-body">
              <tr class="gap-row-header"></tr>
              {Loading ? (
                <>
                  {[...Array(5)].map((_, rowIndex) => (
                    <tr key={rowIndex}>
                      {[...Array(14)].map((_, colIndex) => (
                        <td key={colIndex} className="placeholder-glow">
                          <div className="placeholder col-12 my-2">..</div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  {displayedData.map((rowData) => (
                    <>
                      <tr key={rowData.id} className="custom-table-row">
                        <td>{rowData.email_received_time} </td>
                        <td>{rowData.email_receive_from} </td>
                        <td>{rowData.subject} </td>
                        <td>{rowData.no_of_attachment} </td>
                        <td>{rowData.project_name} </td>
                        <td>{rowData.type_drawing} </td>
                        <td>
                          {rowData.email_extraction === "Yes" ? (
                            <img src={yes} alt="Yes" />
                          ) : (
                            <img src={no} alt="No" />
                          )}
                        </td>
                        <td>
                          {rowData.uploading_docs === "Yes" ? (
                            <img src={yes} alt="Yes" />
                          ) : (
                            <img src={no} alt="No" />
                          )}
                        </td>
                        <td>
                          {rowData.transmittals === "Yes" ? (
                            <img src={yes} alt="Yes" />
                          ) : (
                            <img src={no} alt="No" />
                          )}
                        </td>
                        {/* Status column */}
                        <td>
                          {rowData.email_extraction === "Yes" &&
                          rowData.uploading_docs === "Yes" &&
                          rowData.transmittals === "Yes" ? (
                            <img src={yes} alt="Yes" />
                          ) : (
                            <img src={no} alt="No" />
                          )}
                        </td>
                      </tr>

                      <tr class="gap-row"></tr>
                    </>
                  ))}
                </>
              )}
            </tbody>
          </table>
          {Loading ? null : (
            <div className="pagination-container">
              <ReactPaginate
                pageCount={pageCount}
                onPageChange={handlePageChange}
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                containerClassName="pagination-container"
                pageClassName="pagination-page"
                activeClassName="pagination-active"
                breakClassName="pagination-break"
                previousClassName="pagination-previous"
                nextClassName="pagination-next"
                previousLinkClassName={"page-next-prev"}
                nextLinkClassName={"page-next-prev"}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Autodesk;
