import React, { useState } from "react";
import { useTheme } from "../../../Features/DarkMode/ThemeContext";

const Notification = () => {
  const { theme } = useTheme();
  const [notifications, setNotifications] = useState([
    { id: 1, text: "This is a sample notification 1", marked: false },
    { id: 2, text: "This is a sample notification 2", marked: false },
  ]);
  const [editingNotification, setEditingNotification] = useState(false);

  // const isEditButtonDisabled = notifications.length === 0;

  const handleDeleteClick = () => {
    const updatedNotifications = notifications.filter(
      (notification) => !notification.marked
    );
    setNotifications(updatedNotifications);
  };

  const editMark = () => {
    const updatedNotifications = notifications.map((notification) => ({
      ...notification,
      marked: !notification.marked,
    }));
    setNotifications(updatedNotifications);
  };

  const handleCheckboxChange = (id) => {
    const updatedNotifications = notifications.map((notification) => {
      if (notification.id === id) {
        return {
          ...notification,
          marked: !notification.marked,
        };
      }
      return notification;
    });
    setNotifications(updatedNotifications);
  };

  return (
    <section
      className={`${
        theme === "dark" ? "dark-theme " : "light-background"
      } settings `}
    >
      <div
        className={`${
          theme === "dark" ? "dark-header" : "light-header"
        } wrapper  mt-sm-5`}
      >
        <h4
          className={`${
            theme === "dark" ? "dark-text" : "light-text"
          } pb-4 border-bottom justify-content-between`}
          style={{ width: "100%" }}
        >
          Notification
        </h4>
        <span>
          {editingNotification && notifications.length > 0 && (
            <>
              <button
                className="btn btn-sm btn-danger ms-2"
                onClick={handleDeleteClick}
              >
                Delete
              </button>
              <button
                className="btn btn-sm btn-warning ms-2"
                onClick={editMark}
              >
                {notifications.some((notification) => notification.marked)
                  ? "Unmark"
                  : "Mark"}
              </button>
              <button
                className="btn btn-sm btn-secondary ms-2"
                onClick={() => setEditingNotification(false)}
              >
                Close
              </button>
            </>
          )}
          {!editingNotification && notifications.length > 0 && (
            <button
              className="btn btn-sm btn-primary ms-2"
              onClick={() => setEditingNotification(true)}
            >
              Edit
            </button>
          )}
          {notifications.length === 0 && <p>No Notification to display</p>}
        </span>

        <div className="d-flex flex-column py-3 border-bottom">
          {notifications.map((notification) => (
            <div
              key={notification.id}
              className="d-flex justify-content-between align-items-center mb-3"
            >
              {editingNotification && (
                <input
                  type="checkbox"
                  checked={notification.marked}
                  onChange={() => handleCheckboxChange(notification.id)}
                />
              )}
              <p className={`${theme === "dark" ? "dark-text" : "light-text"}`}>
                {notification.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Notification;
