import React, { useState, useRef, useEffect } from "react";
import "./HomeSlideData.css";
import Card from "./Card/Card";
import { useTheme } from "../../../Features/DarkMode/ThemeContext";
import { useUserData } from "./../../Login-Auth/UserDataProvider";

import Manual from "../../../Assets/HomeSlide2images/Frame 1462.svg";
import Waranty from "../../../Assets/HomeSlide2images/Frame 1462 (1).svg";
import Policies from "../../../Assets/HomeSlide2images/Frame 1462 (2).svg";
import ERP from "../../../Assets/HomeSlide2images/Frame 1462 (3).svg";
import CRM from "../../../Assets/HomeSlide2images/crm 3.svg";
import BOM from "../../../Assets/HomeSlide2images/Frame 1462 (4).svg";
// import ERPtwo from "../../../Assets/HomeSlide2images/Frame 1462.svg";
import rightArrow from "../../../Assets/HeroImages/rightArrow.png";
import leftArrow from "../../../Assets/HeroImages/leftArrow.png";
import csv from "./../../../Assets/images/csv.svg";

import dManual from "../../../Assets/HomeSlide2images/dmanual.svg";
import dWaranty from "../../../Assets/HomeSlide2images/dwarranty.svg";
import dPolicies from "../../../Assets/HomeSlide2images/dPolicies.svg";
import dERP from "../../../Assets/HomeSlide2images/derm.svg";
import dCRM from "../../../Assets/HomeSlide2images/dcrm.svg";
import dBOM from "../../../Assets/HomeSlide2images/dbom.svg";
import dcsv from "./../../../Assets/images/dcsv.svg";

// import dERPtwo from "../../../Assets/HomeSlide2images/derp.svg";

// import drightArrow from "../../../Assets/HeroImages/rightArrow.png";
// import dleftArrow from "../../../Assets/HeroImages/leftArrow.png";

export default function HomeSlideDataTwo() {
  const { theme } = useTheme();
  const containerRef = useRef(null);
  const { responseData } = useUserData();
  const [showLeftArrow, setShowLeftArrow] = useState(false);

  const SidebarData = [
    {
      title: "Autodesk BIM360",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dManual : Manual}
          alt="bio"
        />
      ),
      link: "/interactwith/autodesk",
      value: responseData && responseData["customai"] ? true : false,

      short_disc: "LinguaLink: AI-Powered Translation Maestro ",
    },
    {
      title: "Safe Digging",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dManual : Manual}
          alt="bio"
        />
      ),
      link: "/interactwith/Safe_digging",
      value: responseData && responseData["customai"] ? true : false,

      short_disc: "LinguaLink: AI-Powered Translation Maestro ",
    },
    {
      title: "Hand-written OCR",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dManual : Manual}
          alt="bio"
        />
      ),
      link: "/interactwith/Hand-written_OCR",
      value: responseData && responseData["customai"] ? true : false,

      short_disc: "LinguaLink: AI-Powered Translation Maestro ",
    },
    {
      title: "Data plotter",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dcsv : csv}
          alt="bio"
        />
      ),
      link: "/interactwith/data-plotter",
      value: responseData && responseData["chat_csv"] ? true : false,
      short_disc: "DataDialogue CSV: Your AI-Powered CSV Conversationalist ",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "LinguaLink",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dManual : Manual}
          alt="bio"
        />
      ),
      link: "/interactwith/LinguaLink",
      value: responseData && responseData["customai"] ? true : false,

      short_disc: "LinguaLink: AI-Powered Translation Maestro ",
    },
    {
      title: "ResourceRover",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dERP : ERP}
          alt="bio"
        />
      ),
      link: "/chatwith/ResourceRover",
      value: responseData && responseData["Chatpdf"] ? true : false,

      short_disc: "ResourceRover: AI-Driven ERP Navigator ",
    },
    {
      title: "CRMConverse",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dCRM : CRM}
          alt="bio"
        />
      ),
      link: "/chatwith/CRMConverse",
      value: responseData && responseData["Chatpdf"] ? true : false,

      short_disc: "CRMConverse: Customer Relationship Chat Facilitator ",
    },
    {
      title: "ManualDialogue",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dManual : Manual}
          alt="bio"
        />
      ),
      link: "/chatwith/ManualDialogue",
      value: responseData && responseData["Chatpdf"] ? true : false,

      short_disc: "ManualDialogue: Interactive Documentation Assistant",
    },
    {
      title: "WarrantyWords",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dWaranty : Waranty}
          alt="bio"
        />
      ),
      link: "/chatwith/WarrantyWords",
      value: responseData && responseData["Chatpdf"] ? true : false,

      short_disc: "WarrantyWords: Detailed Warranty Conversationalist",
    },
    {
      title: "PolicyPilot",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dPolicies : Policies}
          alt="bio"
        />
      ),
      link: "/chatwith/PolicyPilot",
      value: responseData && responseData["Chatpdf"] ? true : false,

      short_disc: "PolicyPilot: AI Policy Guidance Expert  ",
    },
    {
      title: "BOMBlueprint",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dBOM : BOM}
          alt="bio"
        />
      ),
      link: "/chatwith/BOMBlueprint",
      value: responseData && responseData["Chatpdf"] ? true : false,

      short_disc: "BOMBlueprint: Automated Bill of Materials Architect ",
    },
  ];

  const [isOverflowing, setIsOverflowing] = useState(false);

  const handleResize = () => {
    if (containerRef.current) {
      const isItOverflowing =
        containerRef.current.scrollWidth > containerRef.current.clientWidth;
      setIsOverflowing(isItOverflowing);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // for scrolling left and right

  const handleScroll = (scrollOffset) => {
    if (containerRef.current) {
      const newScrollLeft = containerRef.current.scrollLeft + scrollOffset;
      containerRef.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
      setShowLeftArrow(newScrollLeft > 0);
    }
  };

  const values = responseData && responseData["Chatpdf"] ? true : false;

  return values ? (
    <div className={"main-card"}>
      <button
        className={`${theme === "dark" ? " dark-arrow" : ""} right-arrow `}
        onClick={() => handleScroll(470)}
        style={{ display: isOverflowing ? "" : "none" }}
      >
        <img className={`arrow-image`} src={rightArrow} alt="right" />
      </button>

      <button
        className={`${theme === "dark" ? " dark-arrow" : ""} left-arrow `}
        onClick={() => handleScroll(-470)}
        style={{ display: showLeftArrow && isOverflowing ? "" : "none" }}
      >
        <img className={`arrow-image`} src={leftArrow} alt="left" />
      </button>

      <div className="Main-home-heading">Try our newest features</div>
      <div
        className="card-section"
        style={{ width: "100%" }}
        ref={containerRef}
      >
        {SidebarData.map((item, index) => (
          <Card key={index} item={item} index={index} />
        ))}
      </div>
    </div>
  ) : null;
}
