import React from "react";
import pdf from "../../../Assets/images/pdfpdf.svg";
import doc from "../../../Assets/images/doc.svg";
import xl from "../../../Assets/images/xl.svg";
import ppt from "../../../Assets/images/ppt.svg";
import bio from "./../../../Assets/images/bio.svg";
import student from "./../../../Assets/images/student.svg";
import product from "./../../../Assets/images/product.svg";
import resume from "./../../../Assets/images/resume.svg";
import teacher from "./../../../Assets/images/teacher.svg";
import rewrite from "./../../../Assets/images/rewrite.svg";
import simplify from "./../../../Assets/images/simplify.svg";
import Summarize from "./../../../Assets/images/Summarize.svg";
import meta from "./../../../Assets/images/meta.svg";
import homepage from "./../../../Assets/images/homepage.svg";
import xml from "./../../../Assets/images/xml.svg";
import txt from "./../../../Assets/images/txt.svg";
import py from "./../../../Assets/images/py.svg";
import md from "./../../../Assets/images/md.svg";
import json from "./../../../Assets/images/json.svg";
import js from "./../../../Assets/images/js.svg";
import db from "./../../../Assets/images/db.svg";
import csv from "./../../../Assets/images/csv.svg";
import ai from "./../../../Assets/images/ai.svg";

import log from "./../../../Assets/images/log.svg";
import tex from "./../../../Assets/images/tex.svg";

import dlog from "./../../../Assets/images/dlog.svg";
import dtex from "./../../../Assets/images/dtex.svg";

import QAG from "./../../../Assets/images/QAG.svg";
import DQAG from "./../../../Assets/images/DQAG.svg";

import dpdf from "../../../Assets/images/dpdf.svg";
import ddoc from "../../../Assets/images/ddoc.svg";
import dxl from "../../../Assets/images/dxl.svg";
import dppt from "../../../Assets/images/dppt.svg";
import dbio from "./../../../Assets/images/dbio.svg";
import dstudent from "./../../../Assets/images/dstd.svg";
import dproduct from "./../../../Assets/images/dprod.svg";
import dresume from "./../../../Assets/images/dresume.svg";
import dteacher from "./../../../Assets/images/dtech.svg";
import drewrite from "./../../../Assets/images/drewrite.svg";
import dsimplify from "./../../../Assets/images/dSimplify.svg";
import dSummarize from "./../../../Assets/images/dSummarize.svg";
import dmeta from "./../../../Assets/images/dmeta.svg";
import dhomepage from "./../../../Assets/images/dhome.svg";
import dxml from "./../../../Assets/images/dxml.svg";
import dtxt from "./../../../Assets/images/dtext.svg";
import dpy from "./../../../Assets/images/dpy.svg";
import dmd from "./../../../Assets/images/dmd.svg";
import djson from "./../../../Assets/images/djson.svg";
import djs from "./../../../Assets/images/djs.svg";
import ddb from "./../../../Assets/images/ddb.svg";
import dcsv from "./../../../Assets/images/dcsv.svg";
import dai from "./../../../Assets/images/dai.svg";

const ArrayData = (theme, responseData) => {
  const Data = [
    {
      title: "Multimodal Carbon Ai",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dai : ai}
          alt="bio"
        />
      ),
      link: "/chatwith/prompt/carbonAi",
      value: responseData && responseData["customai"] ? true : false,
      short_disc: "Interactive PDF insights on demand",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "Catapult",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dai : ai}
          alt="bio"
        />
      ),
      link: "/chatwith/catapult",
      value: responseData && responseData["customai"] ? true : false,
      short_disc: "Interactive PDF insights on demand",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "Salesforce",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dai : ai}
          alt="bio"
        />
      ),
      link: "/chatwith/Salesforce",
      value: responseData && responseData["customai"] ? true : false,
      short_disc: "Interactive PDF insights on demand",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "Multi Model Ai",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dai : ai}
          alt="bio"
        />
      ),
      link: "/chatwith/prompt/LlamaAi",
      value: responseData && responseData["customai"] ? true : false,
      short_disc: "Interactive PDF insights on demand",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "Finetuned applications",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dai : ai}
          alt="bio"
        />
      ),
      link: "/interactwith/Finetuned",
      value: responseData && responseData["customai"] ? true : false,
      short_disc: "Interactive PDF insights on demand",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "Groq",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dai : ai}
          alt="bio"
        />
      ),
      link: "/chatwith/prompt/Groq",
      value: responseData && responseData["customai"] ? true : false,
      short_disc: "Interactive PDF insights on demand",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "Hugging Face",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dai : ai}
          alt="bio"
        />
      ),
      link: "/chatwith/prompt/Huggingface",
      value: responseData && responseData["customai"] ? true : false,
      short_disc: "Interactive PDF insights on demand",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "Zilliz",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dai : ai}
          alt="bio"
        />
      ),
      link: "/chatwith/prompt/Zilliz",
      value: responseData && responseData["customai"] ? true : false,
      short_disc: "Interactive PDF insights on demand",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "CANbus Conversations",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dstudent : student}
          alt="mf4"
        />
      ),
      link: "/chatwith/CANbus",
      value: responseData && responseData["customai"] ? true : false,
      short_disc: "Vehicle Data Dialogue",
      disc: "Navigate your academic voyage with ease. From clarifying subject matter doubts to assisting in project work, this tool is your dedicated educational companion",
    },
    {
      title: "PDF Converse",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dpdf : pdf}
          alt="bio"
        />
      ),
      link: "/chatwith/pdf_converse",
      value: responseData && responseData["Chatpdf"] ? true : false,
      short_disc: "Interactive PDF Insights",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
      chatWith: true,
    },
    {
      title: "DataDialogue Excel",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dxl : xl}
          alt="bio"
        />
      ),
      link: "/chatwith/DataDialogue_Excel",
      value: responseData && responseData["chat_excel"] ? true : false,
      short_disc: "Your AI-Powered CSV Conversationalist",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "DataDialogue CSV",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dcsv : csv}
          alt="bio"
        />
      ),
      link: "/chatwith/DataDialogue_CSV",
      value: responseData && responseData["chat_csv"] ? true : false,
      short_disc: "Your AI-Powered CSV Conversationalist ",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "LogLingo",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dlog : log}
          alt="bio"
        />
      ),
      link: "/chatwith/LogLingo",
      value: responseData && responseData["chatlog"] ? true : false,
      short_disc: "AI Log Analysis Assistant ",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "PyTalk",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dpy : py}
          alt="bio"
        />
      ),
      link: "/chatwith/PyTalk",
      value: responseData && responseData["chatpy"] ? true : false,
      short_disc: "PyTalk: Python Code Conversationalist",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "JSON Jargon",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? djson : json}
          alt="bio"
        />
      ),
      link: "/chatwith/JSON_Jargon",
      value: responseData && responseData["chatjson"] ? true : false,
      short_disc: "AI JSON Interpreter",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "CodeChat JS",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? djs : js}
          alt="bio"
        />
      ),
      link: "/chatwith/CodeChat_JS",
      value: responseData && responseData["chatjs"] ? true : false,
      short_disc: "Interactive JavaScript Dialogues",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "DataSpeak",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? ddb : db}
          alt="bio"
        />
      ),
      link: "/chatwith/DataSpeak",
      value: responseData && responseData["chatdb"] ? true : false,
      short_disc: "AI-Driven Database Dialogue",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "XML Exchange",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dxml : xml}
          alt="bio"
        />
      ),
      link: "/chatwith/XML_Exchange",
      value: responseData && responseData["chatxml"] ? true : false,
      short_disc: "XML Exchange: AI XML Interactor",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "Chat with AI",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dai : ai}
          alt="bio"
        />
      ),
      link: "/chatwith/AI",
      value: responseData && responseData["customai"] ? true : false,
      short_disc: "Interactive PDF insights on demand",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "Custom Prompt",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dai : ai}
          alt="bio"
        />
      ),
      link: "/chatwith/CustomPrompt",
      value: responseData && responseData["customai"] ? true : false,
      short_disc: "Interactive PDF insights on demand",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },

    {
      title: "WordWhisper",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? ddoc : doc}
          alt="bio"
        />
      ),
      link: "/chatwith/WordWhisper",
      value: responseData && responseData["chatdoc"] ? true : false,
      short_disc: "Your AI Document Assistant",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "QuizCraft",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? DQAG : QAG}
          alt="bio"
        />
      ),
      link: "/chatwith/QuizCraft",
      value: responseData && responseData["customai"] ? true : false,
      short_disc: "AI Question Creation Tool for Educators",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "SlideSage",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dppt : ppt}
          alt="bio"
        />
      ),
      link: "/chatwith/SlideSage",
      value: responseData && responseData["chatpptx"] ? true : false,
      short_disc: "AI-Enhanced Presentation Creator",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "TextTalk",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dtxt : txt}
          alt="bio"
        />
      ),
      link: "/chatwith/TextTalk",
      value: responseData && responseData["chattext"] ? true : false,
      short_disc: "Interactive PDF insights on demand",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    // {
    //     title: "Chat with Image",
    //     icon: <img className="web-icons" src={theme === 'dark' ? ChatwithWebsite : ChatwithWebsitechatWith:true} alt="bio" />,
    //     link: "/chatwith/image",
    //     value: responseData && responseData["Chatpdf"] ? true : false,
    //     short_disc: "Interactive PDF insights on demand",
    //     disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go."

    // chatWith:true},

    {
      title: "Markdown Mentor",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dmd : md}
          alt="bio"
        />
      ),
      link: "/chatwith/Markdown_Mentor",
      value: responseData && responseData["chatmd"] ? true : false,
      short_disc: "Interactive PDF insights on demand",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
    },
    {
      title: "Chat with TEX",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dtex : tex}
          alt="bio"
        />
      ),
      link: "/chatwith/tex",
      value: responseData && responseData["chatlatex"] ? true : false,
      short_disc: "Interactive PDF insights on demand",
      disc: "Engage directly with your PDFs. Upload your documents, initiate a conversation, and extract specific insights or data on the go.",
      chatWith: true,
    },
    {
      title: "Bio Writer",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dbio : bio}
          alt="bio"
        />
      ),
      link: "/interactwith/bio_writter",
      value: responseData && responseData["Biowritter"] ? true : false,
      short_disc: "Captivating short biographies fast",
      disc: "Tell your story succinctly. Share key details and generate captivating short biographies tailored for social profiles, websites, and more",
    },

    {
      title: "EduBuddy",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dstudent : student}
          alt="bio"
        />
      ),
      link: "/chatwith/EduBuddy",
      value: responseData && responseData["Studentassist"] ? true : false,
      short_disc: "AI-Powered Student Helper",
      disc: "Navigate your academic voyage with ease. From clarifying subject matter doubts to assisting in project work, this tool is your dedicated educational companion",
      chatWith: true,
    },
    {
      title: "QuestionQuiver",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dteacher : teacher}
          alt="bio"
        />
      ),
      link: "/interactwith/QuestionQuiver",
      value: responseData && responseData["Teacherassist"] ? true : false,
      short_disc: "Educator's AI Question Forge",
      disc: "Propel your learning experience. Provide your study materials and let this tool generate pertinent questions and answers to enhance your grasp and recall",
    },
    {
      title: "Resume Parser",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dresume : resume}
          alt="bio"
        />
      ),
      link: "/chatwith/Resume_Parser",
      value: responseData && responseData["Resumeparser"] ? true : false,
      short_disc: "Swift, efficient resume analysis",
      disc: "Simplify your hiring process. Instantaneously scan and analyze resumes, extracting crucial information to make candidate evaluations swift and efficient ",
    },
    {
      title: "Product Details",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dproduct : product}
          alt="bio"
        />
      ),
      link: "/interactwith/Product_Details",
      value: responseData && responseData["Product_details"] ? true : false,
      short_disc: "Vivid narratives for products",
      disc: "Make every product shine. Offer a brief overview and let the tool craft detailed, persuasive narratives that resonate with potential buyers",
    },
    {
      title: "ContentCraft",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? drewrite : rewrite}
          alt="bio"
        />
      ),
      link: "/interactwith/ContentCraft",
      value: responseData && responseData["Rewrite_content"] ? true : false,
      short_disc: "Comprehensive content creation aid",
      disc: "Perfect your content creation process. From initial ideas to polished articles, get comprehensive support and guidance at every step            ",
    },
    {
      title: "SimplifySpot",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dsimplify : simplify}
          alt="bio"
        />
      ),
      link: "/interactwith/SimplifySpot",
      value: responseData && responseData["simplify_content"] ? true : false,
      short_disc: "Complex texts made easy",
      disc: "Break down intricate content. Transform complex texts into simpler, easily digestible versions while retaining the core message            ",
    },
    {
      title: "SummarySphere",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dSummarize : Summarize}
          alt="bio"
        />
      ),
      link: "/interactwith/SummarySphere",
      value: responseData && responseData["Summarize_content"] ? true : false,
      short_disc: "Interactive PDF insights on demand",
      disc: "Get the gist without the fluff. Input your lengthy articles or reports, and receive concise summaries that capture the essence seamlessly            ",
    },
    {
      title: "MetaMorph",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dmeta : meta}
          alt="bio"
        />
      ),
      link: "/interactwith/MetaMorph",
      value: responseData && responseData["MetaData_desc"] ? true : false,
      short_disc: "Optimize content for search engines",
      disc: "Boost your web visibility. Provide your content and get finely-tuned meta descriptions that enhance your online presence in search engines            ",
    },

    {
      title: "HomeHighlight",
      icon: (
        <img
          className="web-icons"
          src={theme === "dark" ? dhomepage : homepage}
          alt="bio"
        />
      ),
      link: "/interactwith/HomeHighlight",
      value: responseData && responseData["Homepage_desc"] ? true : false,
      short_disc: "Engaging content for first impressions",
      disc: "Captivate from the first click. Design and implement compelling content that ensures visitors are engaged the moment they land on your website            ",
    },
  ];

  return Data;
};

export default ArrayData;
