import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectIsToggleOn } from "../../../Redux/ReduceSidebar";
import { useTheme } from "../../../Features/DarkMode/ThemeContext";
import _http from "../../../Utils/Api/_http";
import "./LinguaLink.css";
import SnackBar from "../../../Features/SnackBar";
export default function EnglishtoHindi() {
  const isToggleOn = useSelector(selectIsToggleOn);
  const { theme } = useTheme();
  const [englishText, setEnglishText] = useState("");
  const [hindiText, setHindiText] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({ error: false, Submit: false });
  const [errors, setError] = useState("");
  const translateToHindi = async () => {
    if (!englishText) {
      setOpen({ Submit: true });
      return;
    } else {
      setLoading(true);
      try {
        const response = await _http.post("/api/eng2hnd", {
          words: englishText,
        });
        setHindiText(response.data.ai_answer);
      } catch (error) {
        setOpen({ error: true });
        if (error.response && error.response?.status === 500) {
          setError("Internal server error");
        } else {
          setError(error.message || "An error occured");
        }
      } finally {
        setLoading(false);

        const currentTimeUTC = new Date().toISOString();

        const options = {
          hour12: false,
          timeZone: "UTC",
          timeZoneName: "short",
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };

        const currentTime = new Date(currentTimeUTC).toLocaleString(
          "en-US",
          options
        );
        const userName = sessionStorage.getItem("Name");

        const newLog = {
          currentTime,
          userName,
          application: "English to Hindi",
          filename: "---",
          filesize: "---",
        };

        let storedLogs = JSON.parse(localStorage.getItem("UserLogsArray"));

        if (!Array.isArray(storedLogs) || !storedLogs.length) {
          storedLogs = [];
        }

        storedLogs.unshift(newLog);

        localStorage.setItem("UserLogsArray", JSON.stringify(storedLogs));
      }
    }
  };
  const handleClose = (state) => {
    setOpen({ ...state, open: false });
  };
  return (
    <div
      className={` ${theme === "dark" ? "dark-theme " : "light-background"
        } chatUItwo  `}
      style={{
        overflowX: "hidden",
        overflowY: "scroll",
        padding: "30px",
      }}
    >
      <div
        className="translation-container "
        style={{ width: !isToggleOn ? "100vw" : "calc(100vw - 342px)" }}
      >
        <div className="output-box">
          <label>English Text:</label>
          <textarea
            rows="20"
            cols="60"
            value={englishText}
            onChange={(e) => setEnglishText(e.target.value)}
          />
        </div>
        <div className="translate-button">
          <button onClick={translateToHindi} disabled={loading}>
            {loading ? "Translating..." : "Translate"}
          </button>
        </div>

        {
          <SnackBar
            message="Please enter Text..."
            severity="warning"
            Open={open.Submit}
            handleClose={handleClose}
          />
        }
        {
          <SnackBar
            message={errors}
            severity={"error"}
            handleClose={handleClose}
            Open={open.error}
          />
        }
        <div className="output-box">
          <label>Hindi Text:</label>
          <textarea rows="20" cols="60" value={hindiText} readOnly />
        </div>
      </div>
    </div>
  );
}
