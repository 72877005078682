import React from "react";
import ChatWithMultipleTypeFiles from "../../../Components/ChatWith/ChatWithMultipleTypeFiles";

export default function ChatWithWaranty() {
  return (
    <ChatWithMultipleTypeFiles
      appName="WarrantyWords"
      dis="Detailed Warranty Conversationalist"
    />
  );
}
