import React, { useEffect, useState, useRef } from "react";
import _http from "../../Utils/Api/_http";
import "./safedigging.css";
import ReactPaginate from "react-paginate";

import uploadIcon from "../../Assets/SafeDigging/Group.svg";

import gray_mark from "../../Assets/SafeDigging/shield 22.svg";
import greenTick from "../../Assets/SafeDigging/shield(1).svg";

import corss from "../../Assets/SafeDigging/cross.svg";

import SafeModel from "./SafeModel";
import SamplePdfs from "./SamplePdfs";
import SnackBar from "./../../Features/SnackBar";

function SafeDigging() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = (rowData) => {
    setSelectedRowData(rowData);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [tableData, setTableData] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFile, setCurrentfile] = useState([]);
  const fileInputRef = useRef(null);
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(tableData.length / itemsPerPage);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [Loading, setLoading] = useState(true);
  const [currentCompany, setCurrentCompany] = useState("");
  const [opensnack, setOpensnack] = useState({ Submit: false, error: false });
  const [errors, setError] = useState("");

  const handleCloseSnack = (state) => {
    setOpensnack({ ...state, open: false });
  };
  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
    setCurrentfile(files[0]);
  };

  const fetchData = async () => {
    try {
      const response = await _http.get("/api/get-job-pack-data");
      const responseData = response.data;

      responseData.sort((a, b) => {
        return new Date(b.modified) - new Date(a.modified);
      });

      setTableData(responseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const uploadFile = async () => {
    if (currentFile.length === 0 || !currentCompany) {
      setOpensnack({ Submit: true });
      return;
    }
    setLoading(true);

    let updatedApiName = "";
    if (currentCompany === "CadentGas") {
      updatedApiName = "cadentgascompany";
    } else if (currentCompany === "SGN") {
      updatedApiName = "sgngascompany";
    } else if (currentCompany === "WWU") {
      updatedApiName = "wawgascompany";
    } else {
      updatedApiName = "ngngascompany";
    }

    try {
      // console.log(updatedApiName, "apinameapinameapinameapiname");
      const formData = new FormData();
      formData.append("file", currentFile);
      await _http.post(`/api/${updatedApiName}`, formData);
      fetchData();
    } catch (error) {
      setOpensnack({ error: true });
      if (error.response && error.response?.status === 500) {
        setError("Internal server error");
      } else {
        setError(error.message || "An error occured");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const displayedData = tableData.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );

  return (
    <section className={`safe-digging-back `} style={{ overflowX: "scroll" }}>
      <div>
        <div className="safe-digging-header">
          <div className="uplaod-box">
            <div className="uplaod-box-heading">Upload File</div>
            <div className="uplaod-box-subhead">
              {selectedFiles.length > 0 ? (
                <>
                  {selectedFiles.map((file, index) => (
                    <div key={index}>
                      <>{file.name?.slice(0, 40)}</>
                    </div>
                  ))}
                </>
              ) : (
                "Only .PDF files. 32MB max files size."
              )}
            </div>
            <div className="safe-digg-box">
              <div className={`safe-digg-input-box`} onClick={handleClick}>
                <img src={uploadIcon} className="upload-icon-safe" alt="icon" />
                <p className="uplaod-box-subhead">
                  <span className="mx-3">Drag and drop or</span> <br />
                  select a PDF to upload
                </p>
                <input
                  type="file"
                  accept=".pdf"
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                  ref={fileInputRef}
                />
              </div>
            </div>
            <div className="safe-submit-btn ">
              <select
                class="form-select "
                value={currentCompany}
                onChange={(e) => setCurrentCompany(e.target.value)}
              >
                <option value="">Select Company</option>
                <option value="CadentGas">CadentGas</option>
                <option value="SGN">SGN</option>
                <option value="WWU">WWU</option>
                <option value="NGN">NGN</option>
              </select>
              <button
                onClick={uploadFile}
                className={`btn btn-danger btn-block   `}
              >
                Upload
              </button>
              <SnackBar
                message="Select File and Company Name"
                severity="warning"
                Open={opensnack.Submit}
                handleClose={handleCloseSnack}
              />
              <SnackBar
                message={errors}
                severity={"error"}
                handleClose={handleCloseSnack}
                Open={opensnack.error}
              />
            </div>
          </div>
          <div className="history-box">
            <SamplePdfs />
          </div>
        </div>
        <div>
          <table className="custom-table">
            <thead className="custom-table-header ">
              <th>Job Reference</th>
              <th>Utility Provider</th>
              <th>Red Line</th>
              <th>Blue Line</th>
              <th>Green Line</th>
              <th>Orange Line</th>
              <th>Job Ready for Release</th>
              <th>Image Validation</th>
              <th>Warning Validation</th>
              <th>Critical Line</th>
              <th>Warning Indicator</th>
              <th>Warning Message</th>
              <th>Created Date</th>
              <th>Modified Date</th>
            </thead>
            <tbody className="custom-table-body">
              <tr class="gap-row-header"></tr>
              {Loading ? (
                <>
                  {[...Array(5)].map((_, rowIndex) => (
                    <tr key={rowIndex}>
                      {[...Array(14)].map((_, colIndex) => (
                        <td key={colIndex} className="placeholder-glow">
                          <div className="placeholder col-12 my-2">..</div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  {displayedData.map((rowData) => (
                    <>
                      <tr
                        key={rowData.id}
                        className="custom-table-row"
                        onClick={() => handleOpen(rowData)}
                      >
                        {/* Job Reference */}
                        <td>{rowData.job_reference}</td>
                        {/* Utility Provider */}
                        <td>{rowData.utility_provider}</td>
                        {/* Red Line */}
                        {rowData.red_line === "Yes" ? (
                          <td>
                            <img src={gray_mark} alt="Yes" />
                          </td>
                        ) : (
                          <td>{<img src={corss} alt="No" />}</td>
                        )}
                        {/* Blue Line	 */}
                        {rowData.blue_line === "Yes" ? (
                          <td>
                            <img src={gray_mark} alt="yes" />
                          </td>
                        ) : (
                          <td>
                            <img src={corss} alt="No" />
                          </td>
                        )}
                        {/* Green Line */}
                        {rowData.green_line === "Yes" ? (
                          <td>
                            <img src={greenTick} alt="yes" />
                          </td>
                        ) : (
                          <td>
                            <img src={gray_mark} alt="No" />
                          </td>
                        )}
                        {/* Orange Line */}
                        {rowData.orange_line === "Yes" ? (
                          <td>
                            <img src={greenTick} alt="yes" />
                          </td>
                        ) : (
                          <td>
                            <img src={gray_mark} alt="No" />
                          </td>
                        )}
                        {/*Job Ready for Release */}
                        {rowData.job_ready_for_release === "Yes" ? (
                          <td>
                            <span className="job-yes">Yes</span>
                          </td>
                        ) : (
                          <td>
                            <span className="job-no">Hot</span>
                          </td>
                        )}
                        {/* image_validation */}
                        {rowData.image_validation === "Completed" ? (
                          <td>
                            <span className="validation-Complete">
                              Completed
                            </span>
                          </td>
                        ) : (
                          <td>
                            <span className="validation-Not-Complete">
                              Not Completed
                            </span>
                          </td>
                        )}
                        {/* Warning Validation */}
                        {rowData.warning_validation === "Completed" ? (
                          <td>
                            <span className="validation-Complete">
                              Completed
                            </span>
                          </td>
                        ) : (
                          <td>
                            <span className="validation-Not-Complete">
                              Not Completed
                            </span>
                          </td>
                        )}
                        {/* critical_line */}
                        {rowData.critical_line === "Yes" ? (
                          <td>
                            <img src={greenTick} alt="yes" />
                          </td>
                        ) : (
                          <td>
                            <img src={gray_mark} alt="No" />
                          </td>
                        )}
                        {/* Warning Validation */}
                        {rowData.warning_indicator === "Yes" ? (
                          <td>
                            <img src={greenTick} alt="yes" />
                          </td>
                        ) : (
                          <td>
                            <img src={gray_mark} alt="No" />
                          </td>
                        )}
                        {/* Warning Indicator */}
                        <td>{rowData.warning_message?.slice(0, 20)}</td>
                        <td>{rowData.created}</td>
                        <td>{rowData.modified}</td>
                      </tr>

                      <tr class="gap-row"></tr>
                    </>
                  ))}
                </>
              )}
              <SafeModel
                open={open}
                handleClose={handleClose}
                selectedRowData={selectedRowData}
              />
            </tbody>
          </table>
          {Loading ? null : (
            <div className="pagination-container">
              <ReactPaginate
                pageCount={pageCount}
                onPageChange={handlePageChange}
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                containerClassName="pagination-container"
                pageClassName="pagination-page"
                activeClassName="pagination-active"
                breakClassName="pagination-break"
                previousClassName="pagination-previous"
                nextClassName="pagination-next"
                previousLinkClassName={"page-next-prev"}
                nextLinkClassName={"page-next-prev"}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default SafeDigging;
