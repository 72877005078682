// in this tool user can attach their custom prompt in file

import React, { useState, useEffect, useRef } from "react";
import _http from "./../../Utils/Api/_http";
import dSendIcon from "./../../Assets/ChatSectionImages/dark-send.svg";
import SendIcon from "./../../Assets/ChatSectionImages/Send.svg";
import backimage from "./../../Assets/background-gpt.svg";
import { useTheme } from "./../../Features/DarkMode/ThemeContext";
import AttachFileTwoToneIcon from "@mui/icons-material/AttachFileTwoTone";
import deleteIcon from "../../Assets/ChatSectionImages/deleteIcon.svg";
import "./customPrompt.css";
import SnackBar from "../../Features/SnackBar";
import { Link } from "react-router-dom";

const Carbonai = () => {
  const [open, setOpen] = useState({ Submit: false, error: false });
  const [errors, setError] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [showInitialImage, setShowInitialImage] = useState(true);
  const [Cfile, setFile] = useState([]);
  const [fileName, setFileName] = useState("");
  const [hide, setHide] = useState(false);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const { theme } = useTheme();
  const [selectModel, setSelectModel] = useState("");
  const textareaRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };
  const handleClose = (state) => {
    setOpen({ ...state, open: false });
  };
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };

  const handleInputChange = (e) => {
    setCurrentQuestion(e.target.value);
    const textarea = e.target;
    const rows = Math.min(Math.max(1, textarea.value.split("\n").length), 5);
    textarea.rows = rows;
  };

  const api = (file) => {
    const form = new FormData();
    form.append("file", file);

    const options = {
      method: "POST",
      headers: {
        authorization:
          "Bearer d902e457817b81dd74a2f905606a78eee6aef1666fe478bef18287efa674b426",
        "customer-id": "1",
      },
    };

    options.body = form;

    fetch(
      "https://api.carbon.ai/uploadfile?chunk_size=1000&chunk_overlap=200&embedding_model=OPENAI&generate_sparse_vectors=true",
      options
    )
      .then((response) => response.json())
      .catch((err) => console.error(err));
  };

  const handleFileChange = (e) => {
    setHide(true);
    const selectedFiles = e.target.files;
    api(selectedFiles[0]);
    if (selectedFiles && selectedFiles.length > 0) {
      const file = selectedFiles[0];
      const fileName = file.name;

      if (fileName) {
        setFileName(fileName.slice(0, 10));
      }

      setFile(selectedFiles);
    } else {
      setFileName((prevFileName) => prevFileName || "");
      setFile((prevFile) => prevFile || null);
    }
  };

  const handelDelete = () => {
    setFile([]);
    setHide(false);
  };

  const handleAsk = async () => {
    textareaRef.current.rows = 1;

    if (!currentQuestion) {
      setOpen({ Submit: true });
    } else {
      setHide(true);
      setCurrentQuestion("");
      setLoading(true);
      setShowInitialImage(false);

      try {
        const formData = new FormData();
        formData.append("prompt", currentQuestion);
        formData.append("model", selectModel || "mistral");

        if (Cfile.length > 0) {
          formData.append("file", Cfile[0]);
        } else {
          setHide(false);
        }

        const questionId = Date.now();
        addChatMessage(currentQuestion, null, questionId);

        const response = await _http.post("/api/carbon_question", formData);
        const answerReceived = await response.data.ai_answer;
        updateLastChatMessage(answerReceived, questionId);
      } catch (error) {
        setOpen({ error: true });
        if (error.response && error.response?.status === 500) {
          setError("Internal server error");
        } else {
          setError(error.message || "An error occured");
        }
      } finally {
        const currentTimeUTC = new Date().toISOString();

        const options = {
          hour12: false,
          timeZone: "UTC",
          timeZoneName: "short",
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };

        const currentTime = new Date(currentTimeUTC).toLocaleString(
          "en-US",
          options
        );

        const file = Cfile[0];
        const filename = file?.name?.slice(0, 20) || "---";
        const filesize = file
          ? `${(file.size / (1024 * 1024)).toFixed(2)} MB`
          : "---";

        const userName = sessionStorage.getItem("Name");

        const newLog = {
          currentTime,
          userName,
          application: "Custom Prompt",
          filename,
          filesize,
        };

        let storedLogs = JSON.parse(localStorage.getItem("UserLogsArray"));

        if (!Array.isArray(storedLogs) || !storedLogs.length) {
          storedLogs = [];
        }

        storedLogs.unshift(newLog);

        localStorage.setItem("UserLogsArray", JSON.stringify(storedLogs));

        setLoading(false);
      }
    }
  };

  const addChatMessage = (question, answer, questionId) => {
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { question, answer, id: questionId },
    ]);
  };

  const updateLastChatMessage = (answer, questionId) => {
    setChatMessages((prevMessages) => {
      const updatedMessages = prevMessages.map((msg) =>
        msg.id === questionId ? { ...msg, answer } : msg
      );
      return updatedMessages;
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages, currentQuestion]);

  return (
    <section
      className={`${theme === "dark" ? "dark-theme " : "light-background"
        } chat-area`}
    >
      <span className="select-carbon-file">
        <Link to="/carbonai/files">
          <button className="btn btn-outline-secondary ">
            View Uploaded Files
          </button>
        </Link>
      </span>
      <span className="select-model-carbon">
        <select
          className="form-select "
          aria-label="Default select example"
          value={selectModel}
          onChange={(e) => {
            setSelectModel(e.target.value);
          }}
        >
          <option value="mistral">Mistral</option>
          <option value="llama">Llama</option>
          <option value="GPT-4 Turbo">GPT-4 Turbo</option>
          <option value="Gemma">Gemma</option>
        </select>
      </span>

      <div className="chat-window">
        <span>
          {chatMessages.map((message) => (
            <div
              key={message.id}
              className="chat-message"
              style={{ padding: "10px 0px" }}
            >
              <div
                className={`question  ${theme === "dark"
                    ? "user-message-dark-gpt"
                    : "user-message-light-gpt"
                  }`}
              >
                {message.question.trim()}
              </div>
              {loading && message.answer === null ? (
                <div style={{ margin: "20px" }} className="loader-gpt"></div>
              ) : (
                <div className="answer">
                  <p style={{ margin: "0" }}>
                    {message.answer || "Something went wrong"}
                  </p>
                </div>
              )}
            </div>
          ))}
          <div ref={messagesEndRef} />
          {showInitialImage && (
            <div className="gpt-image">
              <div>
                <img src={backimage} alt="img" />
              </div>
              <div>
                <h4>How can I help you today?</h4>
              </div>
            </div>
          )}
        </span>
      </div>

      <div
        className={`${hide
            ? "custom-prompt-input-area-without-pdf"
            : "custom-prompt-input-area-with-pdf"
          }`}
      >
        {hide ? (
          <>
            <span className="file-name mx-2">
              {fileName}
              <button className="px-3 del-btn" onClick={handelDelete}>
                <img src={deleteIcon} alt="deleteIcon" />
              </button>
            </span>
          </>
        ) : null}
        <div className="input-wrapper">
          <div className="files-section">
            <label htmlFor="fileInput" style={{ display: "none" }}>
              <input
                type="file"
                id="fileInput"
                ref={fileInputRef}
                onChange={handleFileChange}
                multiple
                accept=".pdf, .docx"
              />
            </label>
            <button onClick={handleIconClick}>
              <AttachFileTwoToneIcon />
            </button>
          </div>
          <textarea
            ref={textareaRef}
            type="text"
            className={`textarea-ca1 ${theme === "dark" ? "dark-text" : "light-text"
              } ${theme === "dark" ? "dark-background" : ""}`}
            value={currentQuestion}
            onChange={handleInputChange}
            placeholder="Ask a question..."
            onKeyPress={(event) => {
              if (event.key === "Enter" && !loading && !event.shiftKey) {
                event.preventDefault(); // Prevents the default behavior of adding a newline
                handleAsk();
              }
            }}
            rows={1}
            maxrows={5}
          />
          <button onClick={handleAsk} disabled={loading} className="send-btn">
            <img src={`${theme === "dark" ? dSendIcon : SendIcon}`} alt="" />
          </button>
          {
            <SnackBar
              message="Please ask a question..."
              severity="warning"
              Open={open.Submit}
              handleClose={handleClose}
            />
          }
          {
            <SnackBar
              message={errors}
              severity={"error"}
              handleClose={handleClose}
              Open={open.error}
            />
          }
        </div>
      </div>
    </section>
  );
};

export default Carbonai;
