// mapping loga from local storage

import React, { useState, useEffect } from "react";
import { useTheme } from "./../../../Features/DarkMode/ThemeContext";

export default function UserLogs() {
  const [logsByDay, setLogsByDay] = useState({});
  const [expanded, setExpanded] = useState({});
  const { theme } = useTheme();

  useEffect(() => {
    const storedData = localStorage.getItem("UserLogsArray");
    const dataArray = JSON.parse(storedData) || [];
    const logsGroupedByDay = groupLogsByDay(dataArray);
    setLogsByDay(logsGroupedByDay);

    const initialExpandedState = Object.keys(logsGroupedByDay).reduce(
      (acc, day) => {
        acc[day] = false;
        return acc;
      },
      {}
    );
    setExpanded(initialExpandedState);
  }, []);

  const groupLogsByDay = (logs) => {
    return logs.reduce((acc, log) => {
      const date = log.currentTime.split(", ")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(log);
      return acc;
    }, {});
  };

  const toggleExpanded = (day) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [day]: !prevExpanded[day],
    }));
  };

  const renderLogsForDay = (logs) => {
    return logs.map((item, index) => (
      <tr key={index}>
        <td>{item.currentTime}</td>
        <td>{item.userName}</td>
        <td>{item.application}</td>
        <td>{item.filename}</td>
        <td>{item.filesize}</td>
      </tr>
    ));
  };

  const renderLogAccordion = () => {
    return Object.keys(logsByDay).map((day, index) => (
      <div className="accordion" key={index}>
        <div className="accordion-item">
          <h2 className="accordion-header" id={`heading${index}`}>
            <button
              className={`accordion-button ${expanded[day] ? "" : "collapsed"}`}
              type="button"
              onClick={() => toggleExpanded(day)}
              aria-expanded={expanded[day]}
              aria-controls={`collapse${index}`}
            >
              {day}
            </button>
          </h2>
          <div
            id={`collapse${index}`}
            className={`accordion-collapse collapse ${
              expanded[day] ? "show" : ""
            }`}
            aria-labelledby={`heading${index}`}
            data-bs-parent=".accordion"
          >
            <div className="accordion-body">
              <table
                className={`table ${
                  theme === "dark" ? "table-dark" : "table-light"
                }`}
              >
                <thead>
                  <tr>
                    <th>Current Time</th>
                    <th>User Name</th>
                    <th>Application</th>
                    <th>File Name</th>
                    <th>File Size</th>
                  </tr>
                </thead>
                <tbody>{renderLogsForDay(logsByDay[day])}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const clearLogs = () => {
    localStorage.removeItem("UserLogsArray");
    setLogsByDay({});
    setExpanded({});
  };

  return (
    <section
      className={`container ${
        theme === "dark" ? "dark-theme " : "light-background"
      } `}
      style={{ flexDirection: "column" }}
    >
      <div className="d-flex justify-content-between align-items-center my-3">
        <h3>User Logs</h3>
        {Object.keys(logsByDay).length > 0 && (
          <button className="btn btn-danger" onClick={clearLogs}>
            Clear All Logs
          </button>
        )}
      </div>
      {Object.keys(logsByDay).length > 0 ? (
        renderLogAccordion()
      ) : (
        <p
          className={`empty-message mt-5 ${
            theme === "dark" ? "" : "text-muted"
          }`}
        >
          No logs to show{" "}
        </p>
      )}
    </section>
  );
}
