import React, { useState, useEffect } from "react";
import logoOfDt from "../../Assets/HomeImages/200x68_Dark.svg";
import { useTheme } from "../../Features/DarkMode/ThemeContext";
import DTLogoLight from "../../Assets/HomeImages/200x68_Light.svg";
import _nonAuthHttp from "./../../Utils/Api/_nonAuthHttp";
import forgotPass from "./../../Assets/Forgot password.svg";

export default function EmailVerify() {
  const { theme } = useTheme();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
    }, 1500);

    return () => clearTimeout(timer);
  }, [message]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  async function handleEmailPasswordReset(e) {
    e.preventDefault();
    if (email === "") {
      setMessage("Email field cannot be empty. Please enter your email.");
      return;
    }

    try {
      const res = await _nonAuthHttp.post("/request_reset_password", { email });

      if (res.data.token.length <= 0) {
        setMessage("Invalid email. Please provide a valid email address.");
      } else {
        setMessage(
          "Password reset initiated. Check your email for instructions."
        );

        setTimeout(() => {
          alert("Reset password link sent to your emali!");
          // navigate("/PasswordReset", { state: { token: res.data.token } });
        }, 500);
      }
    } catch (error) {
      setMessage("Error while processing. Please verify your email.");
    }
  }
  return (
    <main className="main-login-body newLogin">
      <div className="login-image-body">
        <img
          className="newLogin-image"
          src={forgotPass}
          alt="CompanyLogo"
        ></img>
      </div>
      <div className="login-body">
        <div className={`login-card newLogin-card `}>
          <div className="login-logo title">
            <img src={theme === "dark" ? DTLogoLight : logoOfDt} alt="img" />
          </div>
          <div className="title title-margin">
            <h3>Verify Email</h3>
          </div>
          <div className="title">
            <form className="login-card-form">
              <div className="email-login">
                <label for="email">
                  {" "}
                  <b></b>
                </label>

                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Email"
                  value={email}
                  onChange={handleEmailChange}
                  className="form-control form-control-lg"
                  required
                />
              </div>
              <button
                onClick={(event) => {
                  handleEmailPasswordReset(event);
                }}
                className="btn btn-danger   cta-btn"
                type="button"
              >
                Send Reset Link
              </button>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}
