import React from "react";
import {
  Snackbar,
  //  Slide
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// const SlideTransition = (props) => {
//   return <Slide {...props} direction="left" />;
// };

const SnackBar = ({ message, severity, Open, handleClose }) => {
  return (
    <Snackbar
      open={Open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      // TransitionComponent={SlideTransition}
      sx={{
        padding: "65px 15px",
      }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{
          height: "60px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          fontSize: "15px",
          padding: "0 15px",
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
