import React, { useState, useEffect } from "react";
import _http from "./../../../Utils/Api/_http";
import * as XLSX from "xlsx";
import { useTheme } from "./../../../Features/DarkMode/ThemeContext";

const TokenUsage = () => {
  const [apiData, setApiData] = useState([]);
  const { theme } = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = await Promise.all([
          _http.get("/api/usage_read/name"),
          _http.get("/api/usage_read/timestamp"),
          _http.get("/api/usage_read/prompt_token"),
          _http.get("/api/usage_read/complete_token"),
          _http.get("/api/usage_read/total_token"),
          _http.get("/api/usage_read/application"),
        ]);

        const combinedData = responses[0].data.map((item, index) => ({
          name: item.name,
          timestamp: responses[1].data[index].timestamp,
          prompt_token: responses[2].data[index].prompt_token,
          complete_token: responses[3].data[index].complete_token,
          total_token: responses[4].data[index].total_token,
          application: responses[5].data[index].application,
        }));

        const groupedData = groupLogsByDay(combinedData);
        setApiData(groupedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const groupLogsByDay = (logs) => {
    return logs.reduce((acc, log) => {
      const date = log.timestamp.split("[")[1].split(":")[0]; // Extracting date without time
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(log);
      return acc;
    }, {});
  };

  const handleDelete = async (timestamp, index) => {
    // const confirmDelete = window.confirm(
    //   "Are you sure you want to delete this row?"
    // );
    // if (confirmDelete) {
    try {
      await _http.post("/api/usage_delete", { time: timestamp });

      const updatedData = { ...apiData };
      delete updatedData[timestamp];
      setApiData(updatedData);
    } catch (error) {
      console.error("Error deleting row:", error);
    }
    // }
  };

  const handleDownload = () => {
    const flattenedData = Object.values(apiData).flat();
    const worksheet = XLSX.utils.json_to_sheet(flattenedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Token_Logs");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(data);

    const a = document.createElement("a");
    a.href = url;
    a.download = "api_data.xlsx";
    document.body.appendChild(a);
    a.click();

    // Cleanup
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const sanitizeDate = (dateString) => {
    return dateString.replace(/\s+/g, "-").replace(/\//g, "-");
  };

  return (
    <section
      className={`container ${
        theme === "dark" ? "dark-theme " : "light-background"
      } `}
      style={{ flexDirection: "column" }}
    >
      <div className="d-flex justify-content-between align-items-center my-3">
        <h3>User Logs</h3>
        {Object.entries(apiData).length > 0 && (
          <button className="btn btn-danger" onClick={handleDownload}>
            Download
          </button>
        )}
      </div>
      {Object.entries(apiData).length > 0 ? (
        <div className="accordion" id="logsAccordion">
          {Object.entries(apiData)
            .reverse()
            .map(([date, logs]) => (
              <div className="accordion-item" key={date}>
                <h2
                  className="accordion-header"
                  id={`heading-${sanitizeDate(date)}`}
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse-${sanitizeDate(date)}`}
                    aria-expanded="true"
                    aria-controls={`collapse-${sanitizeDate(date)}`}
                  >
                    {date}
                  </button>
                </h2>
                <div
                  id={`collapse-${sanitizeDate(date)}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`heading-${sanitizeDate(date)}`}
                  data-bs-parent="#logsAccordion"
                >
                  <div className="accordion-body">
                    <table className="table">
                      <thead
                        className={`${
                          theme === "dark" ? "dark-text" : "light-text"
                        }`}
                      >
                        <tr>
                          <th>Name</th>
                          <th>Timestamp</th>
                          <th>Prompt Token</th>
                          <th>Complete Token</th>
                          <th>Total Token</th>
                          <th>Application</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody
                        className={`${
                          theme === "dark" ? "dark-text" : "light-text"
                        }`}
                      >
                        {logs.reverse().map((log, index) => (
                          <tr key={`${date}-${index}`}>
                            <td>{log.name}</td>
                            <td>{log.timestamp}</td>
                            <td>{log.prompt_token}</td>
                            <td>{log.complete_token}</td>
                            <td>{log.total_token}</td>
                            <td>{log.application}</td>
                            <td>
                              <button
                                className="btn btn-outline-danger"
                                onClick={() =>
                                  handleDelete(log.timestamp, index)
                                }
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <p
          className={`empty-message mt-5 ${
            theme === "dark" ? "" : "text-muted"
          }`}
        >
          No Token Usage to show{" "}
        </p>
      )}
    </section>
  );
};

export default TokenUsage;
