import React from "react";
import ChatWithMultipleTypeFiles from "../../../Components/ChatWith/ChatWithMultipleTypeFiles";

export default function ChatWithManual() {
  return (
    <ChatWithMultipleTypeFiles
      appName="ManualDialogue"
      dis=" Interactive Documentation Assistant"
    />
  );
}
