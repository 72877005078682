import React, { useEffect, useState } from "react";
import "../../Components/ChatSection/ChatSection.css";
import _http from "../../Utils/Api/_http";
import Three from "./Three";

const Two = ({ textFile, index, displayFile }) => {
  const [fileDataList, setFileDataList] = useState([]);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [isLoadingAns, setIsLoadingAns] = useState(false);
  const [chatHistories, setChatHistories] = useState({});

  const readFile = (displayFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(displayFile);
    });
  };

  useEffect(() => {
    const handleFileChange = async () => {
      const displayAsFile = new File([displayFile], "your_file", {
        type: "application/pdf",
      });

      const textFileAsFile = new File([textFile], "your_text_file", {
        type: "text/plain",
      });

      const fileName = displayAsFile.name;
      const fileId = Date.now().toString();

      const newPdf = {
        id: fileId,
        name: fileName,
        chat: [],
        file: displayAsFile,
      };

      setFileDataList((prevList) => [newPdf, ...prevList]);
      setSelectedFileId(fileId);

      const textContent = await readFile(textFileAsFile);

      const dataArray = textContent.split("\n,");

      if (dataArray.length === 1 && dataArray[0] === "") {
        setChatHistories({ [fileId]: [] });
        return;
      }

      dataArray.forEach((text, index) => {
        const chatMessage = {
          author: index % 2 === 0 ? "bot" : "user",
          type: "text",
          data: { text },
        };

        setChatHistories((prevHistories) => ({
          ...prevHistories,
          [fileId]: [...(prevHistories[fileId] || []), chatMessage],
        }));
      });
    };

    handleFileChange();
  }, [textFile, displayFile]);

  const updateChat = async (userInput) => {
    setIsLoadingAns(true);

    const userMessage = {
      author: "user",
      type: "text",
      data: { text: userInput },
    };

    // Update the chat history for the selected file with the user's message
    const updatedChatHistories = {
      ...chatHistories,
      [selectedFileId]: [...(chatHistories[selectedFileId] || []), userMessage],
    };

    setChatHistories(updatedChatHistories);

    const selectedFileData = fileDataList.find(
      (fileData) => fileData.id === selectedFileId
    );

    if (!selectedFileData) {
      setIsLoadingAns(false);
      return;
    }

    const file = selectedFileData.file;
    const question = userInput.toString();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("question", question);

    try {
      const response = await _http.post(
        "/api/chat_pdf_userquestion", // Use the API endpoint received as a prop
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const botmessage = response?.data?.ai_answer || "No results";

      const botMessage = {
        author: "bot",
        type: "text",
        data: { text: botmessage },
      };

      // Update the chat history for the selected file with the bot's message
      const updatedChatHistoriesBot = {
        ...updatedChatHistories,
        [selectedFileId]: [
          ...(updatedChatHistories[selectedFileId] || []),
          botMessage,
        ],
      };

      setChatHistories(updatedChatHistoriesBot);
      const chatContent = updatedChatHistoriesBot[selectedFileId].map(
        (message) => `${message.data.text}\n`
      );

      // Create a blob and file from chat content
      const chatBlob = new Blob([chatContent], {
        type: "text/plain;charset=utf-8",
      });
      const chatFile = new File([chatBlob], "chat.txt");

      // Send chat history to API endpoint
      try {
        const formData = new FormData();
        formData.append("number", index); // Make sure index is defined correctly
        formData.append("name", sessionStorage.getItem("Name"));
        formData.append("chatfile", chatFile);

        await _http.post("/api/updatebook", formData);
      } catch (error) {
        alert(error);
      }

      setIsLoadingAns(false);
    } catch (error) {
      alert(error);
      setIsLoadingAns(false);
    }
  };

  return (
    <>
      {fileDataList.map(
        (fileData) =>
          fileData.id === selectedFileId && (
            <Three
              key={fileData.id}
              fileData={fileData}
              chatHistories={chatHistories}
              updateChat={updateChat}
              uniqueIdentifier={fileData.id}
              isLoadingAns={isLoadingAns}
            />
          )
      )}
    </>
  );
};

export default Two;
