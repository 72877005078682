import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../../../Features/DarkMode/ThemeContext";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggle, selectIsToggleOn } from "./../../../Redux/ReduceSidebar";
import notificationLogo from "../../../Assets/Navbar/lightnotification.svg";
import settingLogo from "../../../Assets/Navbar/lightsettings.svg";
import dnotificationLogo from "../../../Assets/Navbar/dnotification.svg";
import dsettingLogo from "../../../Assets/Navbar/dsettings.svg";
import logout from "../../../Assets/HeroImages/logout.png";
import hamburger from "../../../Assets/Navbar/lighthamburger.svg";
import dhamburger from "../../../Assets/Navbar/dham.svg";

import DTLogo from "../../../Assets/HomeImages/200x68_Dark.svg";
import DTLogoLight from "../../../Assets/HomeImages/200x68_Light.svg";
import { Tooltip } from "react-tooltip";

export default function Navbartab({ userImage }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const isToggleOn = useSelector(selectIsToggleOn);

  function submitLogout() {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("refreshToken");
    sessionStorage.removeItem("key");
    localStorage.removeItem("userImage");
    navigate("/Login");
  }

  const toggleSidebar = () => {
    dispatch(toggle());
  };

  return (
    <nav
      className={`${theme === "dark" ? "dark-header" : "light-theme"} 
        navbar navbar-expand-lg navbar-light  main-nav-tab
        `}
    >
      <div className="container-fluid" style={{ padding: "0px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className="MinimizeSidebar"
            onClick={toggleSidebar}
            data-tooltip-id="nav-tooltip"
            data-tooltip-content={isToggleOn ? "Close Sidebar" : "Open Sidebar"}
          >
            <img
              className="mx-3 nav-ham"
              src={theme === "dark" ? dhamburger : hamburger}
              alt="img"
            />
          </div>
          <Link className="navbar-brand dtSkill-logo" to="/Home">
            <img src={theme === "dark" ? DTLogoLight : DTLogo} alt="img" />
          </Link>
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
          <form className="flex">
            <Link className="mx-2" to="/Settings">
              <img
                style={{ height: "18px" }}
                src={theme === "dark" ? dsettingLogo : settingLogo}
                alt="icon"
                data-tooltip-id="nav-tooltip"
                data-tooltip-content={"Settings"}
              />
            </Link>
            <Link className="mx-2" to="/Notification">
              <img
                style={{ height: "18px" }}
                src={theme === "dark" ? dnotificationLogo : notificationLogo}
                alt="icon"
                data-tooltip-id="nav-tooltip"
                data-tooltip-content="Notification"
              />
            </Link>
            <Link className="mx-2" to="/GenerativeAI">
              <button className="nav-item navbar-item btn btn-danger ">
                Create GenerativeAI
              </button>
            </Link>
            <span className="nav-item dropdown">
              <span
                style={{ color: "black" }}
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src={userImage}
                  className="nav-item navbar-item nav-user-img"
                  alt="UserImg"
                />
              </span>

              <div
                className="dropdown-menu dropdown-menu-right logout-dropdown "
                aria-labelledby="navbarDropdown"
              >
                <button onClick={submitLogout} className=" btn dropdown-item">
                  Logout
                  <img
                    className="mx-2"
                    style={{ height: "20px" }}
                    src={logout}
                    alt="logout"
                  />
                </button>
              </div>
            </span>
          </form>
        </div>
      </div>
      <Tooltip
        id="nav-tooltip"
        place="bottom"
        style={{
          fontSize: "16px",
          padding: "7px",
          borderRadius: "3px",
          zIndex: 1,
        }}
      />
    </nav>
  );
}
