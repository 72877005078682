import React, { useState } from "react";
import "./Finetuned.css";
import _http from "../../Utils/Api/_http";
import SnackBar from "../../Features/SnackBar";

export default function Finetuned() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({ Submit: false, error: false });
  const [inputText, setInputText] = useState("");
  const [selectModel, setSelectModel] = useState("");
  const [output, setOutput] = useState("");
  const [errors, setError] = useState("");

  const handleClose = (state) => {
    setOpen({ ...state, open: false });
  };

  const handleSubmit = async () => {
    if (!inputText || !selectModel) {
      setOpen({ Submit: true });
      return;
    }
    try {
      setLoading(true);
      const response = await _http.post("/api/predibase", {
        email_body: inputText,
        model_name: selectModel,
      });

      setOutput(response.data);
    } catch (error) {
      setOpen({ error: true });

      if (error.response && error.response?.status === 500) {
        setError("Internal server error");
      } else {
        setError(error.message || "An error occured");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="Finetuned" style={{ backgroundColor: "#f9fafb" }}>
      <div className=" Finetuned-textarea ">
        <div class="mb-3">
          <label for="exampleFormControlTextarea1" class="form-label">
            <h6>Input</h6>
          </label>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="10"
            placeholder="type something..."
            value={inputText}
            onChange={(e) => {
              setInputText(e.target.value);
            }}
          ></textarea>
        </div>

        <button className="btn btn-dark mb-4" onClick={handleSubmit}>
          Run
        </button>

        <div>
          <label class="form-label">
            {" "}
            <h6>Output</h6>{" "}
          </label>
          <div className="rendered-output">
            {loading ? "Loading..." : <>{output}</>}
          </div>
        </div>
      </div>
      <div className="output-section">
        <div className="dropOptions">
          <select
            class="form-select"
            aria-label="Default select example"
            value={selectModel}
            onChange={(e) => setSelectModel(e.target.value)}
          >
            <option selected>LLM Deployment</option>
            <option value="mistral-7b-instruct">Mistral 7b Instruct</option>
            <option value="llama2_7b_chat">Llama 2 7b Chat</option>
            <option value="Gemma2_instruct">Gemma2 Instruct</option>
            <option value="Gemma7_instruct">Gemma7 Instruct</option>
          </select>
        </div>
      </div>
      {
        <SnackBar
          message="Please select a model and type something."
          severity="warning"
          Open={open.Submit}
          handleClose={handleClose}
        />
      }
      {
        <SnackBar
          message={errors}
          severity={"error"}
          handleClose={handleClose}
          Open={open.error}
        />
      }
    </section>
  );
}