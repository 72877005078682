import React from "react";
import ChatWithFiles from "../../../Components/ChatWith/ChatWithFiles";

export default function ChatWithJson() {
  return (
    <ChatWithFiles
      appName="JSON Jargon"
      type="JSON"
      sliceNumber={5}
      fileType=".json"
      apiSummary="jsonsummary"
      apiQuestion="jsonquestion"
      modalContent={
        <>
          <p>
            1) Upload or Drag the JSON.
            <br></br>
            2) The uploaded JSON is displayed to the right.
            <br></br>
            3) A ChatBot with a JSON summary and sample questions are displayed
            to the right of the PDF.
            <br></br>
            4) Ask questions and get the answer from the ChatBot.
            <br></br>
            5) Other features like Bookmark, Reset, Delete, Share, and Export
            are also present.
            <br></br>
          </p>
        </>
      }
      accept=".json"
      maxsize={32}
      dis="AI JSON Interpreter"
    />
  );
}
