import { Navigate, Outlet } from "react-router-dom";
import HomeMain from "./../../Pages/Home-page/HomeMain";
const useAuth = () => {
  const user = {
    loggedIn: localStorage.getItem("jwtToken") === null,
  };
  return user && user.loggedIn;
};

const ProtectedRoutes = ({ responseData }) => {
  const isAuth = useAuth();

  return isAuth ? (
    <Navigate to="/Login" />
  ) : (
    <HomeMain responseData={responseData} />
  );
};
const AdminProductedRoutes = ({ responseData }) => {
  const isAllowed = responseData?.id === 1;
  return isAllowed ? <Navigate to="/NotFound" /> : <Outlet />;
};
export { ProtectedRoutes, AdminProductedRoutes };
