import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../Features/DarkMode/ThemeContext";
import _http from "./../../Utils/Api/_http";

const DisplayBookmark = () => {
  const [bookmarks, setBookmarks] = useState([]);
  const [isZeroLength, setIsZeroLength] = useState(false);
  const navigate = useNavigate();
  const { theme } = useTheme();

  const handleDeleteAllBookmark = async () => {
    try {
      for (let i = 0; i < bookmarks.length; i++) {
        await handleDeleteBookmark(bookmarks[i].serial_no);
      }
      setBookmarks([]);
      setIsZeroLength(true);
    } catch (error) {
      console.error("Error deleting bookmarks:", error);
    }
  };

  const handleDeleteBookmark = async (serialNo) => {
    try {
      await _http.post("/api/deletebookmark", {
        serial_no: serialNo,
        name: sessionStorage.getItem("Name"),
      });

      if (bookmarks.length === 1) {
        setIsZeroLength(true);
        setBookmarks([]);
        return;
      }

      setBookmarks((prevBookmarks) =>
        prevBookmarks.filter((bookmark) => bookmark.serial_no !== serialNo)
      );
    } catch (error) {
      console.error("Error deleting bookmark:", error);
    }
  };

  const showBookmark = (index, appname, description, pdf) => {
    navigate("/SetMyAIproject", {
      state: { index, appname, description, pdf },
    });
  };

  useEffect(() => {
    _http
      .get(`/api/readbookmarks/${sessionStorage.getItem("Name")}`)
      .then((response) => {
        setBookmarks(response.data.reverse());
      })
      .catch((error) => {
        console.error("Error fetching bookmarks:", error);
      });
  }, []);

  return (
    <section
      className={`container ${
        theme === "dark" ? "dark-theme " : "light-background"
      } `}
      style={{ flexDirection: "column" }}
    >
      <div className="d-flex justify-content-between align-items-center my-3">
        <h3>My AI Projects</h3>

        {bookmarks.length > 0 && (
          <button
            className="btn btn-danger"
            onClick={() => handleDeleteAllBookmark()}
          >
            Delete All
          </button>
        )}
      </div>

      {bookmarks && !isZeroLength && bookmarks.length > 0 ? (
        <div className="table-responsive w-100">
          <table className="table ">
            <thead
              className={`${theme === "dark" ? "dark-text" : "light-text"}`}
            >
              <tr>
                <th style={{ width: "10%" }}>Number</th>
                <th style={{ width: "30%" }}>App Name</th>
                <th style={{ width: "40%" }}>Description</th>
                <th style={{ width: "20%" }}>Actions</th>
              </tr>
            </thead>
            <tbody
              className={`${theme === "dark" ? "dark-text" : "light-text"}`}
            >
              {bookmarks.map((bookmark, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{bookmark.app_name}</td>
                  <td>{bookmark.description}</td>
                  <td className="d-flex">
                    <button
                      className="btn btn-outline-success me-2"
                      onClick={() =>
                        showBookmark(
                          bookmark.serial_no,
                          bookmarks[index].app_name,
                          bookmarks[index].description,
                          bookmarks[index].pdf
                        )
                      }
                    >
                      View
                    </button>
                    <button
                      className="btn btn-outline-danger"
                      onClick={() => handleDeleteBookmark(bookmark.serial_no)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p
          className={`empty-message mt-5 ${
            theme === "dark" ? "" : "text-muted"
          }`}
        >
          Please add bookmarks!!!
        </p>
      )}
    </section>
  );
};

export default DisplayBookmark;
