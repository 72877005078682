import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./Login.css";
import { useTheme } from "../../Features/DarkMode/ThemeContext";
import { useUserData } from "./UserDataProvider";
import _http from "./../../Utils/Api/_http";
import logoOfDt from "../../Assets/HomeImages/200x68_Dark.svg";
import DTLogoLight from "../../Assets/HomeImages/200x68_Light.svg";
import CompanyLoginHandler from "./CompanyLoginHandler";
import _nonAuthHttp from "./../../Utils/Api/_nonAuthHttp";
import SnackBar from "../../Features/SnackBar";

export default function Login() {
  const [dataFetched, setDataFetched] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [currentUser, setCurrentUser] = useState(false);
  // const [error, setError] = useState("");
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { updateResponseData } = useUserData();
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState("");
  const { companyLogin, companyLogo } = CompanyLoginHandler();
  const handleClose = () => {
    setOpen(false);
  };

  const usernameChange = (event) => {
    setUsername(event.target.value);
  };

  const passwordChange = (event) => {
    setPassword(event.target.value);
  };
  const validateLoginInput = (username, password) => {
    if (username.length === 0 || password.length === 0) {
      return "Please fill out the fields";
    }
    return null;
  };

  // calling api here to show tools according to user

  const fetchData = async (updateResponseData) => {
    try {
      const response = await _http.post("/api/user_app", {
        username: sessionStorage.getItem("Name"),
      });
      const responseData = response.data[0];
      updateResponseData(responseData);
    } catch (error) {
      setOpen(true);
      setErrors("Error fetching data:");
      console.error("Error fetching data:", error);
    }
  };

  const handleLogin = async (e) => {
    setloading(true);
    e.preventDefault();
    const inputError = validateLoginInput(username, password);

    if (inputError) {
      setOpen(true);
      setErrors(inputError);
      setloading(false);
      return;
    }

    try {
      const res = await _nonAuthHttp.post("/login", {
        username: username,
        password: password,
      });

      if (res.data.error) {
        setOpen(true);
        setErrors(res.data.error);
        setCurrentUser(false);
      } else {
        setCurrentUser(true);
        localStorage.setItem("jwtToken", res.data.output.access_token);
        setCurrentUser(true);
        sessionStorage.setItem("key", "UserLogedIN");
        sessionStorage.setItem("Name", res.data.output.user);
        sessionStorage.setItem("Email", res.data.output.email);

        if (!dataFetched) {
          fetchData(updateResponseData);
          setDataFetched(true);
        }
        navigate("/Home");
      }
    } catch (error) {
      setOpen(true);
      if (error.response && error.response?.status === 500) {
        setErrors("Internal server error ");
      } else if (error.response && error.response?.status === 400) {
        setErrors("Login failed: Incorrect password provided.");
      } else {
        setErrors(error.message);

        console.error("Error during login:", error);
      }
    } finally {
      setCurrentUser(false);
      setloading(false);
    }
  };

  return (
    <main className="main-login-body newLogin">
      <div className="login-image-body">
        <img
          className="newLogin-image"
          src={companyLogin}
          alt="CompanyLogo"
        ></img>
      </div>
      <div className={`login-body`}>
        <div className={`login-card `}>
          <div className="login-logo title">
            <img src={theme === "dark" ? DTLogoLight : logoOfDt} alt="img" />
          </div>
          {companyLogo ? (
            <div className=" title company-logo">
              <img
                className="newLogin-CompanyLogo"
                src={companyLogo}
                alt="CompanyLogo"
              ></img>
            </div>
          ) : (
            ""
          )}
          <div className="title title-margin">
            <span className="aisute-margin">AIsuite Login</span>
          </div>
          <div className="title" style={{ padding: "20px 0px" }}>
            Don't have an account yet?
            <Link className="subtitle" to="/Register">
              Create an account
            </Link>
          </div>
          {currentUser ? (
            <div className="text-center mb-5">Login successful!</div>
          ) : (
            <form className="login-card-form">
              <div className="email-login">
                <div className="login-id">
                  <label htmlFor="username">Official email ID</label>

                  <input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="sample@gmail.com"
                    value={username}
                    onChange={usernameChange}
                    className="form-control form-control-lg"
                    required
                  />
                </div>
                <div className="login-id">
                  <label htmlFor="password">Password</label>

                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="******"
                    onChange={passwordChange}
                    className="form-control form-control-lg"
                    autoComplete="on"
                    required
                  />
                </div>
                {
                  <SnackBar
                    message={errors}
                    severity={"error"}
                    handleClose={handleClose}
                    Open={open}
                  />
                }
              </div>
              {/* {error && <span className="invalid-login-error">{error}</span>} */}

              <div className="login-btn">
                <button
                  onClick={handleLogin}
                  className="btn btn-danger cta-btn"
                  type="button"
                >
                  {loading ? "LOGIN IN..." : "LOGIN"}
                </button>
                <Link className="forget-pass" to="/EmailVerify">
                  Forgot Password?
                </Link>
              </div>
            </form>
          )}
        </div>
      </div>
    </main>
  );
}
