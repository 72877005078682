import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from "../Features/DarkMode/ThemeContext";
import hamburger from "./../Assets/Navbar/lighthamburger.svg";
import dhamburger from "./../Assets/Navbar/dham.svg";
import { useMediaQuery } from "@mui/material";
import { Tooltip } from "react-tooltip";
import close from "./../Assets/SafeDigging/Close.svg";
const style = {
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  color: "white",
};

export default function TransitionsModal({ modalContent }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { theme } = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 376px)");
  const modalWidth = isSmallScreen ? "300" : "600";
  const modalHeight = isSmallScreen ? "550" : "350";

  return (
    <div style={{ width: "100%" }}>
      <Button
        onClick={handleOpen}
        sx={{ color: theme === "dark" ? "white" : "black" }}
        data-tooltip-id="my-tooltip"
        data-tooltip-content={"Description"}
      >
        <img src={theme === "dark" ? dhamburger : hamburger} alt="img" />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              ...style,
              width: modalWidth,
              height: modalHeight,
              left: isSmallScreen ? "42%" : "50%",
              bgcolor: theme === "dark" ? "rgb(32,32,32)" : "white",
              opacity: 0.4,
            }}
          >
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ color: theme === "dark" ? "white" : "black" }}
            >
              Steps to follow!!!
              <div className="mx-4">
                <span className="close-popup " style={{ top: "32px" }}>
                  {" "}
                  <button onClick={handleClose}>
                    {" "}
                    <img src={close} alt="close" />{" "}
                  </button>
                </span>
              </div>
            </Typography>
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2, color: theme === "dark" ? "white" : "black" }}
            >
              <>{modalContent}</>
            </Typography>
          </Box>
        </Fade>
      </Modal>
      <Tooltip id="my-tooltip" place="bottom" style={{ fontSize: "16px" }} />
    </div>
  );
}
