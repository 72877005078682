// //To be used with ChatWithFiles
// import React from "react";
// import ChatWithFiles from "./ChatWithFiles";

// export default function ChatWithImage() {
//     return (
//         <ChatWithFiles
//             appName="Chat With Image"
//             type="IMAGE"
//             // sliceNumber={4}
//             // fileType=".pdf"
//             apiSummary="imgcaption"
//             // apiQuestion="docquestion"
//             modalContent={
//                 <>
//                     <p>
//                         1) Upload or Drag the Image.
//                         <br></br>
//                         2) The uploaded Image is displayed to the right.
//                         <br></br>
//                         3) A ChatBot with a Image summary and sample questions are
//                         displayed to the right of the Image.
//                         <br></br>
//                         4) Ask questions and get the answer from the ChatBot.
//                         <br></br>
//                         5) Other features like Bookmark, Reset, Delete, Share, and
//                         Export are also present.
//                         <br></br>
//                     </p>
//                 </>
//             }
//             accept="image/jpeg, image/png, image/gif"
//             maxsize={32}
//         />
//     );
// }

import React, { useRef, useEffect, useState } from "react";
import "../../../Components/ChatWith/ChatLayout.css";
import ChatSection from "./../../../Components/ChatSection/ChatSection";
import NewLoder from "../../../Features/Loadings/NewLoder";
import { useTheme } from "../../../Features/DarkMode/ThemeContext";
import _http from "./../../../Utils/Api/_http";
import UnderStandButtonPdf from "./../../../Features/UnderStandButtonPdf";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const ChatWithImage = () => {
  const fileInputRef = useRef(null);
  const [pdfDataList, setPdfDataList] = useState([]);
  const [message, setMessage] = useState(null);
  const [selectedPdfId, setSelectedPdfId] = useState(null);
  const [pdfSize, setPdfSize] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAns, setIsLoadingAns] = useState(false);
  const [chatHistories, setChatHistories] = useState({});
  const [pdfUploadPer, setPdfUploadPer] = useState(0);
  const [pageInfoData, setPageInfoData] = useState("");
  const [minimizePdfView, setMinimizePdfView] = useState(false);

  const handelMnimizePdf = () => {
    setMinimizePdfView(!minimizePdfView);
  };
  useEffect(() => {
    setPageInfoData("Chat With Image");
  }, []);
  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileDrop = async (event) => {
    event.preventDefault();
    setPdfUploadPer(0); // Corrected variable name
    const file = event.dataTransfer.files[0];

    if (!file) {
      alert("Select file");
      return;
    }

    // if (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif") {
    //     alert(`File ${file.name} is not a Image. Only Images are allowed.`);
    //     allFilesValid = false;
    //     break;
    // }

    // Call your existing file handling function with the dropped file
    handleFileChange({ target: { files: [file] } });
  };

  const handleFileChange = async (event) => {
    setPdfUploadPer(0);
    const files = event.target.files;

    if (!files || files.length === 0) {
      alert("Select one or more files.");
      return;
    }

    const maxSize = 10 * 1024 * 1024; // 10MB
    let allFilesValid = true;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // if (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif") {
      //     alert(`File ${file.name} is not a Image. Only Images are allowed.`);
      //     allFilesValid = false;
      //     break;
      // }

      //         let fileIsPresent = pdfDataList.find((item) => item.name === file.name);
      //   console.log(fileIsPresent + "file is present");
      //   if (fileIsPresent || fileIsPresent !== undefined) {

      //     alert(`The file name ${file.name} already exists.`);
      //     return;
      //   }

      if (file.size > maxSize) {
        setPdfSize(`File ${file.name} exceeds the size limit (10MB).`);
        allFilesValid = false;
        break;
      }
    }

    if (!allFilesValid) {
      return;
    }

    setIsLoading(true);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await _http.post("/api/imgcaption", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setPdfUploadPer(percentCompleted);
          },
        });

        const pdfUrl = URL.createObjectURL(file);
        const pdfName = file.name;
        const pdfId = Date.now().toString();
        const chatMessage = {
          author: "bot",
          type: "text",
          data: { text: response.data.ai_answer },
        };
        const newPdf = {
          id: pdfId,
          url: pdfUrl,
          name: pdfName,
          chat: [],
          file: file,
        };
        setPdfDataList((prevList) => [newPdf, ...prevList]);
        setSelectedPdfId(pdfId);

        setChatHistories((prevHistories) => ({
          ...prevHistories,
          [pdfId]: [chatMessage],
        }));

        setMessage(chatMessage);
      } catch (error) {
        setIsLoading(false);
        alert(error);
        console.error("Error uploading PDF:", error);
      }
    }

    setIsLoading(false);
    // event.target.value = null;
  };

  const handlePdfSelect = (pdfId) => {
    setSelectedPdfId(pdfId);
  };

  const handlePdfDelete = (pdfId) => {
    const updatedPdfDataList = pdfDataList.filter(
      (pdfDataItem) => pdfDataItem.id !== pdfId
    );
    setPdfDataList(updatedPdfDataList);

    if (selectedPdfId === pdfId) {
      const latestPdf = updatedPdfDataList[updatedPdfDataList.length - 1];
      setSelectedPdfId(latestPdf ? latestPdf.id : null);
    }
  };

  const updateChat = async (userInput) => {
    setIsLoadingAns(true);

    const selectedPdfData = pdfDataList.find(
      (pdfData) => pdfData.id === selectedPdfId
    );

    if (!selectedPdfData) {
      setIsLoadingAns(false);
      return;
    }

    const file = selectedPdfData.file;
    const question = userInput.toString();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("question", question);

    try {
      const response = await _http.post("/api/docquestion", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Extract the bot's message from the server response
      const botmessage = response?.data?.ai_answer || "No results";

      // Create message objects for user and bot
      const userMessage = {
        author: "user",
        type: "text",
        data: { text: userInput },
      };

      const botMessage = {
        author: "bot",
        type: "text",
        data: { text: botmessage },
      };

      // Update the chat history for the selected PDF
      setChatHistories((prevHistories) => ({
        ...prevHistories,
        [selectedPdfId]: [
          ...(prevHistories[selectedPdfId] || []),
          userMessage,
          botMessage,
        ],
      }));

      setIsLoadingAns(false);
      setMessage({ ...botMessage, pdfId: selectedPdfId }); // Include pdfId property
    } catch (error) {
      console.error("Error updating chat:", error);
      setIsLoadingAns(false);
    }
  };

  const updateChatHistories = (newChatHistories) => {
    setChatHistories(newChatHistories);
  };

  const { theme } = useTheme();

  return (
    <div
      className="mini-side-bar"
      onDragOver={(e) => e.preventDefault()} // Prevent the browser's default behavior
      onDrop={handleFileDrop}
    >
      {/* <NavList /> */}

      <div className="containerr">
        <div
          className={`sidebar ${
            theme === "dark" ? "sidebar-dark-border " : "sidebar-light-border "
          } ${theme === "dark" ? "dark-theme" : "light-theme"}`}
        >
          <div className="heading-containerr">
            {" "}
            <div className="page-info-headingg">Chat With Image</div>
            <div className="description-boxx" title="Working Description">
              <UnderStandButtonPdf
                modalContent={
                  <>
                    <p>
                      1) Upload or Drag the Image.
                      <br></br>
                      2) The uploaded Image is displayed to the right.
                      <br></br>
                      3) A ChatBot with a Image summary and sample questions are
                      displayed to the right of the Image.
                      <br></br>
                      4) Ask questions and get the answer from the ChatBot.
                      <br></br>
                      5) Other features like Bookmark, Reset, Delete, Share, and
                      Export are also present.
                      <br></br>
                    </p>
                  </>
                }
              />
            </div>
          </div>

          <div
            style={{ height: "64px", marginBottom: "15px", cursor: "pointer" }}
          >
            <button
              className={`${
                theme === "dark" ? "dark-pdf-upload " : "light-pdf-upload  "
              } upload-button `}
              onClick={handleFileUpload}
            >
              Upload Image
            </button>

            <div className="pdf-sizeError">{pdfSize}</div>
          </div>
          <input
            type="file"
            accept="image/jpeg, image/png, image/gif"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            multiple
          />
          <div className="pdf-Uploaded progress">
            <div
              className={`${
                theme === "dark" ? "dark-button  " : "light-button "
              } progress-bar`}
              role="progressbar"
              aria-valuenow={pdfUploadPer}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${pdfUploadPer}%` }}
            >
              {pdfUploadPer}%{" "}
            </div>
          </div>

          <div className="uploaded-pdfs">
            {pdfDataList.map((pdfData) => (
              <div key={pdfData.id} className="uploaded-pdf">
                <div className="pdf-info">
                  <button
                    className={`uploaded-pdf-button ${
                      pdfData.id === selectedPdfId ? "selected-pdf-button" : ""
                    }`}
                    onClick={() => handlePdfSelect(pdfData.id)}
                    title={pdfData.name}
                  >
                    {pdfData.name}
                  </button>
                  <button
                    className="delete-pdf-button"
                    onClick={() => handlePdfDelete(pdfData.id)}
                    title="Delete PDF"
                  >
                    <DeleteOutlineIcon />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        {isLoading ? (
          <div>
            {" "}
            <NewLoder />{" "}
          </div>
        ) : (
          <>
            <div
              className={`${
                minimizePdfView ? " Hide-pdf-viewer" : "pdf-viewer"
              } `}
            >
              {pdfDataList.map(
                (pdfData) =>
                  pdfData.id === selectedPdfId && (
                    <embed
                      key={pdfData.id}
                      src={pdfData.url}
                      width="100%"
                      height="100%"
                      type="application/pdf"
                    />
                  )
              )}
            </div>
            <div className={`${minimizePdfView ? " full-chat" : "chat"} `}>
              {pdfDataList.map(
                (pdfData) =>
                  pdfData.id === selectedPdfId && (
                    <ChatSection
                      key={pdfData.id}
                      pdfData={pdfData}
                      message={message}
                      updateChat={updateChat}
                      onChatUpdate={updateChat}
                      chatHistories={chatHistories}
                      uniqueIdentifier={pdfData.id}
                      updateChatHistories={updateChatHistories}
                      isLoadingAns={isLoadingAns}
                      pageInfoData={pageInfoData}
                      handelMnimizePdf={handelMnimizePdf}
                    />
                  )
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatWithImage;
