import React from "react";
import "./safedigging.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import jobYes from "../../Assets/SafeDigging/yes.svg";
import jobHot from "../../Assets/SafeDigging/Hot.svg";

import gray_mark from "../../Assets/SafeDigging/shield 22.svg";
import greenTick from "../../Assets/SafeDigging/shield(1).svg";
import corss from "../../Assets/SafeDigging/cross.svg";

import close from "../../Assets/SafeDigging/Close.svg";

function SafeModel({ open, handleClose, selectedRowData }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    height: "80%",
    boxShadow: 24,
    p: 4,
    outline: "none",
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {selectedRowData && (
          <>
            <span className="safe-popup-heading">Safe Digging</span>
            <div className="safe-model">
              <div style={{ borderRight: "1px solid #E8E8E8" }}>
                <div className="my-4">
                  <span className="safe-model-heading ">Job Reference</span>
                  <div className="my-2 safe-model-info">
                    {selectedRowData.job_reference}
                  </div>
                </div>
                <div className="my-4">
                  <span className="safe-model-heading">Utility Provider</span>
                  <div className="my-2 safe-model-info">
                    {" "}
                    {selectedRowData.utility_provider}
                  </div>
                </div>
                <div className="row-flex-class my-4  ">
                  {/* Red Line */}
                  <div>
                    {" "}
                    {selectedRowData.red_line === "Yes" ? (
                      <div className="my-2 safeGreen">
                        <div className="safe-model-heading my-1">Red Line </div>
                        <img src={gray_mark} alt="yes" />{" "}
                      </div>
                    ) : (
                      <div className="my-2 safeGreen">
                        <div className="safe-model-heading my-1">Red Line </div>{" "}
                        <img src={corss} alt="No" />{" "}
                      </div>
                    )}
                  </div>

                  <div className="m-auto  ">
                    {selectedRowData.blue_line === "Yes" ? (
                      <div className="my-2 safeGreen">
                        <div className="safe-model-heading my-1">
                          Blue Line{" "}
                        </div>
                        <img src={gray_mark} alt="yes" />{" "}
                      </div>
                    ) : (
                      <div className="my-2 safeGreen">
                        <div className="safe-model-heading my-1">
                          Blue Line{" "}
                        </div>{" "}
                        <img src={corss} alt="No" />{" "}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row-flex-class my-4">
                  <div>
                    {selectedRowData.green_line === "Yes" ? (
                      <div className="my-2  safeRed ">
                        <div className="safe-model-heading my-1">
                          Green Line{" "}
                        </div>
                        <img src={greenTick} alt="yes" />{" "}
                      </div>
                    ) : (
                      <div className="my-2 safeGreen">
                        <div className="safe-model-heading my-1">
                          Green Line{" "}
                        </div>{" "}
                        <img src={gray_mark} alt="No" />{" "}
                      </div>
                    )}
                  </div>

                  <div className="m-auto ">
                    {selectedRowData.orange_line === "Yes" ? (
                      <div className="my-2  safeRed">
                        <div className="safe-model-heading my-1">
                          Orange Line{" "}
                        </div>
                        <img src={greenTick} alt="yes" />{" "}
                      </div>
                    ) : (
                      <div className="my-2 safeGreen ">
                        <div className="safe-model-heading my-1">
                          Orange Line{" "}
                        </div>{" "}
                        <img src={gray_mark} alt="No" />{" "}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row-flex-class my-4">
                  <div>
                    <span className="safe-model-heading">Image Validation</span>
                    {selectedRowData.image_validation === "Completed" ? (
                      <div>
                        {" "}
                        <span className="safe-pop-completed my-2">
                          Completed
                        </span>{" "}
                      </div>
                    ) : (
                      <div>
                        <span className="safe-pop-Notcompleted my-2">
                          Not Completed
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="m-auto ">
                    <span className="safe-model-heading">
                      Warning Validation
                    </span>
                    {selectedRowData.warning_validation === "Completed" ? (
                      <div>
                        {" "}
                        <span className="safe-pop-completed  my-2">
                          Completed
                        </span>{" "}
                      </div>
                    ) : (
                      <div>
                        <span className="safe-pop-Notcompleted my-2">
                          Not Completed
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="mx-4">
                <span className="close-popup ">
                  {" "}
                  <button onClick={handleClose}>
                    {" "}
                    <img src={close} alt="close" />{" "}
                  </button>
                </span>

                <div className="row-flex-class my-4">
                  <div>
                    {selectedRowData.critical_line === "Yes" ? (
                      <div className="my-2 safeRed ">
                        <div className="safe-model-heading my-1">
                          Critical Line{" "}
                        </div>
                        <img src={greenTick} alt="yes" />{" "}
                      </div>
                    ) : (
                      <div className="my-2 safeGreen">
                        <div className="safe-model-heading my-1">
                          Critical Line{" "}
                        </div>{" "}
                        <img src={gray_mark} alt="No" />{" "}
                      </div>
                    )}
                  </div>

                  <div className="mx-5 ">
                    {selectedRowData.warning_indicator === "Yes" ? (
                      <div className="my-2 safeRed">
                        <div className="safe-model-heading my-1">
                          Warning Validation{" "}
                        </div>
                        <img src={greenTick} alt="yes" />{" "}
                      </div>
                    ) : (
                      <div className="my-2 safeGreen ">
                        <div className="safe-model-heading my-1">
                          Warning Validation{" "}
                        </div>{" "}
                        <img src={gray_mark} alt="No" />{" "}
                      </div>
                    )}
                  </div>
                </div>

                <div className="my-4">
                  <span className="safe-model-heading">Warning Message</span>
                  <div className="my-2 safe-model-info  WarningMessage ">
                    {selectedRowData.warning_message}
                  </div>
                </div>

                <div className="row-flex-class">
                  <div className="">
                    <span className="safe-model-heading">Created Date</span>
                    <div className="my-2 safe-model-info">
                      {selectedRowData.created}
                    </div>
                  </div>

                  <div className=" mx-5">
                    <span className="safe-model-heading">Modified Date</span>
                    <div className="my-2 safe-model-info">
                      {selectedRowData.modified}
                    </div>
                  </div>
                </div>

                <div className="safe-job-popup ">
                  <div className="job-yes-no">
                    <span className="job-reading-heading">
                      Job Ready for Release
                    </span>
                    {selectedRowData.job_ready_for_release === "Yes" ? (
                      <div className="job-img-align  ">
                        <img
                          style={{ height: "170px" }}
                          src={jobYes}
                          alt="Yes"
                        />
                      </div>
                    ) : (
                      <div className="job-img-align    ">
                        {" "}
                        <img
                          style={{ height: "170px" }}
                          src={jobHot}
                          alt="Hot"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
}

export default SafeModel;
