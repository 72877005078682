import React from "react";
import ChatWithFiles from "../../../Components/ChatWith/ChatWithFiles";

export default function ChatLayout() {
  return (
    <ChatWithFiles
      appName="PDF Converse"
      type="PDF"
      sliceNumber={4}
      fileType=".pdf"
      apiSummary="chat_pdf_summary"
      apiQuestion="chat_pdf_userquestion"
      modalContent={
        <>
          <p>
            1) Upload or Drag the PDF.
            <br></br>
            2) The uploaded PDF is displayed to the right.
            <br></br>
            3) A ChatBot with a PDF summary and sample questions are displayed
            to the right of the PDF.
            <br></br>
            4) Ask questions and get the answer from the ChatBot.
            <br></br>
            5) Other features like Bookmark, Reset, Delete, Share, and Export
            are also present.
            <br></br>
          </p>
        </>
      }
      accept=".pdf"
      maxsize={32}
      dis="Interactive PDF Insights"
    />
  );
}
