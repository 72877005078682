// chatwithPage
import ChatWithZip from "../CANbusConversations/CANbus";
import ChatLayout from "../Interact-With-File/ChatWith/PDFConverse";
import ChatWithXl from "../Interact-With-File/ChatWith/DataDialogue";
import ChatWithCSV from "../Interact-With-File/ChatWith/DataDialogueCSV";
import ChatWithLog from "../Interact-With-File/ChatWith/LogLingo";
import ChatWithJson from "../Interact-With-File/ChatWith/JSONJargon";
import ChatWithJS from "../Interact-With-File/ChatWith/CodeChatJS";
import ChatWithDB from "../Interact-With-File/ChatWith/DataSpeak";
import ChatWithXml from "../Interact-With-File/ChatWith/XMLExchange";
import ChatWithDoc from "../Interact-With-File/ChatWith/WordWhisper";
import ChatWithppt from "../Interact-With-File/ChatWith/SlideSage";
import QAGenerator from "../Interact-With-File/ChatWith/QuizCraft";
import StudentAssist from "../Interact-With-File/ChatWith/EduBuddy";

import ChatWithImage from "../Interact-With-File/ChatWith/ChatWithImage";
import ChatWithTxt from "../Interact-With-File/ChatWith/TextTalk";
import ChatWithManual from "../Interact-With-File/ChatWith/ManualDialogue";
import ChatWithPolicies from "../Interact-With-File/ChatWith/PolicyPilot";
import ChatWithWaranty from "../Interact-With-File/ChatWith/WarrantyWords";
import ChatWithBom from "../Interact-With-File/ChatWith/BOMBlueprint";
import ChatWithCrm from "../Interact-With-File/ChatWith/CRMConverse";
import ChatWithErp from "../Interact-With-File/ChatWith/ResourceRover";
import ResumeParser from "../Interact-With-File/ChatWith/ResumeParser";
import ChatWithGPT from "../ChatwithAI/ChatWithGPT";
import ChatWithGPTprompt from "../GptPrompt/GPTprompt";
import ChatWithPY from "../Interact-With-File/ChatWith/PyTalk";
import ChatWithTex from "../Interact-With-File/ChatWith/ChatWithTex";
import ChatWithMd from "../Interact-With-File/ChatWith/MarkdownMentor";
import EnglishtoHindi from "../Interact-With-File/ChatWith/LinguaLink";
//interactWithPage
import TeacherAssist from "../Interact-Tools/QuestionQuiver";
import BioWriter from "../Interact-Tools/BioWriter";
import ProductDetailsDiscription from "../Interact-Tools/ProductDetailsDiscription";
import SummarizeContent from "../Interact-Tools/SummarySphere";
import SimplyfiyContent from "../Interact-Tools/SimplifySpot";
import RewriteContent from "../Interact-Tools/ContentCraft";
import HomepageDescription from "../Interact-Tools/HomeHighlight";
import MetaDataDiscription from "../Interact-Tools/MetaMorph";
import LlamaAi from "../GptPrompt/LlamaAi";
import Zilliz from "../GptPrompt/Zilliz";
import SafeDigging from "./../SafeDigging/SafeDigging";
import Carbonai from "../GptPrompt/Carbonai";
import ImageLatex from "../Hand-writtenOCR/OCR";
import CarbonFileTable from "../GptPrompt/CarbonAI/CarbonFileTable";
import DataPlotter from "../Interact-With-File/ChatWith/DataPlotter";
import Huggingface from "../Huggingface/Huggingface";
import Groq from "../GptPrompt/Groq";
import Finetuned from "../FinetunedApplications/Finetuned";
import Autodesk from "../AutodeskBIM360/Autodesk";
import SALESFORCE from "../SALESFORCE/SALESFORCE";
import SaleTable from "../SALESFORCE/SaleTable";
import Catapult from "../Catapult/Catapult";
import CataTable from "../Catapult/CataTable";

const dynamicRoutes = [
  // Ai
  {
    path: "/chatwith/AI",
    prop: "customai",
    component: <ChatWithGPT />,
  },
  {
    path: "/interactwith/Finetuned",
    prop: "customai",
    component: <Finetuned />,
  },
  {
    path: "/interactwith/autodesk",
    prop: "customai",
    component: <Autodesk />,
  },
  {
    path: "/chatwith/CustomPrompt",
    prop: "customai",
    component: <ChatWithGPTprompt />,
  },
  {
    path: "/chatwith/prompt/LlamaAi",
    prop: "customai",
    component: <LlamaAi />,
  },
  {
    path: "/chatwith/prompt/Huggingface",
    prop: "customai",
    component: <Huggingface />,
  },
  {
    path: "/chatwith/prompt/Groq",
    prop: "customai",
    component: <Groq />,
  },
  {
    path: "/chatwith/Salesforce",
    prop: "customai",
    component: <SALESFORCE />,
  },
  {
    path: "/chatwith/catapult",
    prop: "customai",
    component: <Catapult />,
  },
  {
    path: "/chatwith/prompt/Zilliz",
    prop: "customai",
    component: <Zilliz />,
  },
  {
    path: "/chatwith/prompt/carbonAi",
    prop: "customai",
    component: <Carbonai />,
  },
  {
    path: "/carbonai/files",
    prop: "customai",
    component: <CarbonFileTable />,
  },
  {
    path: "/Salesforce/files",
    prop: "customai",
    component: <SaleTable />,
  },
  {
    path: "/catapult/files",
    prop: "customai",
    component: <CataTable />,
  },
  {
    path: "/chatwith/QuizCraft",
    prop: "customai",
    component: <QAGenerator />,
  },
  {
    path: "/interactwith/LinguaLink",
    prop: "customai",
    component: <EnglishtoHindi />,
  },

  {
    path: "/chatwith/CANbus",
    prop: "customai",
    component: <ChatWithZip />,
  },
  {
    path: "/chatwith/pdf_converse",
    prop: "Chatpdf",
    component: <ChatLayout />,
  },
  {
    path: "chatwith/image",
    prop: "Chatpdf",
    component: <ChatWithImage />,
  },
  // { path: "/chatwith/md", prop: "chatmd", component: <ChatWithMd /> },
  { path: "/chatwith/DataSpeak", prop: "chatdb", component: <ChatWithDB /> },
  { path: "/chatwith/CodeChat_JS", prop: "chatjs", component: <ChatWithJS /> },
  { path: "/chatwith/PyTalk", prop: "chatpy", component: <ChatWithPY /> },
  { path: "/chatwith/LogLingo", prop: "chatlog", component: <ChatWithLog /> },
  {
    path: "/chatwith/tex",
    prop: "chatlatex",
    component: <ChatWithTex />,
  },
  {
    path: "/chatwith/JSON_Jargon",
    prop: "chatjson",
    component: <ChatWithJson />,
  },
  {
    path: "/chatwith/XML_Exchange",
    prop: "chatxml",
    component: <ChatWithXml />,
  },
  {
    path: "/chatwith/SlideSage",
    prop: "chatpptx",
    component: <ChatWithppt />,
  },
  {
    path: "/chatwith/TextTalk",
    prop: "chattext",
    component: <ChatWithTxt />,
  },
  {
    path: "/chatwith/DataDialogue_Excel",
    prop: "chat_excel",
    component: <ChatWithXl />,
  },
  {
    path: "/chatwith/WordWhisper",
    prop: "chatdoc",
    component: <ChatWithDoc />,
  },
  {
    path: "/chatwith/Resume_Parser",
    prop: "Resumeparser",
    component: <ResumeParser />,
  },
  {
    path: "/chatwith/EduBuddy",
    prop: "Studentassist",
    component: <StudentAssist />,
  },
  {
    path: "/chatwith/DataDialogue_CSV",
    prop: "chat_csv",
    component: <ChatWithCSV />,
  },
  {
    path: "/interactwith/data-plotter",
    prop: "chat_csv",
    component: <DataPlotter />,
  },
  {
    path: "/chatwith/Markdown_Mentor",
    prop: "chatmd",
    component: <ChatWithMd />,
  },

  {
    path: "/chatwith/WarrantyWords",
    prop: "Chatpdf",
    component: <ChatWithWaranty />,
  },
  {
    path: "/chatwith/PolicyPilot",
    prop: "Chatpdf",
    component: <ChatWithPolicies />,
  },
  {
    path: "/interactwith/Safe_digging",
    prop: "Chatpdf",
    component: <SafeDigging />,
  },
  {
    path: "/interactwith/Hand-written_OCR",
    prop: "Chatpdf",
    component: <ImageLatex />,
  },
  {
    path: "/chatwith/ResourceRover",
    prop: "Chatpdf",
    component: <ChatWithErp />,
  },
  {
    path: "/chatwith/CRMConverse",
    prop: "Chatpdf",
    component: <ChatWithCrm />,
  },
  {
    path: "/chatwith/BOMBlueprint",
    prop: "Chatpdf",
    component: <ChatWithBom />,
  },
  {
    path: "/chatwith/ManualDialogue",
    prop: "Chatpdf",
    component: <ChatWithManual />,
  },

  {
    path: "/interactwith/MetaMorph",
    prop: "MetaData_desc",
    component: <MetaDataDiscription />,
  },
  {
    path: "/interactwith/Product_Details",
    prop: "Product_details",
    component: <ProductDetailsDiscription />,
  },
  {
    path: "/interactwith/HomeHighlight",
    prop: "Homepage_desc",
    component: <HomepageDescription />,
  },
  {
    path: "/interactwith/ContentCraft",
    prop: "Rewrite_content",
    component: <RewriteContent />,
  },
  {
    path: "/interactwith/SummarySphere",
    prop: "Summarize_content",
    component: <SummarizeContent />,
  },
  {
    path: "/interactwith/SimplifySpot",
    prop: "simplify_content",
    component: <SimplyfiyContent />,
  },
  {
    path: "/interactwith/bio_writter",
    prop: "Biowritter",
    component: <BioWriter />,
  },
  {
    path: "/interactwith/QuestionQuiver",
    prop: "Teacherassist",
    component: <TeacherAssist />,
  },
];
export default dynamicRoutes;
