import React, { useState, useEffect, useRef } from "react";
import "../SafeDigging/safedigging.css";
import "./OCR.css";
import uploadIcon from "../../Assets/SafeDigging/Group.svg";
import SnackBar from "../../Features/SnackBar";

function ImageLatex() {
  const [open, setOpen] = useState({ Submit: false, error: false });
  const [errors, setError] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFile, setCurrentfile] = useState([]);
  const fileInputRef = useRef(null);
  const [apiResponse, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [textValue, setTextValue] = useState(
    apiResponse?.image_to_latext || ""
  );
  const handleClose = (state) => {
    setOpen({ ...state, open: false });
  };
  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
    setCurrentfile(files[0]);
  };

  const uploadFile = async () => {
    if (currentFile.length === 0) {
      setOpen({ Submit: true });
      return;
    }
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("imagetotext", currentFile);
      // using fetch so we will not get cors  error
      const response = await fetch(
        "https://portal.learnasyougo.org/api/v1/imagetotext",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setResponse(data);
    } catch (error) {
      setOpen({ error: true });
      if (error.response && error.response?.status === 500) {
        setError("Internal server error");
      } else {
        setError(error.message || "An error occured");
      }
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  // for setting value of latex in textarea
  useEffect(() => {
    if (apiResponse && apiResponse.image_to_latext) {
      setTextValue(apiResponse.image_to_latext);
    }
  }, [apiResponse]);

  return (
    <section className={` safe-digging-back`} style={{ overflowX: "scroll" }}>
      <div style={{ width: "100vw" }}>
        <div className="safe-digging-header image-box cc  ">
          <div className="uplaod-box">
            <div className="uplaod-box-heading">Upload Image</div>
            <div className="uplaod-box-subhead">
              {selectedFiles.length > 0 ? (
                <>
                  {selectedFiles.map((file, index) => (
                    <div key={index}>
                      <>{file.name?.slice(0, 40)}</>
                    </div>
                  ))}
                </>
              ) : (
                "Only Image files. 32MB max files size."
              )}
            </div>
            <div className="safe-digg-box">
              <div className={`safe-digg-input-box`} onClick={handleClick}>
                <img src={uploadIcon} className="upload-icon-safe" alt="icon" />
                <p className="uplaod-box-subhead">
                  <span className="mx-3">Drag and drop or</span> <br />
                  select an image to upload
                </p>
                <input
                  type="file"
                  accept=".jpeg"
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                  ref={fileInputRef}
                />
              </div>
            </div>
            <div className="safe-submit-btn">
              <button
                disabled={selectedFiles.length <= 0}
                onClick={uploadFile}
                class="btn btn-danger"
                type="button"
              >
                <span
                  className={`${loading ? "spinner-border spinner-border-sm" : ""
                    }`}
                  role="status"
                  aria-hidden="true"
                ></span>
                {loading ? " Uploading" : "Upload"}
              </button>
              {
                <SnackBar
                  message="select an image to upload"
                  severity="warning"
                  Open={open.Submit}
                  handleClose={handleClose}
                />
              }
              <SnackBar
                message={errors}
                severity={"error"}
                handleClose={handleClose}
                Open={open.error}
              />
            </div>
          </div>
          <div className="history-box  " style={{ width: "50%" }}>
            <div className="uplaod-box-heading mx-2">Latex output</div>
            <textarea
              style={{ width: "100%", marginLeft: "10px" }}
              rows="10"
              className="form-control"
              value={textValue}
              onChange={(e) => setTextValue(e.target.value)}
            />
          </div>
        </div>
        {/* {loading ? (
          "Loading...."
        ) : ( */}
        <div className="image-output my-3">
          <div>
            <div className="uplaod-box-heading">Uploaded Image</div>
            <div className="image-box1">
              {selectedFiles.length > 0 ? (
                <img
                  src={URL.createObjectURL(selectedFiles[0])}
                  alt="Uploaded"
                  style={{ width: "100%" }}
                />
              ) : (
                <span className="uplaod-box-subhead">Upload an image</span>
              )}
            </div>
          </div>
          <div>
            <div className="uplaod-box-heading">Rendered output</div>
            <div className="image-box2">
              <a
                href={apiResponse?.iframe_link}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  width: "90% !important",
                  overflow: "hidden !important",
                  border: "none",
                }}
              >
                <iframe
                  src={apiResponse?.iframe_link}
                  style={{
                    width: "90% !important",
                    overflow: "hidden !important",
                    border: "none",
                  }}
                  title="Image iframe"
                  className="iframe-image"
                ></iframe>
              </a>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    </section>
  );
}

export default ImageLatex;
