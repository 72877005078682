import React from "react";
import ChatWithFiles from "../../../Components/ChatWith/ChatWithFiles";

export default function ChatWithCSV() {
  return (
    <ChatWithFiles
      appName="DataDialogue CSV"
      type="CSV"
      sliceNumber={4}
      fileType=".csv"
      apiSummary="csvsummary"
      apiQuestion="csvquestion"
      modalContent={
        <>
          <p>
            1) Upload or Drag the CSV.
            <br></br>
            2) The uploaded CSV is displayed to the right.
            <br></br>
            3) A ChatBot with a CSV summary and sample questions are displayed
            to the right of the CSV.
            <br></br>
            4) Ask questions and get the answer from the ChatBot.
            <br></br>
            5) Other features like Bookmark, Reset, Delete, Share, and Export
            are also present.
            <br></br>
          </p>
        </>
      }
      accept=".csv"
      maxsize={200}
      dis="Your AI-Powered CSV Conversationalist"
    />
  );
}
