import React, { useRef, useState, useMemo, useEffect } from "react";
import { useTheme } from "../../../Features/DarkMode/ThemeContext";
import { useUserData } from "./../../Login-Auth/UserDataProvider";
import rightArrow from "../../../Assets/HeroImages/rightArrow.png";
import leftArrow from "../../../Assets/HeroImages/leftArrow.png";
import "./HomeSlideData.css";
import ArrayData from "./ArrayData";
import Card from "./Card/Card";
import { useSelector } from "react-redux";
import { selectFilter } from "../../../Redux/ReduceSidebar";

export default function HomeSlideDataOne() {
  const containerRef = useRef("");
  const { theme } = useTheme();
  const { responseData } = useUserData();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const filter = useSelector(selectFilter);
  const [allAbsent, setAllAbsent] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const handleResize = () => {
    if (containerRef.current) {
      const isItOverflowing =
        containerRef.current.scrollWidth > containerRef.current.clientWidth;
      setIsOverflowing(isItOverflowing);
    }
  };

  const filteredData = useMemo(
    () =>
      ArrayData(theme, responseData).filter((item) =>
        item.title.toLowerCase().includes(filter.toLowerCase())
      ),
    [theme, responseData, filter]
  );

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // for scrolling left and right

  const handleScroll = (scrollOffset) => {
    if (containerRef.current) {
      const newScrollLeft = containerRef.current.scrollLeft + scrollOffset;
      containerRef.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
      setShowLeftArrow(newScrollLeft > 0);
    }
  };

  useEffect(() => {
    for (let i = 0; i < filteredData.length; i++) {
      if (filteredData[i].value === true) {
        setAllAbsent(false);
        console.log("allAbsent is false");
        return;
      }
    }
    setAllAbsent(true);
    console.log("allAbsent is true");
  }, [filteredData]);

  return (
    <div className="main-card">
      <button
        className={`${theme === "dark" ? " dark-arrow" : ""} right-arrow`}
        onClick={() => handleScroll(470)}
        style={{ display: isOverflowing ? "" : "none" }}
      >
        <img className={`arrow-image`} src={rightArrow} alt="right" />
      </button>

      <button
        className={`${theme === "dark" ? " dark-arrow" : ""} left-arrow`}
        onClick={() => handleScroll(-470)}
        style={{ display: showLeftArrow && isOverflowing ? "" : "none" }}
      >
        <img className={`arrow-image`} src={leftArrow} alt="left" />
      </button>
      <div className="Main-home-heading">You might want to try</div>

      <div className="card-section" ref={containerRef}>
        {allAbsent && (
          <p style={{ margin: "auto" }}>No Results were found....</p>
        )}
        {filteredData.map((item, index) => (
          <Card key={index} item={item} index={index} />
        ))}
      </div>
    </div>
  );
}
