// this tools is not completed

import React, { useState } from "react";
import "./GenerativeAI.css";
import { useTheme } from "./../../Features/DarkMode/ThemeContext";
import SendIconLight from "../../Assets/ChatSectionImages/Send.svg";
import SendIconDark from "../../Assets/ChatSectionImages/dark-send.svg";
import openmainSidebar from "../../Assets/group2.svg";
import SnackBar from "../../Features/SnackBar";
export default function GenerativeAI() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("Description displayed here.");
  const [instructions, setInstructions] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [conversationStarters, setConversationStarters] = useState("");
  const [conversationList, setConversationList] = useState([]);
  const { theme } = useTheme();
  const [hideBox1, setHideBox1] = useState(false);
  const [open, setOpen] = useState({ Submit: false, error: false });
  const [errors, setErrors] = useState();
  const toggleBoxDisplay = () => {
    setHideBox1((prevVal) => !prevVal);
  };

  const addConversationStarters = () => {
    const check = conversationStarters.trim();
    if (check.length === 0) {
      setErrors("Please enter something");
      setOpen({ Submit: true });
      return;
    }
    const newElement = { id: new Date().getTime(), val: conversationStarters };

    setConversationList([...conversationList, newElement]);
    setConversationStarters("");
  };

  const deleteConversationStarters = (idToDelete) => {
    setConversationList((prevList) =>
      prevList.filter((element) => element.id !== idToDelete)
    );
  };

  // useEffect(() => {
  //   console.log(conversationStarters + "list");
  // }, [conversationList]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    const fileExtension = file.name.slice(-4).toLowerCase();

    if (fileExtension !== ".pdf" && fileExtension !== "docx") {
      setOpen({ Submit: true });
      setErrors("Only PDF and DOCX supported.");
      return;
    }

    if (file) {
      setUploadedFileName(file.name);
    }
  };
  const handleClose = (state) => {
    setOpen({ ...state, open: false });
  };
  return (
    <section
      className={`GenerativeAI-container  abc ${
        theme === "dark" ? "" : "light-background"
      }`}
    >
      <div className={`box1`} style={{ display: hideBox1 ? "none" : "" }}>
        <div className="heading-box">
          <h2 className="previewHeading">Configure</h2>

          <button
            type="button"
            className="btn btn-light save-button"
            onClick={toggleBoxDisplay}
          >
            Save
          </button>
        </div>
        <div className="imageContainer">
          <img
            src={`https://imgs.search.brave.com/8uUeT6OWK9pBNDmF44RLvVI2cHgerqoKxv4BaFFjS9w/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9taXJv/Lm1lZGl1bS5jb20v/djIvMCowcUhDejFz/T29Uc3lndTc1`}
            alt="Preview"
            className="centerImage1"
          />
        </div>
        <form className="border-container">
          <div className="form-outline mb-4 form-div">
            <label htmlFor="name">
              <b>Name</b>
            </label>
            <input
              type="text"
              id="name"
              className="form-control mt-1 mb-4"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                console.log({ name });
              }}
            />

            <label htmlFor="Description">
              <b>Description</b>
            </label>
            <input
              type="text"
              id="Description"
              className="form-control mt-1 mb-4  "
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                console.log({ name });
              }}
            ></input>

            <label htmlFor="Instructions">
              <b>Instructions</b>
            </label>
            <textarea
              type="text"
              id="Instructions"
              cols={56}
              rows={5}
              className="form-control mt-1 mb-4"
              value={instructions}
              onChange={(e) => {
                setInstructions(e.target.value);
                console.log({ name });
              }}
            ></textarea>

            <label>
              <b>Conversation Starters</b>
            </label>

            <div className="input-group mb-3 mt-2 add-items">
              <input
                type="text"
                className="form-control input-add-item"
                placeholder="Add conversation starters ..."
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                value={conversationStarters}
                onChange={(e) => {
                  setConversationStarters(e.target.value);
                }}
              />
              <button
                className="btn-add-item"
                type="button"
                onClick={addConversationStarters}
              >
                +
              </button>
            </div>

            <ul className="list-containerrr">
              {conversationList.map((element) => (
                <li className="list-itemss" key={element.id}>
                  <p> {element.val}</p>
                  <div className="closed-icon">
                    <button
                      type="button"
                      onClick={() => deleteConversationStarters(element.id)}
                      className="crossed-btn"
                    >
                      x
                    </button>
                  </div>
                </li>
              ))}
            </ul>

            <p className="knowledge">
              <b>Knowledge</b>
            </p>
            <label
              htmlFor="formId"
              onChange={handleFileChange}
              className="upload-label"
            >
              <input
                className="upload-input"
                name=""
                type="file"
                id="formId"
                accept=".pdf , .doc"
                multiple
                hidden
              />
              <b> {uploadedFileName || "Upload files"}</b>
            </label>
          </div>
          {
            <SnackBar
              message={errors}
              severity={"warning"}
              handleClose={handleClose}
              Open={open.Submit}
            />
          }
        </form>
      </div>
      <div className="box2" style={{ width: hideBox1 ? "100%" : "50%" }}>
        <div className="headingbox2">
          <h2 className="previewHeading2">Preview</h2>
          <button
            type="button"
            className="toggle-button"
            onClick={toggleBoxDisplay}
            style={{ display: hideBox1 ? "" : "none" }}
          >
            <img src={openmainSidebar} alt="Toggle Sidebar" />
          </button>
        </div>

        <div className="imageContainer">
          <img
            src={`https://imgs.search.brave.com/8uUeT6OWK9pBNDmF44RLvVI2cHgerqoKxv4BaFFjS9w/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9taXJv/Lm1lZGl1bS5jb20v/djIvMCowcUhDejFz/T29Uc3lndTc1`}
            alt="Preview"
            className="centerImage2"
          />
          <p>{description}</p>
        </div>

        <div
          className="input-field-container"
          style={{ width: hideBox1 ? "60%" : "100%" }}
        >
          <input
            className="form-control Gen-text-input"
            type="text"
            placeholder="Enter text here ..."
          />
          <button className="submit-button">
            <img
              className="btn-img"
              src={theme === "dark" ? SendIconDark : SendIconLight}
              alt="logo"
            />
          </button>
        </div>
      </div>
    </section>
  );
}
