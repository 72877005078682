import React from "react";
import ChatWithFiles from "../../../Components/ChatWith/ChatWithFiles";

export default function ChatWithDoc() {
  return (
    <ChatWithFiles
      appName="WordWhisper"
      type="DOCX"
      sliceNumber={5}
      fileType=".docx"
      apiSummary="docsummary"
      apiQuestion="docquestion"
      modalContent={
        <>
          <p>
            1) Upload or Drag the DOCX.
            <br></br>
            2) The uploaded DOCX is displayed to the right.
            <br></br>
            3) A ChatBot with a DOCX summary and sample questions are displayed
            to the right of the PDF.
            <br></br>
            4) Ask questions and get the answer from the ChatBot.
            <br></br>
            5) Other features like Bookmark, Reset, Delete, Share, and Export
            are also present.
            <br></br>
          </p>
        </>
      }
      accept=".docx"
      maxsize={32}
      dis="Your AI Document Assistant"
    />
  );
}
