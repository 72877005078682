import React from "react";
import ChatWithMultipleTypeFiles from "../../../Components/ChatWith/ChatWithMultipleTypeFiles";

export default function ChatWithCrm() {
  return (
    <ChatWithMultipleTypeFiles
      appName="CRMConverse"
      dis="Customer Relationship Chat Facilitator"
    />
  );
}
