import React, { useState } from "react";
import "./../../Interact-Tools/Interact-Tools.css";
import Loading from "../../../Features/Loadings/Loading";
import { useTheme } from "../../../Features/DarkMode/ThemeContext";
// import UnderStandButton from "../../../Features/UnderStandButton";
import _http from "../../../Utils/Api/_http";
// import InteractToolsLlogs from "../../../User-Logs/Interact-Tools-logs/InteractToolsLlogs";
import { useSelector } from "react-redux";
import { selectIsToggleOn } from "../../../Redux/ReduceSidebar";
import SnackBar from "../../../Features/SnackBar";

function QAGenerator() {
  const [pdfFile, setPdfFile] = useState(null);
  const [selectOption1, setSelectOption1] = useState("");
  const [selectOption2, setSelectOption2] = useState("");
  const [textInputValue, setTextInputValue] = useState("");
  const [discription, setDiscription] = useState(true);
  const [AI, setAI] = useState(false);
  const [output, setOutput] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const { theme } = useTheme();
  const isToggleOn = useSelector(selectIsToggleOn);
  const [open, setOpen] = useState({
    Submit: false,
    Copy: false,
    error: false,
    Files: false,
  });
  const [errors, setError] = useState("");
  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    if (name === "selectOption1") {
      setSelectOption1(value);
    } else if (name === "selectOption2") {
      setSelectOption2(value);
    }
  };

  const handleTextInputChange = (e) => {
    setTextInputValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (pdfFile === null) {
      setOpen({ Files: true });
      return;
    }
    if (selectOption1 === "" && selectOption2 === "" && textInputValue === "") {
      setOpen({ Submit: true });
      return;
    }

    setOutput("");

    // if (pdfFile.type !== "application/pdf") {
    //     alert("Select PDF file");
    //     return;
    // }

    const filename = pdfFile.name.slice(0, 20);
    const filesize = `${(pdfFile.size / (1024 * 1024)).toFixed(2)} MB`;

    const currentTimeUTC = new Date().toISOString();

    const options = {
      hour12: false,
      timeZone: "UTC",
      timeZoneName: "short",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    const currentTime = new Date(currentTimeUTC).toLocaleString(
      "en-US",
      options
    );
    const userName = sessionStorage.getItem("Name");

    const newLog = {
      currentTime,
      userName,
      application: "QAGenerator - Formative Questions",
      filename,
      filesize,
    };

    let storedLogs = JSON.parse(localStorage.getItem("UserLogsArray"));

    if (!Array.isArray(storedLogs) || !storedLogs.length) {
      storedLogs = [];
    }

    storedLogs.unshift(newLog);

    localStorage.setItem("UserLogsArray", JSON.stringify(storedLogs));

    setisLoading(true);
    setDiscription(false);
    e.preventDefault();

    const formData = new FormData();

    formData.append("filename", pdfFile);
    formData.append("no_of_question", selectOption1);
    formData.append("ques_type", "Mcq type question");
    formData.append("topic", textInputValue);

    try {
      const response = await _http.post("/api/QAGen", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setisLoading(false);
      setOutput(response.data.ai_answer);
      // setTextInputValue("");
    } catch (error) {
      setisLoading(false);
      setOpen({ error: true });
      if (error.response && error.response?.status === 500) {
        setError("Internal server error");
      } else {
        setError(error.message || "An error occured");
      }
    }
  };

  const handelQA = () => {
    setAI(!AI);
  };

  const SummativeQuestions = async (e) => {
    e.preventDefault();

    if (!pdfFile) {
      // alert("Select file");
      setOpen({ Files: true });

      return;
    }
    setOutput("");

    // if (pdfFile.type !== "application/pdf") {
    //     alert("Select PDF file");
    //     return;
    // }
    const filename = pdfFile.name.slice(0, 20);
    const filesize = `${(pdfFile.size / (1024 * 1024)).toFixed(2)} MB`;
    const currentTimeUTC = new Date().toISOString();

    const options = {
      hour12: false,
      timeZone: "UTC",
      timeZoneName: "short",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    const currentTime = new Date(currentTimeUTC).toLocaleString(
      "en-US",
      options
    );
    const userName = sessionStorage.getItem("Name");

    const newLog = {
      currentTime,
      userName,
      application: "QAGenerator - Summative Questions",
      filename,
      filesize,
    };

    let storedLogs = JSON.parse(localStorage.getItem("UserLogsArray"));

    if (!Array.isArray(storedLogs) || !storedLogs.length) {
      storedLogs = [];
    }

    storedLogs.unshift(newLog);

    localStorage.setItem("UserLogsArray", JSON.stringify(storedLogs));

    setisLoading(true);
    setDiscription(false);
    e.preventDefault();

    const formData = new FormData();

    formData.append("filename", pdfFile);

    try {
      const response = await _http.post("/api/Summative_ques", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setisLoading(false);
      setOutput(response.data.ai_answer);
    } catch (error) {
      setisLoading(false);
      setOpen({ error: true });
      if (error.response && error.response?.status === 500) {
        setError("Internal server error");
      } else {
        setError(error.message || "An error occured");
      }
    }
  };

  const handleCopy = () => {
    const contentCopy = output;

    if (contentCopy) {
      const textArea = document.createElement("textarea");
      textArea.value = contentCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      setOpen({ Copy: true });
    }
  };
  const handleClose = (state) => {
    setOpen({
      ...state,
      Submit: false,
      Copy: false,
      error: false,
      Files: false,
    });
  };
  return (
    <section>
      <div
        className={` ${theme === "dark" ? "dark-theme " : "light-background"
          } chatUItwo ${!isToggleOn ? "max" : "min"} `}
      >
        <div className="input-container">
          <div className="">
            <div className="page-info-heading mb-4">
              <div className="headingg">QA Generator</div>

              <button onClick={handelQA} className=" btn btn-secondary">
                {AI ? "Formative Questions" : "Summative Questions"}
              </button>
            </div>

            <form className="border-container" onSubmit={handleSubmit}>
              <div className=" mb-4">
                <input
                  type="file"
                  id="form4Example1"
                  className="form-control"
                  onChange={handleFileChange}
                  accept=".pdf , .docx"
                />
              </div>
              {AI ? (
                <>
                  <button
                    className="btn btn-danger"
                    onClick={SummativeQuestions}
                  >
                    Submit
                  </button>
                  {
                    <SnackBar
                      message="Please select file"
                      severity="warning"
                      Open={open.Files}
                      handleClose={handleClose}
                    />
                  }
                  {
                    <SnackBar
                      message="Please enter values"
                      severity="warning"
                      Open={open.Submit}
                      handleClose={handleClose}
                    />
                  }
                  {
                    <SnackBar
                      message={errors}
                      severity={"error"}
                      handleClose={handleClose}
                      Open={open.error}
                    />
                  }
                </>
              ) : (
                <>
                  <div className="form-outline mb-4">
                    <input
                      placeholder="Number of Questions"
                      id="selectOption1"
                      name="selectOption1"
                      className="form-control "
                      value={selectOption1}
                      onChange={handleSelectChange}
                    ></input>
                  </div>

                  <div className="form-outline mb-4">
                    <select
                      id="selectOption2"
                      name="selectOption2"
                      className="selectpicker form-select form-control"
                      value={selectOption2}
                      onChange={handleSelectChange}
                    >
                      <option value="Mcq type question">MCQs</option>
                    </select>
                  </div>

                  <div className="form-outline mb-4">
                    <textarea
                      placeholder="Topic"
                      type="text"
                      id="form4Example3"
                      value={textInputValue}
                      onChange={handleTextInputChange}
                      cols={56}
                      rows={7}
                      className="form-control"
                    ></textarea>
                  </div>
                  <input
                    className="ch btn bg-danger color"
                    type="submit"
                    value="Submit"
                    style={{ marginTop: "15px" }}
                  />
                  {
                    <SnackBar
                      message="Please select file"
                      severity="warning"
                      Open={open.Files}
                      handleClose={handleClose}
                    />
                  }
                  {
                    <SnackBar
                      message="Please enter values"
                      severity="warning"
                      Open={open.Submit}
                      handleClose={handleClose}
                    />
                  }
                  {
                    <SnackBar
                      message={errors}
                      severity={"error"}
                      handleClose={handleClose}
                      Open={open.error}
                    />
                  }
                </>
              )}
            </form>
          </div>
        </div>
        <div className={` output-container`} id="content">
          <div
            className={` output ${theme === "dark" ? "dark-header" : "light-header"
              } `}
          >
            {isLoading ? (
              <div className="load-center">
                <Loading />
              </div>
            ) : (
              ""
            )}
            {discription ? (
              <div>
                <h5>AI Question Creation Tool for Educators</h5>
                Propel your learning experience. Provide your study materials
                and let this tool generate pertinent questions and answers to
                enhance your grasp and recall.
              </div>
            ) : null}
            {output}
          </div>
          <div style={{ marginTop: "10px" }}>
            <button
              disabled={output === ""}
              onClick={handleCopy}
              type="submit"
              className="btn btn-danger btn-block mb-4"
            >
              Copy
            </button>
            {
              <SnackBar
                message="Text Copied!"
                severity={"success"}
                Open={open.Copy}
                handleClose={handleClose}
              />
            }
          </div>
        </div>
      </div>
    </section>
  );
}

export default QAGenerator;
