import React from "react";
import ChatWithFiles from "../../../Components/ChatWith/ChatWithFiles";

export default function ChatWithppt() {
  return (
    <ChatWithFiles
      appName="SlideSage"
      type="PPTX"
      sliceNumber={5}
      fileType=".pptx"
      apiSummary="pptsummary"
      apiQuestion="pptquestion"
      modalContent={
        <>
          <p>
            1) Upload or Drag the PPTX.
            <br></br>
            2) The uploaded PPTX is displayed to the right.
            <br></br>
            3) A ChatBot with a PPTX summary and sample questions are displayed
            to the right of the PPTX.
            <br></br>
            4) Ask questions and get the answer from the ChatBot.
            <br></br>
            5) Other features like Bookmark, Reset, Delete, Share, and Export
            are also present.
            <br></br>
          </p>
        </>
      }
      accept=".pptx"
      maxsize={32}
      dis="AI-Enhanced Presentation Creator"
    />
  );
}
