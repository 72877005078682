import React from "react";
import ChatWithFiles from "../../../Components/ChatWith/ChatWithFiles";

export default function ChatWithTex() {
  return (
    <ChatWithFiles
      appName="Chat TEX"
      type="TEX"
      sliceNumber={4}
      fileType=".tex"
      apiSummary="texsummary"
      apiQuestion="texquestion"
      modalContent={
        <>
          <p>
            1) Upload or Drag the TEX.
            <br></br>
            2) The uploaded TEX is displayed to the right.
            <br></br>
            3) A ChatBot with a TECX summary and sample questions are displayed
            to the right of the TECX.
            <br></br>
            4) Ask questions and get the answer from the ChatBot.
            <br></br>
            5) Other features like Bookmark, Reset, Delete, Share, and Export
            are also present.
            <br></br>
          </p>
        </>
      }
      accept=".tex"
      maxsize={32}
    />
  );
}
