import React, { useEffect, useState, useRef } from "react";
import samplepdf from "../../Assets/SafeDigging/pdf.svg";
import samplecompleted from "../../Assets/SafeDigging/completed.svg";
import rightArrow from "../../Assets/HeroImages/rightArrow.png";
import leftArrow from "../../Assets/HeroImages/leftArrow.png";
import { useTheme } from "./../../Features/DarkMode/ThemeContext";

export default function SamplePdfs() {
  const { theme } = useTheme();
  const containerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const [samplepdfs, setSamplePdfs] = useState([
    "Wireframe sitemap.pdf",
    "Wireframe sitemap.pdf",
    "Wireframe sitemap.pdf",
    "Wireframe sitemap.pdf",
    "Wireframe sitemap.pdf",
    "Wireframe sitemap.pdf",
    "Wireframe sitemap.pdf",
    "Wireframe sitemap.pdf",
    "Wireframe sitemap.pdf",
    "Wireframe sitemap.pdf",
    "Wireframe sitemap.pdf",
    "Wireframe sitemap.pdf",
  ]);

  const handleResize = () => {
    if (containerRef.current) {
      const isItOverflowing =
        containerRef.current.scrollWidth > containerRef.current.clientWidth;
      setIsOverflowing(isItOverflowing);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleScroll = (scrollOffset) => {
    if (containerRef.current) {
      const newScrollLeft = containerRef.current.scrollLeft + scrollOffset;
      containerRef.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
      setShowLeftArrow(newScrollLeft > 0);
    }
  };

  return (
    <>
      <div className="uplaod-box-heading">Sample PDF</div>
      <div>
        <div className={"main-card"}>
          <button
            className={`${
              theme === "dark" ? " dark-arrow" : ""
            } left-scroll-btn top-scroll-btn`}
            onClick={() => handleScroll(470)}
            style={{ display: isOverflowing ? "" : "none" }}
          >
            <img src={rightArrow} alt="right" />
          </button>

          <button
            className={`${
              theme === "dark" ? " dark-arrow" : ""
            } left-arrow top-scroll-btn `}
            onClick={() => handleScroll(-470)}
            style={{
              display: showLeftArrow && isOverflowing ? "" : "none",
            }}
          >
            <img src={leftArrow} alt="left" />
          </button>
          <div>
            <ul className="sample-pdfs-ul" ref={containerRef}>
              {samplepdfs.map((n) => {
                return (
                  <li className="sample-pdfs-li">
                    <img
                      style={{ margin: "0 4px" }}
                      src={samplepdf}
                      alt="pdf"
                    />
                    {n}
                    <span className="pdf-size">
                      18MB
                      <img src={samplecompleted} alt="" />
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
