import React from "react";
import notAccess from "../Assets/401-error.svg";
import { useTheme } from "./DarkMode/ThemeContext";
import "./NotAccess.css";

const NotAccess = () => {
  const { theme } = useTheme();

  return (
    <section
      className={`work-img  ${
        theme === "dark" ? "dark-theme" : "light-theme"
      }  `}
    >
      <img className="access-denied-img" src={notAccess} alt="" />
    </section>
  );
};
export default NotAccess;
