import React, { useState, useRef, useEffect } from "react";
import expandIcon from "../../Assets/ChatSectionImages/expandIcon.svg";
import wandIcon from "../../Assets/ChatSectionImages/wandIcon.svg";
import quesMark from "../../Assets/ChatSectionImages/quesMark.svg";
import imageIcon from "../../Assets/ChatSectionImages/picIcon.svg";
import linkIcon from "../../Assets/ChatSectionImages/linkIcon.svg";
import ulIcon from "../../Assets/ChatSectionImages/lineIcon1.svg";
import olIcon from "../../Assets/ChatSectionImages/lineIcon.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTheme } from "../../Features/DarkMode/ThemeContext";

const CustomUserInput = ({ handleComposeBox }) => {
  const { theme } = useTheme();
  const [state, setState] = useState({
    textAreaContent: "",
    wordCount: 0,
    composeBox: true,
  });
  const quillRef = useRef();
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const observer = new MutationObserver(() => {
      console.log("mutation seen!");
      // Perform actions on mutation if needed
    });

    observer.observe(container, { childList: true });

    return () => {
      observer.disconnect();
    };
  }, []);
  const handleWordCount = () => {
    const text = quillRef.current?.getEditor().getText();
    const words = text.trim().split(/\s+/).length;
    setState((prevState) => ({
      ...prevState,
      wordCount: words,
    }));
  };

  const handleCompose = () => {
    setState((prevState) => ({
      ...prevState,
      composeBox: !prevState.composeBox,
    }));
    handleComposeBox(state.composeBox);
  };

  const handleInsertLink = () => {
    const userInput = window.getSelection().toString();

    if (userInput !== null) {
      console.log(userInput);
      quillRef.current.getEditor().format("link", userInput);
    }
  };

  const handleInsertImage = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";

    // Listen for the change event of the file input
    fileInput.addEventListener("change", () => {
      const file = fileInput.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = (event) => {
          const imageUrl = event.target.result;
          quillRef.current
            .getEditor()
            .clipboard.dangerouslyPasteHTML(
              quillRef.current.getEditor().getLength(),
              `<img src="${imageUrl}" alt="Image" />`
            );
        };

        // Read the selected image file as a data URL
        reader.readAsDataURL(file);
      }
    });

    // Trigger the click event of the file input
    fileInput.click();
  };

  const handleOrderList = () => {
    quillRef.current.getEditor().format("list", "ordered");
  };

  const handleUnorderList = () => {
    quillRef.current.getEditor().format("list", "bullet");
  };

  return (
    <div className="text-area-container" ref={containerRef}>
      <img
        src={expandIcon}
        style={{ position: "absolute", translate: ".5rem .8rem" }}
        alt="icon"
        onClick={handleCompose}
      />
      <ReactQuill
        className={`textarea ${theme === "dark" ? "dark-background" : ""} ${
          theme === "dark" ? "dark-text" : "light-text"
        }
            `}
        theme="snow"
        id="textarea"
        value={state.textAreaContent}
        onChange={(value) => {
          setState((prevState) => ({
            ...prevState,
            textAreaContent: value,
          }));
          handleWordCount();
        }}
        placeholder="Compose something..."
        ref={quillRef}
      />

      <div className="ques">
        <p>?</p>
      </div>
      <div className="other-write-tools-container">
        <div className="tool1">
          <button className={`${theme === "dark" ? "dark-theme" : ""}`}>
            <img style={{ padding: "0 3px" }} src={wandIcon} alt="icon"></img>
            Continue Writing
          </button>
        </div>
        <div className="tool2">
          <img src={quesMark} alt="icon"></img>
        </div>
        <div className="tool3" onClick={handleOrderList}>
          <img src={olIcon} alt="icon"></img>
        </div>
        <div className="tool4" onClick={handleUnorderList}>
          <img src={ulIcon} alt="icon"></img>
        </div>
        <div className="tool5" onClick={handleInsertLink}>
          <img src={linkIcon} alt="icon"></img>
        </div>
        <div className="tool6" onClick={handleInsertImage}>
          <img src={imageIcon} alt="icon"></img>
        </div>
        <div className="tool7">{state.wordCount} Words</div>
      </div>
    </div>
  );
};

export default CustomUserInput;
