import React, { useState } from "react";
import "./Interact-Tools.css";
import Loading from "../../Features/Loadings/Loading";
// import NavList from "../Home-page/SideNavBar/NavList";
import { useTheme } from "../../Features/DarkMode/ThemeContext";
import UnderStandButtonPdf from "../../Features/UnderStandButtonPdf";
import _http from "../../Utils/Api/_http";
import InteractToolsLlogs from "../User-Logs/Interact-Tools-logs/InteractToolsLlogs";
import SnackBar from "../../Features/SnackBar";

function FileUploadForm() {
  const [pdfFile, setPdfFile] = useState(null);
  const [selectOption1, setSelectOption1] = useState("");
  const [selectOption2, setSelectOption2] = useState("");
  const [textInputValue, setTextInputValue] = useState("");
  const [output, setOutput] = useState("");
  const [discription, setDiscription] = useState(true);
  const [errors, setError] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const { theme } = useTheme();
  const [open, setOpen] = useState({
    Submit: false,
    Copy: false,
    error: false,
    File: false,
    PDF: false,
  });

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    if (name === "selectOption1") {
      setSelectOption1(value);
    } else if (name === "selectOption2") {
      setSelectOption2(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectOption1 === "" || selectOption2 === "" || textInputValue === "") {
      setOpen({ Submit: true });
      return;
    }

    if (!pdfFile) {
      setOpen({ File: true });
      return;
    }

    if (pdfFile.type !== "application/pdf") {
      setOpen({ PDF: true });
      return;
    } else {
      setOutput("");
      setisLoading(true);
      setDiscription(false);
      e.preventDefault();

      const formData = new FormData();

      formData.append("filename", pdfFile);
      formData.append("no_of_question", selectOption1);
      formData.append("ques_type", selectOption2);
      formData.append("topic", textInputValue);

      try {
        const response = await _http.post("/api/teacherassist", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setisLoading(false);
        setOutput(response.data.ai_answer);
      } catch (error) {
        setisLoading(false);
        setOpen({ error: true });
        if (error.response && error.response?.status === 500) {
          setError("Internal server error");
        } else {
          setError(error.message || "An error occured");
        }
      }
    }
  };

  const handleCopy = () => {
    const contentCopy = output;

    if (contentCopy) {
      const textArea = document.createElement("textarea");
      textArea.value = contentCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      setOpen({ Copy: true });
    }
  };
  const handleClose = (state) => {
    setOpen({ ...state, open: false });
  };

  const logUserAction = () => {
    return (
      <InteractToolsLlogs
        application="Teacher Assist "
        filename="---"
        filesize="---"
      />
    );
  };
  return (
    <section>
      {logUserAction()}
      <div
        className={` ${theme === "dark" ? "dark-theme " : "light-background"
          } chatUItwo `}
      >
        <div className="input-container">
          <div className="">
            <div className="page-info-heading mb-3">
              <div className="headingg">QuestionQuiver</div>
              <div className="description">
                <UnderStandButtonPdf
                  modalContent={
                    <>
                      <p>
                        1) Upload a file to generate questions and answers.
                        <br></br>
                        2) Enter the number of questions-answer sets required
                        <br></br>
                        3) Select the desired answer type like - Long Answer,
                        Short Answer, etc.
                        <br></br>
                        4) Enter the topic corresponding to which you want to
                        generate questions.
                        <br></br>
                        5) Click the submit button to see the generated
                        question-answers sets.<br></br> 6) Click the 'Understand
                        how it works!' button to know the application of the
                        tool.
                        <br></br>
                        7) Copy the content by clicking the 'Copy' button.
                        <br></br>
                      </p>
                    </>
                  }
                />
              </div>
            </div>
            <form className="border-container" onSubmit={handleSubmit}>
              <div className=" mb-4">
                <input
                  type="file"
                  // id="pdfFile"
                  id="form4Example1"
                  className="form-control"
                  onChange={(e) => setPdfFile(e.target.files[0])}
                  accept=".pdf"
                />
              </div>

              <div className="form-outline mb-4">
                <input
                  placeholder="Number of Questions"
                  id="selectOption1"
                  name="selectOption1"
                  // id="form4Example1"
                  className="form-control "
                  value={selectOption1}
                  onChange={handleSelectChange}
                >
                  {/* <option value="">Number of Questions</option>
                    <option value="1">1</option>
                    <option value="2">2</option> */}
                </input>
              </div>

              <div className="form-outline mb-4">
                <select
                  id="selectOption2"
                  name="selectOption2"
                  // id="mySelect"
                  className="selectpicker form-select form-control"
                  value={selectOption2}
                  onChange={handleSelectChange}
                >
                  <option disabled value="">
                    Answer type
                  </option>
                  <option value="Long type question">Long Answer </option>
                  <option value="Short type question">Short Answer</option>
                  <option value="Mcq type question">MCQs</option>
                  <option value="true or false type question">
                    True or False
                  </option>
                  <option value="fill in the blanks type question">
                    Fill in The Blanks
                  </option>
                </select>
              </div>

              <div className="form-outline mb-4">
                <textarea
                  placeholder="Topic"
                  type="text"
                  id="form4Example3"
                  value={textInputValue}
                  onChange={(e) => setTextInputValue(e.target.value)}
                  cols={56}
                  rows={7}
                  className="form-control"
                ></textarea>
              </div>
              <>
                <input
                  className="ch btn bg-danger color"
                  type="submit"
                  value="Submit"
                  style={{ marginTop: "15px" }}
                />
                {
                  <SnackBar
                    message="Please enter values"
                    severity="warning"
                    Open={open.Submit}
                    handleClose={handleClose}
                  />
                }
                {
                  <SnackBar
                    message={errors}
                    severity={"error"}
                    handleClose={handleClose}
                    Open={open.error}
                  />
                }
                {
                  <SnackBar
                    message="Select PDF File"
                    severity="warning"
                    Open={open.File}
                    handleClose={handleClose}
                  />
                }
              </>
            </form>
          </div>
        </div>
        <div className={` output-container`} id="content">
          <div
            className={` output ${theme === "dark" ? "dark-header" : "light-header"
              } `}
          >
            {isLoading ? (
              <div className="load-center">
                <Loading />
              </div>
            ) : (
              ""
            )}
            {discription ? (
              <div>
                <h5>Educator's AI Question Forge</h5>
                Propel your learning experience. Provide your study materials
                and let this tool generate pertinent questions and answers to
                enhance your grasp and recall.
              </div>
            ) : null}
            {output}
          </div>
          <div style={{ marginTop: "10px" }}>
            <button
              disabled={output === ""}
              onClick={handleCopy}
              type="submit"
              className="btn btn-danger btn-block mb-4"
            >
              Copy
            </button>
            {
              <SnackBar
                message="Text Copied!"
                severity={"success"}
                Open={open.Copy}
                handleClose={handleClose}
              />
            }
          </div>
        </div>
      </div>
    </section>
  );
}

export default FileUploadForm;
