import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logoOfDt from "../../Assets/HomeImages/200x68_Dark.svg";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "../../Features/DarkMode/ThemeContext";
import DTLogoLight from "../../Assets/HomeImages/200x68_Light.svg";
import _nonAuthHttp from "./../../Utils/Api/_nonAuthHttp";
import resetPaas from "./../../Assets/Reset password.svg";

const PasswordReset = () => {
  const { theme } = useTheme();
  const [newPassword, setNewPassword] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
    }, 1500);

    return () => clearTimeout(timer);
  }, [message]);

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(newPassword));
  }, [newPassword]);

  async function handlePasswordReset(e) {
    e.preventDefault();

    if (!validPwd) {
      setMessage(
        "Invalid password format. Please ensure it meets the criteria."
      );
      return;
    } else if (newPassword === "") {
      setMessage("Empty password field. Please provide a new password.");
      return;
    }

    const urlParams = window.location.pathname.split("/").filter(Boolean);
    const token = urlParams[1];
    const username = urlParams[2];

    console.log(token, username, "here");

    try {
      const res = await _nonAuthHttp.post(
        `/reset_password/${token}/${username}/`,
        {
          new_password: newPassword,
        }
      );

      if (res.data.bool) {
        setMessage(
          "Password reset completed successfully! Please log in using the new password."
        );
        setTimeout(() => {
          navigate("/Login");
        }, 1500);
      } else {
        setMessage("Password reset failed.");
      }
    } catch (error) {
      setMessage("Some Issue Occurred");
    }
  }

  return (
    <main className="main-login-body newLogin">
      <div className="login-image-body">
        <img className="newLogin-image" src={resetPaas} alt="CompanyLogo" />
      </div>
      <div className="login-body">
        <div className={`login-card newLogin-card`}>
          <div className="login-logo title">
            <img src={theme === "dark" ? DTLogoLight : logoOfDt} alt="img" />
          </div>
          <div className="title" style={{ padding: "20px 0px" }}>
            <h3>Password Reset</h3>
          </div>

          {message && (
            <div className="alert alert-info" role="alert">
              {message}
            </div>
          )}

          <form className="login-card-form">
            <div className="email-login">
              <div className="login-id">
                <label htmlFor="psw">Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter New Password"
                  value={newPassword}
                  onChange={handlePasswordChange}
                  aria-invalid={validPwd ? "false" : "true"}
                  aria-describedby="pwdnote"
                  className="form-control form-control-lg"
                  required
                />
              </div>
            </div>
            <p
              id="pwdnote"
              style={{
                display: newPassword.length > 0 && !validPwd ? "block" : "none",
              }}
              className={`alert alert-danger ${
                validPwd ? "offscreen" : "instructions"
              }`}
              role="alert"
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              8 to 24 characters.
              <br />
              Must include uppercase and lowercase letters, a number, and a
              special character.
              <br />
              Allowed special characters:[!@#$%]
            </p>

            <button
              onClick={(event) => {
                handlePasswordReset(event);
              }}
              className="btn btn-danger cta-btn"
              type="button"
            >
              Reset
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default PasswordReset;
