import React from "react";
import ChatWithMultipleTypeFiles from "../../../Components/ChatWith/ChatWithMultipleTypeFiles";

export default function ChatWithPolicies() {
  return (
    <ChatWithMultipleTypeFiles
      appName="PolicyPilot"
      dis="AI Policy Guidance Expert"
    />
  );
}
