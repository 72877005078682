import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";

const appSlice = createSlice({
  name: "app",
  initialState: {
    isToggleOn: true,
  },
  reducers: {
    toggle: (state) => {
      state.isToggleOn = !state.isToggleOn;
    },
  },
});
const imageSlice = createSlice({
  name: "image",
  initialState: {
    isimageOn: true,
  },
  reducers: {
    toggleImage: (state) => {
      state.isimageOn = !state.isimageOn;
    },
  },
});

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    isSidebarOn: true,
  },
  reducers: {
    toggleSidebar: (state) => {
      state.isSidebarOn = !state.isSidebarOn;
    },
  },
});

const MainSidebar = createSlice({
  name: "mainsidebar",
  initialState: {
    isMainSidebarOn: true,
  },
  reducers: {
    toggleMainSidebar: (state) => {
      state.isMainSidebarOn = !state.isMainSidebarOn;
    },
  },
});

const filterSlice = createSlice({
  name: "filter",
  initialState: "",
  reducers: {
    setFilter: (state, action) => {
      return action.payload;
    },
  },
});
const rootReducer = {
  app: persistReducer(
    { key: "root", storage: sessionStorage, whitelist: ["isToggleOn"] },
    appSlice.reducer
  ),
  sidebar: sidebarSlice.reducer,
  mainsidebar: MainSidebar.reducer,
  image: imageSlice.reducer,
  filter: filterSlice.reducer,
};

export const { setFilter } = filterSlice.actions;
export const { toggle } = appSlice.actions;
export const { toggleSidebar } = sidebarSlice.actions;
export const { toggleMainSidebar } = MainSidebar.actions;
export const { toggleImage } = imageSlice.actions;

export const selectFilter = (state) => state.filter;
export const selectIsToggleOn = (state) => state.app.isToggleOn;
export const selectIsSidebarOn = (state) => state.sidebar.isSidebarOn;
export const selectIsMainSidebarOn = (state) =>
  state.mainsidebar.isMainSidebarOn;
export const selectIsImageOn = (state) => state.image.isimageOn;

export default rootReducer;
