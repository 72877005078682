import React, { useState } from "react";
import Loading from "../../Features/Loadings/Loading";
import "./Interact-Tools.css";
import { useTheme } from "../../Features/DarkMode/ThemeContext";
import UnderStandButtonPdf from "../../Features/UnderStandButtonPdf";
import _http from "../../Utils/Api/_http";
import InteractToolsLlogs from "../User-Logs/Interact-Tools-logs/InteractToolsLlogs";
import SnackBar from "../../Features/SnackBar";

export default function SummarizeContent() {
  const [inputSummary, setInputSummary] = useState("");
  const [answer, setAnswer] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [open, setOpen] = useState({
    Submit: false,
    Copy: false,
    error: false,
  });
  const { theme } = useTheme();
  const [errors, setError] = useState("");
  const [discription, setDiscription] = useState(true);

  const handleSubmit = async () => {
    if (inputSummary === "") {
      setOpen({ Submit: true });
      return;
    } else {
      setAnswer("");
      setisLoading(true);
      setDiscription(false);
      try {
        const response = await _http.post("/api/Summary_content", {
          content: inputSummary,
        });
        setisLoading(false);
        setAnswer(response.data.ai_answer);
      } catch (error) {
        setisLoading(false);
        setOpen({ error: true });
        if (error.response && error.response?.status === 500) {
          setError("Internal server error");
        } else {
          setError(error.message || "An error occured");
        }
      }
    }
  };
  const handleClose = (state) => {
    setOpen({ ...state, Submit: false, Copy: false, error: false });
  };

  const handleCopy = () => {
    const contentCopy = answer;

    if (contentCopy) {
      const textArea = document.createElement("textarea");
      textArea.value = contentCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      setOpen({ Copy: true });
    }
  };

  const logUserAction = () => {
    return (
      <InteractToolsLlogs
        application="Summarize Content "
        filename="---"
        filesize="---"
      />
    );
  };

  return (
    <section>
      {logUserAction()}
      <div
        className={` ${theme === "dark" ? "dark-theme " : "light-background"
          } chatUItwo `}
      >
        <div className="input-container">
          <div className="">
            <>
              <div className="page-info-heading mb-3">
                <div className="headingg">SummarySphere</div>
                <div className="description">
                  <UnderStandButtonPdf
                    modalContent={
                      <>
                        <p>
                          1) Enter or paste the content you want to summarize.
                          <br></br>
                          2) Click the submit button to see the content summary
                          in the output window on the right.
                          <br></br>
                          3) Click the hamburger icon to know the application of
                          the tool.
                          <br></br>
                          4) Copy the content by clicking the 'Copy' button.
                          <br></br>
                        </p>
                      </>
                    }
                  />
                </div>
              </div>
              <div className="border-container">
                <div className="form-outline mb-4">
                  <textarea
                    value={inputSummary}
                    onChange={(e) => {
                      setInputSummary(e.target.value);
                    }}
                    cols={80}
                    rows={10}
                    type="text"
                    className="form-control"
                    id="form4Example3"
                  ></textarea>
                </div>

                <div>
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="btn btn-danger btn-block"
                    style={{ marginTop: "10px" }}
                  >
                    Submit
                  </button>
                  {
                    <SnackBar
                      message="Please type something..."
                      severity="warning"
                      Open={open.Submit}
                      handleClose={handleClose}
                    />
                  }
                  {
                    <SnackBar
                      message={errors}
                      severity={"error"}
                      handleClose={handleClose}
                      Open={open.error}
                    />
                  }
                </div>
              </div>
            </>
          </div>
        </div>
        <div className={` output-container`} id="content">
          <div
            className={` output ${theme === "dark" ? "dark-header" : "light-header"
              } `}
          >
            {isLoading ? (
              <div className="load-center">
                <Loading />
              </div>
            ) : (
              ""
            )}
            {discription ? (
              <div>
                <h5>Concise Content Synthesizer</h5>
                Get the gist without the fluff. Input your lengthy articles or
                reports, and receive concise summaries that capture the essence
                seamlessly.
              </div>
            ) : null}
            {answer}
          </div>
          <div style={{ marginTop: "10px" }}>
            <button
              disabled={answer === ""}
              onClick={handleCopy}
              type="submit"
              className="btn btn-danger btn-block mb-4"
            >
              Copy
            </button>
            {
              <SnackBar
                message="Text Copied!"
                severity={"success"}
                Open={open.Copy}
                handleClose={handleClose}
              />
            }
          </div>
        </div>
      </div>
    </section>
  );
}
