import React, { useState, useEffect, useRef } from "react";
import _http from "../../Utils/Api/_http";
import dSendIcon from "./../../Assets/ChatSectionImages/dark-send.svg";
import SendIcon from "./../../Assets/ChatSectionImages/Send.svg";
import backimage from "./../../Assets/background-gpt.svg";
import { useTheme } from "../../Features/DarkMode/ThemeContext";
import "../GptPrompt/customPrompt.css";
import "./hugface.css";
import SnackBar from "../../Features/SnackBar";

const SafeHugging = () => {
  const [open, setOpen] = useState({ Submit: false, error: false });
  const [errors, setError] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [showInitialImage, setShowInitialImage] = useState(true);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const { theme } = useTheme();
  const [selectModel, setSelectModel] = useState("");
  const textareaRef = useRef(null);

  const handleClose = (state) => {
    setOpen({ ...state, open: false });
  };

  // for automatic scrolling to bottom of chat
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };

  const handleInputChange = (e) => {
    setCurrentQuestion(e.target.value);
    const textarea = e.target;
    const rows = Math.min(Math.max(1, textarea.value.split("\n").length), 5);
    textarea.rows = rows;
  };

  const handleAsk = async () => {
    textareaRef.current.rows = 1;

    if (!currentQuestion || !selectModel) {
      setOpen({ Submit: true });
    } else {
      setCurrentQuestion("");
      setLoading(true);
      setShowInitialImage(false);

      try {
        const formData = new FormData();
        formData.append("query", currentQuestion);
        formData.append("END_POINT", selectModel || "mistral");

        const questionId = Date.now();
        addChatMessage(currentQuestion, null, questionId);

        const response = await _http.post("/api/HuggingFace", formData);
        const answerReceived = await response.data.Output;
        updateLastChatMessage(answerReceived, questionId);
      } catch (error) {
        setOpen({ error: true });

        if (error.response && error.response?.status === 500) {
          setError("Internal server error");
        } else {
          setError(error.message || "An error occured");
        }
      } finally {
        const currentTimeUTC = new Date().toISOString();

        const options = {
          hour12: false,
          timeZone: "UTC",
          timeZoneName: "short",
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };

        const currentTime = new Date(currentTimeUTC).toLocaleString(
          "en-US",
          options
        );
        const userName = sessionStorage.getItem("Name");

        const newLog = {
          currentTime,
          userName,
          application: "HuggingFace",
          filename: "---",
          filesize: "---",
        };

        let storedLogs = JSON.parse(localStorage.getItem("UserLogsArray"));

        if (!Array.isArray(storedLogs) || !storedLogs.length) {
          storedLogs = [];
        }

        storedLogs.unshift(newLog);

        localStorage.setItem("UserLogsArray", JSON.stringify(storedLogs));

        setLoading(false);
      }
    }
  };

  const addChatMessage = (question, answer, questionId) => {
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { question, answer, id: questionId },
    ]);
  };

  const updateLastChatMessage = (answer, questionId) => {
    setChatMessages((prevMessages) => {
      const updatedMessages = prevMessages.map((msg) =>
        msg.id === questionId ? { ...msg, answer } : msg
      );
      return updatedMessages;
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages, currentQuestion]);

  return (
    <section
      className={`${theme === "dark" ? "dark-theme " : "light-background"
        } chat-area`}
    >
      <span className="select-model">
        <input
          type="text"
          className="form-control"
          placeholder="Type EndPoint"
          value={selectModel}
          onChange={(e) => {
            setSelectModel(e.target.value);
          }}
        />
      </span>
      <div className="chat-window">
        <span>
          {chatMessages.map((message) => (
            <div
              key={message.id}
              className="chat-message"
              style={{ padding: "10px 0px" }}
            >
              <div
                className={`question  ${theme === "dark"
                    ? "user-message-dark-gpt"
                    : "user-message-light-gpt"
                  }`}
              >
                {message.question.trim()}
              </div>
              {loading && message.answer === null ? (
                <div style={{ margin: "20px" }} className="loader-gpt"></div>
              ) : (
                <div className="answer">
                  <p style={{ margin: "0" }}>
                    {message.answer || "Something went wrong"}
                  </p>
                </div>
              )}
            </div>
          ))}
          <div ref={messagesEndRef} />
          {showInitialImage && (
            <div className="gpt-image">
              <div>
                <img src={backimage} alt="img" />
              </div>
              <div>
                <h4>How can I help you today?</h4>
              </div>
            </div>
          )}
        </span>
      </div>

      <div className={`custom-prompt-input-area-with-pdf`}>
        <div className="input-wrapper-hugging-face">
          <textarea
            ref={textareaRef}
            type="text"
            className={`textarea-ca1 ${theme === "dark" ? "dark-text" : "light-text"
              } ${theme === "dark" ? "dark-background" : ""}`}
            value={currentQuestion}
            onChange={handleInputChange}
            placeholder="Ask a question..."
            onKeyPress={(event) => {
              if (event.key === "Enter" && !loading && !event.shiftKey) {
                event.preventDefault(); // Prevents the default behavior of adding a newline
                handleAsk();
              }
            }}
            rows={1}
            maxrows={5}
          />
          <button onClick={handleAsk} disabled={loading} className="send-btn">
            <img src={`${theme === "dark" ? dSendIcon : SendIcon}`} alt="" />
          </button>
          {
            <SnackBar
              message="Please type a EndPoint and  ask a question..."
              severity="warning"
              Open={open.Submit}
              handleClose={handleClose}
            />
          }
          {
            <SnackBar
              message={errors}
              severity={"error"}
              handleClose={handleClose}
              Open={open.error}
            />
          }
        </div>
      </div>
    </section>
  );
};

export default SafeHugging;
