import React from "react";
import ChatArea from "./ChatArea";
import "./gpt.css";

const ChatWithGPT = () => {
  return (
    <>
      <ChatArea />
    </>
  );
};

export default ChatWithGPT;
