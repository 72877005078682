import React from "react";
import "./card.css";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../../Features/DarkMode/ThemeContext";

const Card = ({ item, index }) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  return item.value ? (
    <li key={index} onClick={() => navigate(item.link)}>
      {/* <Link to={item.link}>  */}
      <div>
        <div
          className={`${
            theme === "dark" ? "dark-outer-icon " : "light-outer-icon"
          } outer-icon`}
        >
          <div
            className={`${
              theme === "dark" ? "dark-theme " : "light-theme "
            } main-home-slide-icons`}
          >
            {item.icon}
          </div>
        </div>
      </div>
      <div
        className={`${
          theme === "dark" ? "dark-text " : "light-text "
        } main-home-slide-title`}
      >
        {item.title}
      </div>
      {/* </Link> */}
    </li>
  ) : null;
};

export default Card;
