import React, { useEffect, useState } from "react";
// import deleteIcon from "../../../Assets/ChatSectionImages/deleteIcon.svg";
import greenTick from "./../../../Assets/SafeDigging/green.svg";
import gray_mark from "./../../../Assets/SafeDigging/cross.svg";

const CarbonFileTable = () => {
  const [responseData, setResponseData] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState("PDF");
  const [loading, setLoading] = useState(true);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().slice(0, 19).replace("T", " ");

    return formattedDate;
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const apiUrl = "https://api.carbon.ai/user_files_v2";
      const requestBody = {
        order_by: "file_size",
        order_dir: "desc",
        filters: { source: selectedFileType },
        include_parsed_text_file: true,
        include_additional_files: true,
        include_raw_file: true,
      };

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            Authorization:
              "Bearer d902e457817b81dd74a2f905606a78eee6aef1666fe478bef18287efa674b426",
            "customer-id": "1",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error("Error fetching data");
        }

        const data = await response.json();
        setResponseData(data);
      } catch (error) {
        console.error("Error:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedFileType]);

  const handleFileTypeChange = (event) => {
    setSelectedFileType(event.target.value);
  };

  return (
    <section
      className="container "
      style={{ backgroundColor: "#f9fafb", flexDirection: "column" }}
    >
      <select
        className="form-select my-4"
        aria-label="Default select example"
        value={selectedFileType}
        onChange={handleFileTypeChange}
        style={{ width: "200px" }}
      >
        <option value="DOCX">DOCX</option>
        <option value="PDF">PDF</option>
        <option value="CSV">CSV</option>
      </select>
      <table
        className="table  custom-table"
        style={{ backgroundColor: "#FFF" }}
      >
        <thead className="custom-table-header ">
          <tr>
            <th>File Format</th>
            <th>File Size</th>
            <th>Created At</th>
            <th>Name</th>
            <th>Hybrid_search</th>
            <th>Download</th>
            {/* <th>Delete</th> */}
          </tr>
        </thead>
        <tbody className="custom-table-body">
          <tr class="gap-row-header"></tr>
          {loading ? (
            <>
              {[...Array(5)].map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {[...Array(6)].map((_, colIndex) => (
                    <td key={colIndex} className="placeholder-glow">
                      <div className="placeholder col-12 my-2">..</div>
                    </td>
                  ))}
                </tr>
              ))}
            </>
          ) : (
            <>
              {responseData.results.map((result, index) => (
                <>
                  <tr key={index}>
                    <td>{result.file_statistics.file_format}</td>
                    <td>
                      {(
                        result.file_statistics.file_size /
                        (1024 * 1024)
                      ).toFixed(2)}{" "}
                      MB
                    </td>
                    <td> {formatDate(result.created_at)}</td>
                    <td>{result.name}</td>
                    <td>
                      {result.generate_sparse_vectors ? (
                        <img src={greenTick} alt="YES" />
                      ) : (
                        <img src={gray_mark} alt="NO" />
                      )}
                    </td>
                    <td>
                      <a href={result.presigned_url}>
                        <img
                          style={{ height: "25px" }}
                          src="https://imgs.search.brave.com/Q_FrOTcfEcQnkrkdPHwDghQ8RjcQ_Wiq9N-AS1fsM0c/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9jZG4t/aWNvbnMtcG5nLmZy/ZWVwaWsuY29tLzI1/Ni8yOTg5LzI5ODk5/NzYucG5n"
                          alt=""
                        />
                      </a>
                    </td>
                    {/* <img src={deleteIcon} alt="delete" /> */}
                  </tr>
                </>
              ))}
            </>
          )}
        </tbody>
      </table>
    </section>
  );
};

export default CarbonFileTable;
