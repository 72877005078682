import React from "react";
import FileViewer from "react-file-viewer";

const PdfViewer = ({ url, fileType }) => {
  return (
    <FileViewer
      style={{ overflow: "auto", height: "100%" }}
      fileType={fileType}
      filePath={url}
    />
  );
};

export default React.memo(PdfViewer);
