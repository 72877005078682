import React from "react";
import ChatWithMultipleTypeFiles from "../../../Components/ChatWith/ChatWithMultipleTypeFiles";

export default function ChatWithErp() {
  return (
    <ChatWithMultipleTypeFiles
      appName="ResourceRover"
      dis="AI-Driven ERP Navigator"
    />
  );
}
