import React, { useEffect, useState } from "react";
// import deleteIcon from "../../../Assets/ChatSectionImages/deleteIcon.svg";
// import greenTick from "../../Assets/SafeDigging/green.svg";
// import gray_mark from "../../Assets/SafeDigging/cross.svg";

const SaleTable = () => {
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const apiUrl = "https://api.carbon.ai/user_files_v2";
      const requestBody = {
        pagination: {
          limit: 123,
        },
        order_by: "created_at",
        order_dir: "desc",
        filters: {
          source: "SALESFORCE",
        },
        include_raw_file: "True",
        include_parsed_text_file: "True",
        include_additional_files: "True",
      };

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            Authorization:
              "Bearer d902e457817b81dd74a2f905606a78eee6aef1666fe478bef18287efa674b426",
            "customer-id": "2",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error("Error fetching data");
        }

        const data = await response.json();
        setResponseData(data.results);
      } catch (error) {
        console.error("Error:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <section
      className="container mt-4 "
      style={{ backgroundColor: "#f9fafb", flexDirection: "column" }}
    >
      <table
        className="table  custom-table"
        style={{ backgroundColor: "#FFF" }}
      >
        <thead className="custom-table-header ">
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th>Date</th>
            <th>Download</th>
            {/* <th>Delete</th> */}
          </tr>
        </thead>
        <tbody className="custom-table-body">
          <tr class="gap-row-header"></tr>
          {loading ? (
            <>
              {[...Array(5)].map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {[...Array(4)].map((_, colIndex) => (
                    <td key={colIndex} className="placeholder-glow">
                      <div className="placeholder col-12 my-2">..</div>
                    </td>
                  ))}
                </tr>
              ))}
            </>
          ) : (
            <>
              {responseData.map((result, index) => (
                <>
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td style={{ textAlign: "start" }}>{result.name}</td>
                    <td>{result.created_at.substring(0, 10)}</td>

                    <td>
                      <a href={result.presigned_url}>
                        <img
                          style={{ height: "25px" }}
                          src="https://imgs.search.brave.com/Q_FrOTcfEcQnkrkdPHwDghQ8RjcQ_Wiq9N-AS1fsM0c/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9jZG4t/aWNvbnMtcG5nLmZy/ZWVwaWsuY29tLzI1/Ni8yOTg5LzI5ODk5/NzYucG5n"
                          alt="download"
                        />
                      </a>
                    </td>
                    {/* <img src={deleteIcon} alt="delete" /> */}
                  </tr>
                </>
              ))}
            </>
          )}
        </tbody>
      </table>
    </section>
  );
};

export default SaleTable;
