// common chatsection for most of  tools
// here only json response will map

// created chatPlots different for future use , as we can add download btn for downloading plots images

// this is for xlsx and csv

import React, { useState, useEffect, useRef } from "react";
import "./ChatSection.css";
import SendIconLight from "../../Assets/ChatSectionImages/Send.svg";
import SendIconDark from "../../Assets/ChatSectionImages/dark-send.svg";
import FileSaver from "file-saver";
import { useTheme } from "../../Features/DarkMode/ThemeContext";
import _http from "./../../Utils/Api/_http";
import boxsizeIcon from "../../Assets/ChatSectionImages/boxsizeIcon.svg";
import bookmarkIcon from "../../Assets/ChatSectionImages/bookmarkIcon.svg";
import resetIcon from "../../Assets/ChatSectionImages/resetIcon.svg";
import deleteIcon from "../../Assets/ChatSectionImages/deleteIcon.svg";
import shareIcon from "../../Assets/ChatSectionImages/shareIcon.svg";
import exportIcon from "../../Assets/ChatSectionImages/exportIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import { selectIsSidebarOn, toggleSidebar } from "../../Redux/ReduceSidebar";
import info from "../../Assets/ChatSectionImages/info.png";
import { Tooltip } from "react-tooltip";
import SnackBar from "../../Features/SnackBar";
import CustomUserInput from "./CustomUserInput";

const ChatSection = ({
  pdfData,
  chatHistories,
  updateChat,
  uniqueIdentifier,
  updateChatHistories,
  isLoadingAns,
  pageInfoData,
  tpromptTokens,
  completionTokens,
  totalTokens,
}) => {
  const chatHistory =
    pdfData.id in chatHistories ? chatHistories[pdfData.id] : [];
  const [messages, setMessages] = useState(pdfData.chat);
  const [userInput, setUserInput] = useState("");
  const messagesEndRef = useRef(null);
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  var name = sessionStorage.getItem("Name");
  const [MainWriteContainer, setMainWriteContainer] = useState(true);
  const isSideBar = useSelector(selectIsSidebarOn);
  const [errors, setError] = useState("");
  const [isBookmmark, setIsBookmark] = useState(false);
  const [isShareChat, setIsSharechat] = useState(false);
  const [open, setOpen] = useState({
    Bookmark: false,
    Copy: false,
    error: false,
    description: false,
  });
  const textareaRef = useRef(null);
  const [ComposeBox, setComposeBox] = useState(true);

  const dispatch = useDispatch();

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };

  isLoadingAns && scrollToBottom();

  useEffect(() => {
    const updateChatMessagesHeight = () => {
      if (textareaRef.current) {
        const textareaHeight = textareaRef.current.offsetHeight;
        const chatMessagesHeight = `calc(100% - ${textareaHeight}px - 80px)`;

        document.querySelector(".chat-messages").style.height =
          chatMessagesHeight;
      }
    };

    updateChatMessagesHeight();

    window.addEventListener("resize", updateChatMessagesHeight);

    return () => {
      window.removeEventListener("resize", updateChatMessagesHeight);
    };
  }, [userInput]);

  useEffect(() => {
    if (chatHistories && chatHistories.pdfId === uniqueIdentifier) {
      setMessages((prevList) => [...prevList, chatHistories]);
    }
    scrollToBottom();
  }, [chatHistories, uniqueIdentifier]);

  const handleComposeBox = () => {
    setComposeBox(!ComposeBox);
  };

  const handleResetChat = () => {
    const botMessage = {
      author: "bot",
      type: "text",
      data: { text: "Welcome to our website! How can I assist you today?" },
    };

    updateChatHistories({
      ...chatHistories,
      [pdfData.id]: [botMessage],
    });

    setMessages([botMessage]);
  };

  const handleDeleteChat = () => {
    setMessages([]);
    if (pdfData.id in chatHistories) {
      chatHistories[pdfData.id] = [];
    }
  };

  const handleUserMessage = () => {
    if (userInput.trim() === "") return;

    const newMessage = {
      author: "user",
      type: "text",
      data: { text: userInput },
    };

    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);
    setUserInput("");
    updateChat(userInput);
    textareaRef.current.rows = 1;

    scrollToBottom();
  };

  const handleTextareaChange = (event) => {
    setUserInput(event.target.value);

    const textarea = event.target;
    const rows = Math.min(Math.max(1, textarea.value.split("\n").length), 5);
    textarea.rows = rows;
  };
  const handleExportChat = () => {
    const chatContent = chatHistory.map((message) => `${message.data.text}\n`);
    const blob = new Blob([chatContent], {
      type: "text/plain;charset=utf-8",
    });
    FileSaver.saveAs(blob, "chat.txt");

    const fileUrl = URL.createObjectURL(blob);
    setUrl(fileUrl);
  };

  const handleShareChat = () => {
    const chatContent = chatHistory.map((message) => `${message.data.text}\n`);
    const blob = new Blob([chatContent], {
      type: "text/plain;charset=utf-8",
    });

    const fileUrl = URL.createObjectURL(blob);
    setUrl(fileUrl);
  };

  const handleBookmarkChat = async () => {
    if (description.trim() === "") {
      setOpen({ description: true });
    } else {
      const chatContent = chatHistory.map(
        (message) => `${message.data.text}\n`
      );
      const chatBlob = new Blob([chatContent], {
        type: "text/plain;charset=utf-8",
      });
      const chatFile = new File([chatBlob], "chat.txt");

      try {
        const formData = new FormData();
        formData.append("id", Math.random().toString(36).substring(2));
        formData.append("name", name);
        formData.append("description", description);
        formData.append("app_name", pageInfoData);
        formData.append("pdffile", pdfData.file);
        formData.append("chatfile", chatFile);
        await _http.post("/api/postbookmark", formData);

        setOpen({ Bookmark: true });
        setIsBookmark(false);
      } catch (error) {
        setOpen({ error: true });
        if (error.response && error.response?.status === 500) {
          setError("Internal Server Error");
        } else {
          setError(error.message);
        }
        setIsBookmark(false);
      }
    }
  };

  const descriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const toggleChatBar = () => {
    setMainWriteContainer(!MainWriteContainer);
    isSideBar && dispatch(toggleSidebar());
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setOpen({ Copy: true });
      setIsSharechat(false);
    } catch (error) {
      setOpen({ error: true });
      console.error("Error copying to clipboard:", error);
      setIsSharechat(false);
    }
  };
  const handleClose = (state) => {
    setOpen({ ...state, open: false });
  };
  const { theme } = useTheme();

  return (
    <div className={`chat-section  ${theme === "dark" ? "dark-header" : ""}`}>
      <div
        className={`heading-containerr2  ${
          theme === "dark" ? "dark-theme" : "light-background"
        }`}
      >
        <img
          src={boxsizeIcon}
          alt="icon"
          className={MainWriteContainer ? "open-compose" : "close-compose"}
          onClick={toggleChatBar}
          data-tooltip-id="tooltip-chatsection"
          data-tooltip-content={MainWriteContainer ? "Open Chat" : "Close Chat"}
        ></img>
      </div>

      <div className="chat-sec-box">
        <div style={{ Height: "inherit", minWidth: "50%", flexGrow: "1" }}>
          <div
            className={`chat-header ${
              theme === "dark" ? "dark-header" : "light-header"
            } ${theme === "dark" ? "dark-border" : ""} `}
          >
            {" "}
            <div
              className={`page-info-heading ${
                theme === "dark" ? "dark-text" : "light-text"
              } `}
              style={{ margin: "0px" }}
            >
              <button
                className={`black-hover-issue ${
                  theme === "dark" ? "dark-text" : "light-text"
                } boxSixe`}
              ></button>
              <p className="chat-box-heading">Chat Box</p>
            </div>
            <div className={`chat-actions`}>
              <button
                className={`${
                  theme === "dark" ? "btn-bookmark-dark" : "btn-bookmark-light"
                } black - hover - issue ${
                  theme === "dark" ? "dark-text" : "light-text"
                } `}
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={() => setIsBookmark(true)}
              >
                {/* Bookmark */}
                <img
                  src={bookmarkIcon}
                  alt="bookmark"
                  data-tooltip-id="tooltip-chatsection"
                  data-tooltip-content="Bookmark"
                ></img>
              </button>

              <div
                className="bookmark-model"
                style={{ display: !isBookmmark ? "none" : "" }}
              >
                <div
                  className="modal"
                  id="exampleModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Add Description !!!
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <textarea
                          className="modal-textarea"
                          rows={3}
                          cols={3}
                          placeholder="Here ..."
                          onChange={descriptionChange}
                        ></textarea>
                      </div>
                      <div className="modal-footer">
                        <button
                          onClick={handleBookmarkChat}
                          className="btn btn-danger"
                        >
                          Add
                        </button>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!isBookmmark && open.Bookmark && (
                <SnackBar
                  message={"Added to My AI Projects"}
                  severity={"success"}
                  handleClose={handleClose}
                  Open={open.Bookmark}
                />
              )}
              {!isBookmmark && open.error && (
                <SnackBar
                  message={errors}
                  severity={"error"}
                  handleClose={handleClose}
                  Open={open.error}
                />
              )}
              {!isShareChat && open.description && (
                <SnackBar
                  message={"Please add Description"}
                  severity={"warning"}
                  handleClose={handleClose}
                  Open={open.description}
                />
              )}
              <button
                className={`black-hover-issue ${
                  theme === "dark" ? "dark-text" : "light-text"
                } `}
                onClick={handleResetChat}
              >
                {/* Reset */}
                <img
                  src={resetIcon}
                  alt="reset"
                  data-tooltip-id="tooltip-chatsection"
                  data-tooltip-content="Reset Chat"
                ></img>
              </button>
              <button
                className={`black-hover-issue ${
                  theme === "dark" ? "dark-text" : "light-text"
                } `}
                onClick={handleDeleteChat}
              >
                {/* Delete */}
                <img
                  src={deleteIcon}
                  alt="delete"
                  data-tooltip-id="tooltip-chatsection"
                  data-tooltip-content="Delete"
                ></img>
              </button>
              <button
                className={`black-hover-issue ${
                  theme === "dark" ? "dark-text" : "light-text"
                } `}
                onClick={handleExportChat}
              >
                {/* Export */}
                <img
                  src={exportIcon}
                  alt="export"
                  data-tooltip-id="tooltip-chatsection"
                  data-tooltip-content="Export Chat"
                ></img>
              </button>
              <div
                className={`dropdown black-hover-issue ${
                  theme === "dark" ? "dark-text" : "light-text"
                } `}
                onClick={() => setIsSharechat(true)}
              >
                <button
                  type="button"
                  className={`black-hover-issue ${
                    theme === "dark" ? "dark-text" : "light-text"
                  } `}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal3"
                  onClick={handleShareChat}
                >
                  {/* <button
                  className={`black-hover-issue ${theme === "dark" ? "dark-text" : "light-text"
                    } `}
                > */}
                  {/* Share */}
                  <img
                    src={shareIcon}
                    alt="share"
                    data-tooltip-id="tooltip-chatsection"
                    data-tooltip-content="Share Chat"
                  ></img>
                </button>
                <div style={{ display: !isShareChat ? "none" : "" }}>
                  <div
                    className="modal fade"
                    id="exampleModal3"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h3
                            className="modal-title fs-5"
                            id="exampleModalLabel"
                          >
                            Copy Url
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">{url}</div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={handleCopy}
                          >
                            Copy
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!isShareChat && open.error && (
            <SnackBar
              message={"Error in copying"}
              severity={"error"}
              handleClose={handleClose}
              Open={open.copyerror}
            />
          )}
          {!isShareChat && open.Copy && (
            <SnackBar
              message={"Url link copied!"}
              severity={"success"}
              handleClose={handleClose}
              Open={open.Copy}
            />
          )}
          <div
            className={`chat-messages  ${
              theme === "dark" ? "dark-background" : ""
            }`}
            style={{ height: "calc(100% - 55px)" }}
          >
            <span className="pdf-name">{pdfData.name}</span>
            {chatHistory.map((message, index) => (
              <div
                key={index}
                className={`chat-message ${
                  message.author === "user"
                    ? `${
                        theme === "dark"
                          ? "user-message-dark"
                          : "user-message-light"
                      }`
                    : "bot-message"
                }  `}
                style={{
                  marginBottom: message.author === "user" ? "" : "10px",
                }}
              >
                <div className="msg-content" style={{ width: "100%" }}>
                  <span className="token-button dropdown">
                    <span
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img style={{ height: "20px" }} src={info} alt="" />
                    </span>

                    <div
                      style={{ minWidth: "14rem", padding: "5px" }}
                      className="dropdown-menu dropdown-menu-right logout-dropdown  "
                      aria-labelledby="navbarDropdown"
                    >
                      <p>Prompt Tokens: {tpromptTokens}</p>
                      <p>Completion Tokens: {completionTokens}</p>
                      <p>Total Tokens: {totalTokens}</p>
                    </div>
                  </span>
                  {message.data.text.trim()}
                </div>
              </div>
            ))}
            <div ref={messagesEndRef}>
              {isLoadingAns && (
                <div style={{ margin: "20px" }} className="loader-gpt"></div>
              )}
            </div>
          </div>

          <div className={`user-input   `}>
            <textarea
              ref={textareaRef}
              className={` ${
                theme === "dark" ? "dark-background" : "light-background"
              } 
              ${theme === "dark" ? "dark-text" : "light-text"}`}
              type="text"
              placeholder="Type your questions...  "
              value={userInput}
              onChange={handleTextareaChange}
              onKeyPress={(event) => {
                if (event.key === "Enter" && !isLoadingAns && !event.shiftKey) {
                  event.preventDefault();
                  handleUserMessage();
                }
              }}
              rows={1}
              maxrows={5}
            />

            <button
              disabled={isLoadingAns}
              className="send-button"
              onClick={handleUserMessage}
            >
              <img
                src={theme === "dark" ? SendIconDark : SendIconLight}
                alt="icon"
              />
            </button>
          </div>
        </div>
        <div
          className={
            MainWriteContainer || isSideBar
              ? "hide-main-write-container"
              : ComposeBox
              ? "main-write-container"
              : "main-write-container1"
          }
        >
          <div
            className={`write-container ${
              theme === "dark" ? "dark-theme" : "light-background"
            } `}
          >
            <CustomUserInput handleComposeBox={handleComposeBox} />
          </div>
        </div>
      </div>
      <Tooltip id="tooltip-chatsection" place="top" style={{ zIndex: "1" }} />
    </div>
  );
};

export default ChatSection;
