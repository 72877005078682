import React, { useState } from "react";
import Loading from "../../Features/Loadings/Loading";
import "./Interact-Tools.css";
import { useTheme } from "../../Features/DarkMode/ThemeContext";
import UnderStandButtonPdf from "../../Features/UnderStandButtonPdf";
import _http from "../../Utils/Api/_http";
import InteractToolsLlogs from "../User-Logs/Interact-Tools-logs/InteractToolsLlogs";
import SnackBar from "../../Features/SnackBar";

export default function HomepageDescription() {
  const [Company_Desc, setCompanyDes] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [company_usp, setcompany_usp] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [discription, setDiscription] = useState(true);
  const [answer, setAnswer] = useState("");
  const { theme } = useTheme();

  const [open, setOpen] = useState({
    Submit: false,
    Copy: false,
    error: false,
  });
  const [errors, setError] = useState("");
  const handleSubmit = async () => {
    if (
      Company_Desc === "" ||
      CompanyName === "" ||
      selectedValue === "" ||
      company_usp === ""
    ) {
      setOpen({ Submit: true });

      return;
    } else {
      setAnswer("");
      setisLoading(true);
      setDiscription(false);
      try {
        const response = await _http.post(
          "/api/homepage_description",

          {
            company_desc: Company_Desc,
            company_name: CompanyName,
            company_usp: company_usp,
            tone: selectedValue,
          }
        );
        setAnswer(response.data.ai_answer);
        setisLoading(false);
      } catch (error) {
        setisLoading(false);
        setOpen({ error: true });
        if (error.response && error.response?.status === 500) {
          setError("Internal server error");
        } else {
          setError(error.message || "An error occured");
        }
      }
    }
  };

  const handleCopy = () => {
    const contentCopy = answer;

    if (contentCopy) {
      const textArea = document.createElement("textarea");
      textArea.value = contentCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      setOpen({ Copy: true });
    }
  };
  const handleClose = (state) => {
    setOpen({ ...state, Submit: false, Copy: false, error: false });
  };
  const logUserAction = () => {
    return (
      <InteractToolsLlogs
        application="Homepage Description "
        filename="---"
        filesize="---"
      />
    );
  };

  return (
    <section>
      {logUserAction()}
      <div
        className={` ${theme === "dark" ? "dark-theme " : "light-background"
          } chatUItwo  `}
      >
        {" "}
        <div className="input-container">
          <div className="">
            <>
              <div className="page-info-heading mb-3">
                <div className="headingg">HomeHighlight</div>
                <div className="description">
                  <UnderStandButtonPdf
                    modalContent={
                      <>
                        <p>
                          1) Enter the name of the company.
                          <br></br>
                          2) Enter the description of the company
                          <br></br>
                          3) Enter the company's USP value.
                          <br></br>
                          4) Select the desired tone to generate a description
                          like - formal, informal, and many more.
                          <br></br>
                          5) Click the submit button to see the generated home
                          page description in the output window on the right.
                          <br></br>
                          6) Click the hamburger icon to know the application of
                          the tool.
                          <br></br>
                          7) Copy the content by clicking the 'Copy' button.
                          <br></br>
                        </p>
                      </>
                    }
                  />
                </div>
              </div>
              <div className="border-container">
                <div className="form-outline mb-4">
                  <input
                    type="text"
                    id="form4Example1"
                    className="form-control"
                    value={CompanyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                    placeholder="Company Name"
                    required
                  />
                </div>
                <div className="form-outline mb-4">
                  <textarea
                    rows={1}
                    type="text"
                    id="form4Example1"
                    className="form-control"
                    placeholder="Company Description"
                    value={Company_Desc}
                    onChange={(e) => {
                      setCompanyDes(e.target.value);
                    }}
                    required
                  />
                </div>

                <div className="form-outline mb-4">
                  <textarea
                    rows={1}
                    type="text"
                    id="form4Example1"
                    className="form-control"
                    value={company_usp}
                    onChange={(e) => {
                      setcompany_usp(e.target.value);
                    }}
                    placeholder="Company USP"
                    required
                  />
                </div>

                <div className="form-outline mb-4">
                  <select
                    name="mySelect"
                    id="mySelect"
                    value={selectedValue}
                    onChange={(e) => setSelectedValue(e.target.value)}
                    className="selectpicker form-control form-select"
                    required
                  >
                    <option disabled value="">
                      Tone
                    </option>
                    <option value="Formal">Formal</option>
                    <option value="Informal">Informal</option>
                    <option value="Optimistic">Optimistic</option>
                    <option value="Worried">Worried</option>
                    <option value="Friendly">Friendly</option>
                    <option value="Curious">Curious</option>
                    <option value="Assertive">Assertive</option>
                    <option value="Encouraging">Encouraging</option>
                    <option value="Surprised">Surprised</option>
                    <option value="Cooperative">Cooperative</option>
                  </select>
                </div>
              </div>

              <div>
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="btn btn-danger btn-block mb-4"
                  style={{ marginTop: "10px" }}
                >
                  Submit
                </button>
                {
                  <SnackBar
                    message="Please enter values"
                    severity="warning"
                    Open={open.Submit}
                    handleClose={handleClose}
                  />
                }
                {
                  <SnackBar
                    message={errors}
                    severity={"error"}
                    handleClose={handleClose}
                    Open={open.error}
                  />
                }
              </div>
            </>
          </div>
        </div>
        <div className={` output-container`} id="content">
          <div
            className={` output ${theme === "dark" ? "dark-header" : "light-header"
              } `}
          >
            {isLoading ? (
              <div className="load-center">
                <Loading />
              </div>
            ) : (
              ""
            )}
            {discription ? (
              <div>
                <h5>Engaging Description Generator</h5>
                Captivate from the first click. Design and implement compelling
                content that ensures visitors are engaged the moment they land
                on your website.
              </div>
            ) : null}
            {answer}
          </div>
          <div style={{ marginTop: "10px" }}>
            <button
              disabled={answer === ""}
              onClick={handleCopy}
              type="submit"
              className="btn btn-danger btn-block mb-4"
            >
              Copy
            </button>
            {
              <SnackBar
                message="Text Copied!"
                severity={"success"}
                Open={open.Copy}
                handleClose={handleClose}
              />
            }
          </div>
        </div>
      </div>
    </section>
  );
}
