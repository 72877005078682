import React, { useState } from "react";
import "./Interact-Tools.css";
import Loading from "../../Features/Loadings/Loading";
import { useTheme } from "../../Features/DarkMode/ThemeContext";
import UnderStandButtonPdf from "../../Features/UnderStandButtonPdf";
import _http from "./../../Utils/Api/_http";
import InteractToolsLlogs from "./../User-Logs/Interact-Tools-logs/InteractToolsLlogs";
import SnackBar from "../../Features/SnackBar";

export default function BioWriter() {
  const [isLoading, setisLoading] = useState(false);
  const [discription, setDiscription] = useState(true);
  const [bioData, setBioData] = useState({
    About: "",
    keywords: "",
    perspective: "",
    selectedValue: "",
    answer: "",
  });

  const { theme } = useTheme();
  const [open, setOpen] = useState({
    Submit: false,
    Copy: false,
    error: false,
  });
  const [errors, setError] = useState("");

  const handleSubmit = async () => {
    if (
      bioData.About === "" ||
      bioData.keywords === "" ||
      bioData.selectedValue === "" ||
      bioData.perspective === ""
    ) {
      setOpen({ Submit: true });
      return;
    } else {
      setBioData({ answer: "" });
      setDiscription(false);
      setisLoading(true);

      try {
        const response = await _http.post(
          "/api/bio_result",

          {
            about: bioData.About,
            keywords: bioData.keywords,
            tone: bioData.selectedValue,
            perspective: bioData.perspective,
          }
        );
        setBioData({ ...bioData, answer: response.data.ai_answer });
        setisLoading(false);
      } catch (error) {
        setisLoading(false);
        setOpen({ error: true });
        if (error.response && error.response?.status === 500) {
          setError("Internal server error");
        } else {
          setError(error.message || "An error occured");
        }
      }
    }
  };

  // for copying response

  const handleCopy = () => {
    const contentCopy = bioData.answer;

    if (contentCopy) {
      const textArea = document.createElement("textarea");
      textArea.value = contentCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      setOpen({ Copy: true });
    }
  };
  const handleClose = (state) => {
    setOpen({ ...state, Submit: false, Copy: false, error: false });
  };

  // for logs

  const logUserAction = () => {
    return (
      <InteractToolsLlogs
        application="Bio Writer"
        filename="---"
        filesize="---"
      />
    );
  };

  return (
    <section>
      {logUserAction()}

      <div
        className={` ${theme === "dark" ? "dark-theme " : "light-background"
          } chatUItwo`}
      >
        <div className="input-container">
          <div className="">
            <>
              <div className="page-info-heading mb-3">
                <div className="headingg">Bio writer</div>
                <div className="description">
                  <UnderStandButtonPdf
                    modalContent={
                      <>
                        <p>
                          1) Enter or paste your story in the first input field.
                          <br></br>
                          2) Enter or paste the highlighting words in the
                          Keywords input field.
                          <br></br>
                          3) Select the desired tone to generate a story like
                          formal, informal, and many more.
                          <br></br>
                          4) Select the appropriate perspective.
                          <br></br>
                          5) Click the submit button to see the generated
                          biography in the output window on the right.
                          <br></br>
                          6) Click the hamburger icon to know the application of
                          the tool.
                          <br></br>
                          7) Copy the content by clicking the 'Copy' button.
                          <br></br>
                        </p>
                      </>
                    }
                  />
                </div>
              </div>
              <div className="border-container">
                <div className="form-outline mb-4">
                  <input
                    type="text"
                    placeholder="About"
                    className="form-control"
                    value={bioData.About}
                    onChange={(e) =>
                      setBioData({ ...bioData, About: e.target.value })
                    }
                  />
                </div>

                <div className="form-outline mb-4">
                  <input
                    className="form-control"
                    type="text"
                    value={bioData.keywords}
                    onChange={(e) =>
                      setBioData({ ...bioData, keywords: e.target.value })
                    }
                    placeholder="Keywords"
                  />
                </div>
                <div className="form-outline mb-4">
                  <select
                    name="mySelect"
                    className="form-control form-select"
                    value={bioData.selectedValue}
                    onChange={(e) =>
                      setBioData({
                        ...bioData,
                        selectedValue: e.target.value,
                      })
                    }
                  >
                    <option disabled value="">
                      Tone
                    </option>
                    <option value="Formal">Formal</option>
                    <option value="Informal">Informal</option>
                    <option value="Conversational">Conversational</option>
                    <option value="Humorous">Humorous</option>
                  </select>
                </div>

                <div className="form-outline  mb-4">
                  <div>
                    <select
                      name="perspectiveSelect"
                      id="perspectiveSelect"
                      className="form-control form-select"
                      value={bioData.perspective}
                      onChange={(e) =>
                        setBioData({
                          ...bioData,
                          perspective: e.target.value,
                        })
                      }
                    >
                      <option disabled value="">
                        Select Perspective
                      </option>
                      <option value="First Person">First Person</option>
                      <option value="Third Person">Third Person</option>
                    </select>
                  </div>
                </div>
              </div>

              <div>
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="btn btn-danger btn-block mb-4 my-3"
                >
                  Submit
                </button>
                {
                  <SnackBar
                    message="Please enter values"
                    severity="warning"
                    Open={open.Submit}
                    handleClose={handleClose}
                  />
                }
                {
                  <SnackBar
                    message={errors}
                    severity={"error"}
                    handleClose={handleClose}
                    Open={open.error}
                  />
                }
              </div>
            </>
          </div>
        </div>
        <div className={` output-container`} id="content">
          <div
            className={` output ${theme === "dark" ? "dark-header" : "light-header"
              } `}
          >
            {" "}
            {isLoading ? (
              <div className="load-center">
                <Loading />
              </div>
            ) : (
              ""
            )}
            {discription ? (
              <div>
                <h5>Dynamic BioData Composer</h5>
                Tell your story succinctly. Share key details and generate
                captivating short biographies tailored for social profiles,
                websites, and more.
              </div>
            ) : null}
            {bioData.answer}
          </div>
          <div style={{ marginTop: "10px" }}>
            <button
              disabled={bioData.answer === ""}
              onClick={handleCopy}
              type="submit"
              className="btn btn-danger btn-block mb-4"
            >
              Copy
            </button>
            {
              <SnackBar
                message="Text Copied!"
                severity={"success"}
                Open={open.Copy}
                handleClose={handleClose}
              />
            }
          </div>
        </div>
      </div>
    </section>
  );
}
