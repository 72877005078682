import React from "react";
import "./MainHome.css";
import MainHome from "./MainHome";
import HomeSlideDataOne from "./HomeData/HomeSlideDataOne";
import HomeSlideDataTwo from "./HomeData/HomeSlideDataTwo";
import HomeDataThree from "./HomeData/UserData/HomeDataThree";
import { useTheme } from "../../Features/DarkMode/ThemeContext";

export default function Home() {
  const { theme } = useTheme();
  return (
    <section
      className={`content  ${
        theme === "dark" ? "dark-theme " : "light-background"
      }`}
    >
      <MainHome />
      <HomeSlideDataOne />
      <HomeSlideDataTwo />
      <HomeDataThree />
    </section>
  );
}
