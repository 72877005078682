import React, { useEffect, useState } from "react";
import "./settings.css";
import { Link } from "react-router-dom";
import { useTheme } from "../../../Features/DarkMode/ThemeContext";
import _http from "./../../../Utils/Api/_http";
import ThemeSwitcher from "../../../Features/DarkMode/ThemeSwitcher";
import { useDispatch, useSelector } from "react-redux";
import { toggleImage, selectIsImageOn } from "../../../Redux/ReduceSidebar";
import SnackBar from "../../../Features/SnackBar";
export default function Settings() {
  const { theme } = useTheme();
  const [userImage, setUserImage] = useState(null);
  const isImageOn = useSelector(selectIsImageOn);
  const [open, setOpen] = useState({ Submit: false, error: false });
  const [errors, setErrors] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const name = sessionStorage.getItem("Name");

    const fetchImageFromAPI = () => {
      _http
        .post(
          "/api/view_img",
          { username: name },
          { responseType: "arraybuffer" }
        )
        .then((response) => {
          if (response.data) {
            const arrayBuffer = response.data;
            const blob = new Blob([arrayBuffer], { type: "image/png" });
            const imageUrl = URL.createObjectURL(blob);
            setUserImage(imageUrl);
          }
        })
        .catch((error) => {
          console.error("Error fetching user image: ", error);
          setOpen(true);
          setErrors("Error fetching user image");
        });
    };
    if (localStorage.getItem("userImage")) {
      setUserImage(localStorage.getItem("userImage"));
    } else {
      fetchImageFromAPI();
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    !isImageOn && dispatch(toggleImage());
    console.log(isImageOn);

    const name = sessionStorage.getItem("Name");
    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", file);

    try {
      const response = await _http.post("/api/profile_pic", formData);

      if (response.data.bool) {
        setUserImage(URL.createObjectURL(file));
        sessionStorage.setItem("UserImage", URL.createObjectURL(file));
        dispatch(toggleImage());
        setOpen({ Submit: true });
        setErrors("Image uploaded successfully");
      } else {
        setOpen({ error: true });
        setErrors("Image upload failed");
      }
    } catch (error) {
      setOpen({ error: true });
      setErrors("Error updating user image");
      console.error("Error updating user image: ", error);
    }
  };

  // const removeImage = () => {
  //     const name = sessionStorage.getItem('Name');

  //     _http.get(`/api/delete_img/${name}`)
  //         .then(() => {
  //             fetchImageFromAPI();
  //         })
  //         .catch((error) => {
  //             console.error('Error updating user image: ', error);
  //         });
  // }

  return (
    <section
      className={`${
        theme === "dark" ? "dark-theme " : "light-background"
      } settings `}
    >
      <div
        className={`${
          theme === "dark" ? "dark-header" : "light-header"
        } wrapper  mt-sm-5`}
      >
        <h4
          className={`${
            theme === "dark" ? "dark-text" : "light-text"
          } pb-4 border-bottom`}
        >
          Account settings <ThemeSwitcher />
        </h4>
        <div className="d-flex align-items-center py-3 border-bottom">
          <img src={userImage} className="img" alt="User" />
          <div
            className="py-3 mx-3 "
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label
              htmlFor="formId"
              onChange={handleImageChange}
              className="upload-label btn btn-danger"
              style={{ margin: "0.5rem" }}
            >
              <input
                className="upload-input"
                name=""
                type="file"
                id="formId"
                accept="image/*"
                // multiple
                hidden
              />
              <b> {"Change image"}</b>
            </label>
            {/* <button className='btn btn-danger mx-2 my-2' onClick={removeImage} >Remove Image</button> */}
            {
              <SnackBar
                message={errors}
                severity={"error"}
                handleClose={handleClose}
                Open={open.error}
              />
            }{" "}
            {
              <SnackBar
                message={errors}
                severity={"success"}
                handleClose={handleClose}
                Open={open.Submit}
              />
            }
          </div>
        </div>
        <div className="py-2">
          <div className="row py-2">
            <div className="col-md-6">
              <label htmlFor="firstname">
                {" "}
                <h6>Username</h6>{" "}
              </label>
              <p>{sessionStorage.getItem("Name")}</p>
            </div>
            <div className="col-md-6">
              <label htmlFor="email">
                {" "}
                <h6>Email Address</h6>{" "}
              </label>
              <p>{sessionStorage.getItem("Email")}</p>
            </div>
          </div>
          <div className="py-3 pb-4">
            <Link to="/EmailVerify">
              <button className="btn btn-danger mr-3">Reset Password</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
