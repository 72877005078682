import React from "react";
import { useTheme } from "./ThemeContext";
import darkMode from "./../../Assets/night-mode.png";
import LightMode from "./../../Assets/Light-mode.png";
import { Tooltip } from "react-tooltip";
const ThemeSwitcher = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <>
      <span
        className="mx-3"
        onClick={toggleTheme}
        data-tooltip-id="my-tooltip"
        data-tooltip-content={theme === "dark" ? "Dark Mode" : "Light Mode"}
      >
        <img
          style={{ height: "22px" }}
          src={theme === "dark" ? LightMode : darkMode}
          alt="darkMode"
        />
      </span>
      <Tooltip
        id="my-tooltip"
        place="bottom"
        style={{
          fontSize: "16px",
          padding: "7px",
          borderRadius: "3px",
          position: "absolute",
          top: 0,
          zIndex: 1,
        }}
        className="tooltip"
      />
    </>
  );
};

export default ThemeSwitcher;
