import React from "react";
import ChatWithFiles from "../../../Components/ChatWith/ChatWithFiles";

export default function ChatWithLog() {
  return (
    <ChatWithFiles
      appName="LogLingo"
      type="LOG"
      sliceNumber={4}
      fileType=".log"
      apiSummary="logsummary"
      apiQuestion="logquestion"
      modalContent={
        <>
          <p>
            1) Upload or Drag the LOG.
            <br></br>
            2) The uploaded LOG is displayed to the right.
            <br></br>
            3) A ChatBot with a LOG summary and sample questions are displayed
            to the right of the LOG.
            <br></br>
            4) Ask questions and get the answer from the ChatBot.
            <br></br>
            5) Other features like Bookmark, Reset, Delete, Share, and Export
            are also present.
            <br></br>
          </p>
        </>
      }
      accept=".log"
      maxsize={32}
      dis="AI Log Analysis Assistant"
    />
  );
}
