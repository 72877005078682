import React from "react";
import ChatPlots from "../../../Components/ChatWith/ChatPlots";

export default function DataPlotter() {
  return (
    <ChatPlots
      appName="Data Plotter"
      sliceNumber={4}
      maxsize={200}
      // apiSummary="excelsummary"
      // apiQuestion="excelquestion"
      apiSummary="csvsummary"
      apiQuestion="csvquestion"
      modalContent={
        <>
          <p>
            1) Upload or Drag the CSV or XLSX.
            <br></br>
            2) The uploaded CSV or XLSX is displayed to the right.
            <br></br>
            3) A ChatBot with a CSV or XLSX summary and sample questions are
            displayed to the right of the CSV or XLSX.
            <br></br>
            4) Ask questions and get the answer from the ChatBot.
            <br></br>
            5) Other features like Bookmark, Reset, Delete, Share, and Export
            are also present.
            <br></br>
          </p>
        </>
      }
      dis="How can i help you today?"

    />
  );
}
