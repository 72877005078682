import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BookmarkChatWith from "./BookmarkChatWith";
import _http from "../../Utils/Api/_http";

export default function SetBookmark() {
  const location = useLocation();
  const [index, setIndex] = useState("");
  const [appname, setAppname] = useState("");
  const [description, setDescription] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [displayFile, setDisplayFile] = useState(null);
  const [textFile, setTextFile] = useState(null);
  const [apiToBeUsed, setApiToBeUsed] = useState("");

  var name = sessionStorage.getItem("Name");

  const getFileUrl = (file, type) => {
    const fileBlob = new Blob([file], {
      type: type,
    });
    const obtainedFileUrl = URL.createObjectURL(fileBlob);
    return obtainedFileUrl;
  };

  useEffect(() => {
    const fetchData = async () => {
      const indexFromLocation = location.state?.index || "0";
      setIndex(indexFromLocation);
      const appnameFromLocation = location.state?.appname || "";
      setAppname(appnameFromLocation);
      const descriptionFromLocation = location.state?.description || "";
      setDescription(descriptionFromLocation);
      const fileNameFromLocation = location.state?.pdf || "";
      setFileName(fileNameFromLocation);
      setFileType(fileNameFromLocation.split(".").pop().toLowerCase());

      try {
        const displayFileResponse = await _http.post(
          "/api/getpdfbookmark",
          { serial_no: indexFromLocation, name: name },

          { responseType: "blob" }
        );

        const textFileResponse = await _http.post(
          "/api/gettextbookmark",
          { serial_no: indexFromLocation, name: name },
          { responseType: "blob" }
        );

        setDisplayFile(displayFileResponse.data);
        setTextFile(textFileResponse.data);

        switch (appnameFromLocation) {
          case "PDF Converse":
            setFileUrl(getFileUrl(displayFileResponse.data, "application/pdf"));
            setApiToBeUsed("/api/chat_pdf_userquestion");
            setFileType("pdf");
            break;
          case "EduBuddy":
            setFileUrl(getFileUrl(displayFileResponse.data, "application/pdf"));
            setApiToBeUsed("/api/StudentQuestion");
            setFileType("pdf");
            break;
          case "Resume Parser":
            setFileUrl(getFileUrl(displayFileResponse.data, "application/pdf"));
            setApiToBeUsed("/api/resumequestion");
            setFileType("pdf");
            break;
          case "TextTalk":
            setFileUrl(getFileUrl(displayFileResponse.data, "text/plain"));
            setApiToBeUsed("/api/textquestion");
            setFileType("txt");
            break;
          case "DataDialogue CSV":
            setFileUrl(getFileUrl(displayFileResponse.data, "text/csv"));
            setApiToBeUsed("/api/csvquestion");
            setFileType("csv");
            break;
          case "WordWhisper":
            setFileUrl(
              getFileUrl(
                displayFileResponse.data,
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              )
            );
            setApiToBeUsed("/api/docquestion");
            setFileType("docx");
            break;
          case "SlideSage":
            setFileUrl(
              getFileUrl(
                displayFileResponse.data,
                "application/vnd.openxmlformats-officedocument.presentationml.presentation"
              )
            );
            setApiToBeUsed("/api/pptquestion");
            setFileType("pptx");
            break;
          case "DataDialogue Excel":
            setFileUrl(
              getFileUrl(
                displayFileResponse.data,
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              )
            );
            setApiToBeUsed("/api/excelquestion");
            setFileType("xlsx");
            break;
          case "JSON Jargon":
            setFileUrl(
              getFileUrl(displayFileResponse.data, "application/json")
            );
            setApiToBeUsed("/api/jsonquestion");
            setFileType("json");
            break;
          case "XML Exchange":
            setFileUrl(getFileUrl(displayFileResponse.data, "application/xml"));
            setApiToBeUsed("/api/xmlquestion");
            setFileType("xml");
            break;
          case "Markdown Mentor":
            setFileUrl(getFileUrl(displayFileResponse.data, "text/markdown"));
            setApiToBeUsed("/api/mdquestion");
            setFileType("md");
            break;
          case "PyTalk":
            setFileUrl(getFileUrl(displayFileResponse.data, "text/plain"));
            setApiToBeUsed("/api/pythonquestion");
            setFileType("py");
            break;
          case "CodeChat JS":
            setFileUrl(getFileUrl(displayFileResponse.data, "text/javascript"));
            setApiToBeUsed("/api/jsquestion");
            setFileType("js");
            break;
          case "Chat TEX":
            const fileBlob = new Blob([displayFileResponse.data], {
              type: "application/x-tex",
            });
            setFileUrl(
              getFileUrl(displayFileResponse.data, "application/x-tex")
            );
            setApiToBeUsed("/api/texquestion");
            setFileType("tex");
            break;
          case "LogLingo":
            setFileUrl(getFileUrl(displayFileResponse.data, "text/plain"));
            setApiToBeUsed("/api/logquestion");
            setFileType("log");
            break;
          //Applications supporting multiple file types
          case "ManualDialogue":
          case "BOMBlueprint":
          case "CRMConverse":
          case "ResourceRover":
          case "PolicyPilot":
          case "WarrantyWords":
            //Setting file type from file name
            const fileExtension = fileNameFromLocation
              .split(".")
              .pop()
              .toLowerCase();
            switch (fileExtension) {
              case "pdf":
                setFileUrl(
                  getFileUrl(displayFileResponse.data, "application/pdf")
                );
                break;
              case "txt":
                setFileUrl(getFileUrl(displayFileResponse.data, "text/plain"));
                break;
              case "pptx":
                setFileUrl(
                  getFileUrl(
                    displayFileResponse.data,
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                  )
                );
                break;
              case "docx":
                setFileUrl(
                  getFileUrl(
                    displayFileResponse.data,
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  )
                );
                break;
              case "xlsx":
                setFileUrl(
                  getFileUrl(
                    displayFileResponse.data,
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  )
                );
                break;
              default:
                break;
            }

            break;
          default:
            break;
        }
      } catch (error) {
        console.log("Error fetching files:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {displayFile && textFile && (
        <BookmarkChatWith
          displayFile={displayFile}
          textFile={textFile}
          appname={appname}
          fileUrl={fileUrl}
          index={index}
          description={description}
          apiToBeUsed={apiToBeUsed}
          fileName={fileName}
          fileType={fileType}
        />
      )}
    </>
  );
}
