// this is created for user tools ,
// in  future through api we will get data for what user is using

import React from "react";
import { useTheme } from "../../../../Features/DarkMode/ThemeContext";
import homehamburger from "../../../../Assets/HeroImages/homehamburger.png";
import { useUserData } from "./../../../Login-Auth/UserDataProvider";
import emptyAIwork from "../../../../Assets/HomeImages/EmptyAIwork.svg";
import "./HomeDataThree.css";
import Card from "../Card/Card";
import ArrayData from "../ArrayData";

export default function HomeDataThree() {
  const { theme } = useTheme();
  const { responseData } = useUserData();

  const Data = ArrayData(theme, responseData);

  return (
    <>
      <div className="user-work-head">
        <div className="Main-home-heading">My AI Works</div>
        <img src={homehamburger} alt="img" />
      </div>
      <div className="main-card main-card-data">
        {Data.length === 0 ? (
          <div
            className=""
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <img src={emptyAIwork} alt="icon" />
          </div>
        ) : (
          Data.map((item, index) => (
            <Card key={index} item={item} index={index} />
          ))
        )}
      </div>
    </>
  );
}
