import React from "react";
import ChatWithMultipleTypeFiles from "../../../Components/ChatWith/ChatWithMultipleTypeFiles";

export default function ChatWithBom() {
  return (
    <ChatWithMultipleTypeFiles
      appName="BOMBlueprint"
      dis="Automated Bill of Materials Architect"
    />
  );
}
