// created this component for showing image on login page as per different company

import { useEffect, useState } from "react";
import stcaklogo from "../../Assets/stacklogo.png";
import stcklogin from "../../Assets/images/stackteck.png";
import lam from "../../Assets/lam.svg";
import lamlogin from "../../Assets/images/login-side.png";
import dtLogin from "./../../Assets/dtLogintwo.svg";

const CompanyLoginHandler = () => {
  const [companyLogin, setCompanyLogin] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);

  useEffect(() => {
    const companyData = [
      {
        companyName: "lamons.aisuite.dtskill.com",
        companyLogoName: lam,
        companyLogin: lamlogin,
      },
      {
        companyName: "stackteck.aisuite.dtskill.com",
        companyLogoName: stcaklogo,
        companyLogin: stcklogin,
      },
      {
        companyNames: [
          "localhost",
          "aisuite.dtskill.com",
          "qa.aisuite.dtskill.com",
        ],
        companyLogin: dtLogin,
      },
    ];

    const currentDomain = window.location.hostname;

    const selectedCompany = companyData.find((company) => {
      if (Array.isArray(company.companyNames)) {
        return company.companyNames.includes(currentDomain);
      } else {
        return company.companyName === currentDomain;
      }
    });

    if (selectedCompany) {
      setCompanyLogin(selectedCompany.companyLogin);
      setCompanyLogo(selectedCompany.companyLogoName);
    }
  }, []);

  return { companyLogin, companyLogo };
};

export default CompanyLoginHandler;
