// this code is same as chatWith Files ,
// here in 2nd api we were getting a zip file in response which contains of images
// so here we are unziping that file and showing images in chat area .

import React, { useRef, useEffect, useState } from "react";
import "./ChatLayout.css";
import NewLoder from "../../Features/Loadings/NewLoder";
import { useTheme } from "../../Features/DarkMode/ThemeContext";
import _http from "../../Utils/Api/_http";
import UnderStandButtonPdf from "../../Features/UnderStandButtonPdf";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import uploadIcon from "../../Assets/Vector.svg";
import closemainSidebar from "../../Assets/group1.svg";
import openmainSidebar from "../../Assets/group2.svg";
import openSidebar from "../../Assets/Group 1151.svg";
import { selectIsSidebarOn } from "../../Redux/ReduceSidebar";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleSidebar,
  selectIsToggleOn,
  selectIsMainSidebarOn,
  toggleMainSidebar,
} from "../../Redux/ReduceSidebar";
import { Tooltip } from "react-tooltip";
import SnackBar from "../../Features/SnackBar";
import ChatSectionPlots from "../ChatSection/ChatPlots";
import JSZip from "jszip";
import PdfViewer from "../../Pages/Interact-With-File/ChatWith/PdfViewer";
import tiles from "./../../Assets/tile.svg";

const ChatPlots = (props) => {
  const {
    appName,
    sliceNumber,
    apiSummary,
    apiQuestion,
    modalContent,
    maxsize,
    dis,
  } = props;
  const fileInputRef = useRef(null);
  const [pdfDataList, setPdfDataList] = useState([]);
  const [message, setMessage] = useState(null);
  const [selectedPdfId, setSelectedPdfId] = useState(null);
  const [pdfSize, setPdfSize] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAns, setIsLoadingAns] = useState(false);
  const [chatHistories, setChatHistories] = useState({});
  const [pdfUploadPer, setPdfUploadPer] = useState(0);
  const [pageInfoData, setPageInfoData] = useState("");
  const [minimizePdfView, setMinimizePdfView] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const isSideBar = useSelector(selectIsSidebarOn);
  const isToggleOn = useSelector(selectIsToggleOn);
  const isMainSidebarOn = useSelector(selectIsMainSidebarOn);
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const [CurrentFileType, setCurrentFileType] = useState("");
  const [fileName, setFileName] = useState("");
  const [errors, setError] = useState("");
  const [tokens, setTokens] = useState({
    prompt_tokens: "",
    completion_tokens: "",
    total_tokens: "",
  });
  const [open, setOpen] = useState({
    error: false,
    Allowedfile: false,
    selectedfile: false,
  });

  const handleClose = () => {
    setOpen({ error: false, Allowedfile: false, selectedfile: false });
  };

  const handelMnimizePdf = () => {
    setMinimizePdfView(!minimizePdfView);
  };

  useEffect(() => {
    setPageInfoData(appName);
  }, [appName]);

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    setPdfUploadPer(0);

    const files = event.target.files;

    if (!files || files.length === 0) {
      alert("Select one or more files.");
      return;
    }

    const maxSize = maxsize * 1024 * 1024;
    let allFilesValid = true;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file.name.slice(-sliceNumber).toLowerCase() === ".csv") {
        setCurrentFileType("csv");
      } else if (file.name.slice(-sliceNumber).toLowerCase() === ".xlsx") {
        setCurrentFileType("xlsx");
      } else {
        alert("Error while getting File Type");
      }
      if (
        file.name.slice(-sliceNumber).toLowerCase() !== ".csv" &&
        file.name.slice(-sliceNumber).toLowerCase() !== ".xlsx"
      ) {
        setFileName(file.name);
        setOpen({ Allowedfile: true });
        allFilesValid = false;
        break;
      }

      let fileIsPresent = pdfDataList.find((item) => item.name === file.name);
      if (fileIsPresent || fileIsPresent !== undefined) {
        setFileName(file.name);
        setOpen({ selectedfile: true });
        return;
      }

      if (file.size > maxSize) {
        setPdfSize(`File ${file.name} exceeds the size limit( ${maxSize}MB).`);
        allFilesValid = false;
        break;
      }
    }

    if (!allFilesValid) {
      return;
    }

    setIsLoading(true);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await _http.post(`/api/${apiSummary}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setPdfUploadPer(percentCompleted);
          },
        });

        const prompt_tokens =
          response?.data?.prompt_tokens || "No prompt_tokens ";
        const completion_tokens =
          response?.data?.completion_tokens || "No completion_tokens";
        const total_tokens = response?.data?.total_tokens || "No total_tokens";

        setTokens((prevTokens) => ({
          ...prevTokens,
          prompt_tokens,
          completion_tokens,
          total_tokens,
        }));

        const pdfUrl = URL.createObjectURL(file);
        const pdfName = file.name;
        const pdfId = Date.now().toString();
        const chatMessage = {
          author: "bot",
          type: "text",
          data: { text: response.data.ai_answer },
        };
        const newPdf = {
          id: pdfId,
          url: pdfUrl,
          name: pdfName,
          chat: [],
          file: file,
        };
        setPdfDataList((prevList) => [newPdf, ...prevList]);
        setSelectedPdfId(pdfId);

        setChatHistories((prevHistories) => ({
          ...prevHistories,
          [pdfId]: [chatMessage],
        }));

        setMessage(chatMessage);
      } catch (error) {
        setOpen({ error: true });
        if (error.response && error.response?.status === 500) {
          setError("Internal server error");
        } else {
          setError(error.message || "An error occured");
        }
      } finally {
        setIsLoading(false);
        setPdfUploadPer(0);

        const file = files[0];
        const filename = file.name.slice(0, 20);
        const filesize = `${(file.size / (1024 * 1024)).toFixed(2)} MB`;
        const currentTimeUTC = new Date().toISOString();

        const options = {
          hour12: false,
          timeZone: "UTC",
          timeZoneName: "short",
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };

        const currentTime = new Date(currentTimeUTC).toLocaleString(
          "en-US",
          options
        );
        const userName = sessionStorage.getItem("Name");

        const newLog = {
          currentTime,
          userName,
          application: appName,
          filename,
          filesize,
        };

        let storedLogs = JSON.parse(localStorage.getItem("UserLogsArray"));

        if (!Array.isArray(storedLogs) || !storedLogs.length) {
          storedLogs = [];
        }

        storedLogs.unshift(newLog);

        localStorage.setItem("UserLogsArray", JSON.stringify(storedLogs));
      }
    }

    setIsLoading(false);
    event.target.value = null;
  };

  const handlePdfSelect = (pdfId) => {
    setSelectedPdfId(pdfId);
  };

  const handlePdfDelete = (pdfId) => {
    const updatedPdfDataList = pdfDataList.filter(
      (pdfDataItem) => pdfDataItem.id !== pdfId
    );
    setPdfDataList(updatedPdfDataList);
  };

  useEffect(() => {
    const selectedPdfExists = pdfDataList.some(
      (pdfDataItem) => pdfDataItem.id === selectedPdfId
    );

    if (!selectedPdfExists && pdfDataList.length > 0) {
      const nextPdfId = pdfDataList[0].id;
      setSelectedPdfId(nextPdfId);
    }
  }, [pdfDataList, selectedPdfId]);

  const updateChat = async (userInput) => {
    setIsLoadingAns(true);
    const userMessage = {
      author: "user",
      type: "text",
      data: { text: userInput },
    };

    setChatHistories((prevHistories) => ({
      ...prevHistories,
      [selectedPdfId]: [...(prevHistories[selectedPdfId] || []), userMessage],
    }));

    const selectedPdfData = pdfDataList.find(
      (pdfData) => pdfData.id === selectedPdfId
    );

    if (!selectedPdfData) {
      setIsLoadingAns(false);
      return;
    }

    const file = selectedPdfData.file;
    const question = userInput.toString();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("question", question);

    try {
      const response = await _http.post(`/api/${apiQuestion}`, formData, {
        responseType: "blob",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const contentType = response.headers["content-type"];

      if (contentType === "application/zip") {
        console.log("inside application/zip ");
        console.log(response.data, "response.data inside zip ");
        try {
          const zip = new JSZip();
          const zipFiles = await zip.loadAsync(response.data);
          const images = [];

          for (const fileName in zipFiles.files) {
            const file = zipFiles.files[fileName];
            if (!file.dir) {
              const imageData = await file.async("base64");
              images.push({
                name: fileName,
                data: `data:image/png;base64,${imageData}`,
              });
            }
          }

          const botMessageObj = {
            author: "bot",
            type: "images",
            data: { images },
          };

          setChatHistories((prevHistories) => ({
            ...prevHistories,
            [selectedPdfId]: [
              ...(prevHistories[selectedPdfId] || []),
              botMessageObj,
            ],
          }));

          setMessage({ ...botMessageObj, pdfId: selectedPdfId });
        } catch (error) {
          console.error("Error processing ZIP file:", error);
          alert(
            "Error: Corrupted ZIP file or missing bytes. Please try again."
          );
        }
      } else if (contentType === "application/json") {
        console.log("inside application/json");
        console.log(response, "response for json");
        console.log(response.data, "response.data for json");

        const reader = new FileReader();

        reader.onload = function (event) {
          const jsonResponse = JSON.parse(event.target.result);

          console.log(jsonResponse, " JSON data from the response");

          const botmessage = jsonResponse?.ai_answer || "No results";
          const prompt_tokens =
            jsonResponse?.prompt_tokens || "No prompt_tokens ";
          const completion_tokens =
            jsonResponse?.completion_tokens || "No completion_tokens";
          const total_tokens = jsonResponse?.total_tokens || "No total_tokens";

          setTokens((prevTokens) => ({
            ...prevTokens,
            prompt_tokens,
            completion_tokens,
            total_tokens,
          }));

          const botMessage = {
            author: "bot",
            type: "text",
            data: { text: botmessage },
          };
          setChatHistories((prevHistories) => ({
            ...prevHistories,
            [selectedPdfId]: [
              ...(prevHistories[selectedPdfId] || []),
              botMessage,
            ],
          }));

          setMessage({ ...botMessage, pdfId: selectedPdfId });
        };

        reader.readAsText(response.data);
      } else {
        console.log("Content-Type: 3", contentType);
        alert("response type is wrong");
      }

      setIsLoadingAns(false);
    } catch (error) {
      setOpen({ error: true });
      if (error.response && error.response?.status === 500) {
        setError("Internal server error");
      } else {
        setError(error.message || "An error occurred");
      }
      setIsLoadingAns(false);
    }
  };

  const updateChatHistories = (newChatHistories) => {
    setChatHistories(newChatHistories);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    console.log("Dropped files:", files);
    setIsDragOver(false);
  };

  const CloseSidebar = () => {
    dispatch(toggleSidebar());
  };

  return (
    <section>
      <div
        className={`
        ${isSideBar ? "sidebar" : "hideSideBar"} 
        ${theme === "dark" ? "sidebar-dark-border " : "sidebar-light-border "} 
        ${theme === "dark" ? "" : "light-background"} `}
      >
        {isToggleOn && pdfDataList.length > 0 && (
          <div className="arrow">
            <img
              src={!isMainSidebarOn ? openmainSidebar : closemainSidebar}
              alt="icon"
              className={
                isMainSidebarOn ? "open-mainsidebar" : "close-mainsidebar"
              }
              data-tooltip-id="tooltip-arrow"
              data-tooltip-content={
                isMainSidebarOn ? "Close Sidebar" : "Open Sidebar"
              }
              onClick={() => {
                dispatch(toggleMainSidebar());
              }}
            />
          </div>
        )}

        <div className="heading-containerr">
          {" "}
          <div className="page-info-headingg">{appName}</div>
          {pdfDataList.length === 0 ? (
            <div className="description-boxx">
              <UnderStandButtonPdf modalContent={modalContent} />
            </div>
          ) : (
            <div
              onClick={CloseSidebar}
              data-tooltip-id="tooltip-arrow"
              data-tooltip-content={"Close"}
            >
              <img style={{ height: "21px" }} src={openSidebar} alt="icon" />
            </div>
          )}
        </div>

        <div>
          <div>
            <button
              className={`${
                theme === "dark" ? "newLight-border" : "newDark-border"
              } 
              ${theme === "dark" ? "dark-background" : "light-background"}
              newchat-button`}
              onClick={handleFileUpload}
            >
              + New Chat
            </button>
            <div className="or-line">
              <hr />
              <p>or</p>
              <hr />
            </div>
            <div
              className={`drag-drop-input ${isDragOver ? "drag-over" : ""} ${
                theme === "dark" ? "dark-background" : "light-background"
              } `}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onClick={handleFileUpload}
            >
              <img src={uploadIcon} className="mx-2" alt="icon" />
              <p>
                Drag and drop or <br /> Upload a CSV or XLSX file <br /> (max
                200 MB)
              </p>
            </div>

            <div className="pdf-sizeError">{pdfSize}</div>
          </div>
          <>
            <input
              type="file"
              accept=".csv ,.xlsx"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              multiple
            />
            {
              <SnackBar
                message={`The file name ${fileName} already exists.`}
                severity="info"
                Open={open.selectedfile}
                handleClose={handleClose}
              />
            }
            {
              <SnackBar
                message={`File ${fileName} is not supported. Only CSV or XLSX file is allowed.`}
                severity="info"
                Open={open.Allowedfile}
                handleClose={handleClose}
              />
            }{" "}
            {
              <SnackBar
                message={errors}
                severity={"error"}
                handleClose={handleClose}
                Open={open.error}
              />
            }
          </>
          {isLoading && (
            <div className="pdf-Uploaded progress">
              <div
                className={`${
                  theme === "dark" ? "dark-button  " : "light-button "
                } progress-bar`}
                role="progressbar"
                aria-valuenow={pdfUploadPer}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${pdfUploadPer}% ` }}
              >
                {pdfUploadPer}%{" "}
              </div>
            </div>
          )}
          <div style={{ height: "calc(100vh - 370px)", overflow: "auto" }}>
            <div className="uploaded-pdfs">
              {pdfDataList.map((pdfData) => (
                <div key={pdfData.id} className="uploaded-pdf">
                  <div className="pdf-info">
                    <div
                      className={`uploaded-pdf-button ${
                        pdfData.id === selectedPdfId
                          ? `${
                              theme === "dark"
                                ? "selected-pdf-button-dark "
                                : "selected-pdf-button-light "
                            }`
                          : null
                      } `}
                      onClick={() => handlePdfSelect(pdfData.id)}
                      title={pdfData.name}
                    >
                      <div style={{ width: "90%", overflow: "auto" }}>
                        {" "}
                        {pdfData.name}
                      </div>
                      <button
                        className="delete-pdf-button"
                        onClick={() => handlePdfDelete(pdfData.id)}
                        title="Delete PDF"
                      >
                        <DeleteOutlineIcon />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="loader">
          {" "}
          <NewLoder />{" "}
        </div>
      ) : pdfDataList.length === 0 ? (
        <div
          className={`gpt-image ${
            theme === "dark" ? "dark-background" : "light-header"
          }`}
          style={{ height: "auto" }}
        >
          <div>
            <img src={tiles} alt="img" />
          </div>
          <div>
            <h4>{dis}</h4>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex" }} className="pdf-output">
          <div>
            {!isSideBar && pdfDataList.length > 0 && (
              <div className="heading-containerr1">
                <div onClick={() => dispatch(toggleSidebar())}>
                  <img
                    style={{ height: "21px" }}
                    src={openSidebar}
                    alt="icon"
                    data-tooltip-id="tooltip-arrow"
                    data-tooltip-content={"Open File Uploader"}
                  />
                </div>
                <div className="page-info-headingg">{appName}</div>
              </div>
            )}
            <div className={isSideBar ? "pdf-viewer" : "pdf-viewer1"}>
              {pdfDataList.map(
                (pdfData, i) =>
                  pdfData.id === selectedPdfId && (
                    <span style={{ height: "inherit" }} key={i}>
                      {CurrentFileType === "xlsx" && (
                        <div
                          key={pdfData.id}
                          style={{
                            height: "inherit",
                            width: "100%",
                          }}
                          className={`${
                            theme === "dark" ? "light-text" : " "
                          } `}
                        >
                          <PdfViewer url={pdfData.url} fileType="xlsx" />
                        </div>
                      )}
                      {CurrentFileType === "csv" && (
                        <div
                          key={pdfData.id}
                          style={{
                            height: "inherit",
                            width: "100%",
                          }}
                          className={`${
                            theme === "dark" ? "light-text" : " "
                          } `}
                        >
                          <PdfViewer url={pdfData.url} fileType="csv" />
                        </div>
                      )}
                    </span>
                  )
              )}
            </div>
          </div>
          <div style={{ height: "inherit", minWidth: "calc(100% - 450px)" }}>
            {pdfDataList.map(
              (pdfData) =>
                pdfData.id === selectedPdfId && (
                  <ChatSectionPlots
                    key={pdfData.id}
                    pdfData={pdfData}
                    message={message}
                    updateChat={updateChat}
                    onChatUpdate={updateChat}
                    chatHistories={chatHistories}
                    uniqueIdentifier={pdfData.id}
                    updateChatHistories={updateChatHistories}
                    isLoadingAns={isLoadingAns}
                    pageInfoData={pageInfoData}
                    handelMnimizePdf={handelMnimizePdf}
                    tpromptTokens={tokens.prompt_tokens}
                    completionTokens={tokens.completion_tokens}
                    totalTokens={tokens.total_tokens}
                    pdfName={pdfData.name}
                  />
                )
            )}
          </div>
        </div>
      )}
      <Tooltip
        id="tooltip-arrow"
        place="bottom"
        style={{ fontSize: "16px", padding: "7px" }}
      />
    </section>
  );
};

export default ChatPlots;
