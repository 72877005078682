import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import _http from "./../../Utils/Api/_http";
import Navbar from "./NavBar/Navbartab";
import Sidebar from "./Sidebar/SideBar";
import { useTheme } from "../../Features/DarkMode/ThemeContext";

import {
  toggleImage,
  selectIsToggleOn,
  selectIsMainSidebarOn,
  toggleMainSidebar,
  selectIsSidebarOn,
  toggleSidebar,
  selectIsImageOn,
} from "../../Redux/ReduceSidebar";
import SnackBar from "../../Features/SnackBar";

const HomeMain = ({ responseData }) => {
  const isToggleOn = useSelector(selectIsToggleOn);
  const isMinSideBar = useSelector(selectIsMainSidebarOn);
  const isSideBar = useSelector(selectIsSidebarOn);
  const isImageOn = useSelector(selectIsImageOn);
  const dispatch = useDispatch();
  const location = useLocation();
  const [isActive, setIsActive] = useState();
  const [userImage, setUserImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState("");
  const { theme } = useTheme();
  const fetchImageFromAPI = () => {
    const name = sessionStorage.getItem("Name");

    _http
      .post(
        "/api/view_img",
        { username: name },
        { responseType: "arraybuffer" }
      )
      .then((response) => {
        if (response.data) {
          const arrayBuffer = response.data;
          const blob = new Blob([arrayBuffer], { type: "image/png" });
          const imageUrl = URL.createObjectURL(blob);
          setUserImage(imageUrl);
        }
      })
      .catch((error) => {
        console.error("Error fetching user image: ", error);
        setOpen(true);
        setErrors("Error fetching user image");
      });
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (userImage === null || isImageOn === false) {
      fetchImageFromAPI();
      !isImageOn &&
        dispatch(() => {
          toggleImage();
        });
      return;
    }
  }, [isImageOn]);

  useEffect(() => {
    handlePathChange(location.pathname);
    console.log("pathname changed called");
  }, [location.pathname]);

  useEffect(() => {
    isActive && !isMinSideBar && dispatch(toggleMainSidebar());
    isActive && !isSideBar && dispatch(toggleSidebar());
  }, [isActive, isMinSideBar, dispatch, isSideBar]);

  const handlePathChange = (path) => {
    setIsActive(
      path === "/Home" ||
        path === "/TokenUsage" ||
        path === "/MyAIproject" ||
        path === "/logs" ||
        path === "/Settings" ||
        path === "/GenerativeAI" ||
        path === "/Notification"
    );
  };

  return (
    <>
      <Navbar userImage={userImage} />

      <main
        className={`contents ${!isActive && !isMinSideBar ? "minsideBar" : ""} 
        ${theme === "dark" ? "dark-theme" : "light-theme"} 
        ${theme === "dark" ? "text-white" : "text-dark"}  `}
      >
        {isToggleOn && (
          <Sidebar userImage={userImage} responseData={responseData} />
        )}
        <>
          <Outlet />
        </>
      </main>

      {
        <SnackBar
          message={errors}
          severity={"error"}
          handleClose={handleClose}
          Open={open}
        />
      }
    </>
  );
};

export default HomeMain;
