// MyStore.js
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./ReduceSidebar";
import { persistStore, PERSIST } from "redux-persist";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: PERSIST,
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
