import React, { useEffect, useState } from "react";
import "./portlogs.css";
import ReactPaginate from "react-paginate";
import resetIcon from "./../../Assets/ChatSectionImages/resetIcon.svg";

function PortLogs() {
  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(tableData.length / itemsPerPage);
  const [Loading, setLoading] = useState(true);

  // pagenation
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayedData = tableData.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );
  const fetchData = async () => {
    try {
      const response = await fetch("api_url");
      if (!response.ok) {
        alert("Network response was not ok");
        return;
      }
      const jsonData = await response.json();

      setTableData(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className={`safe-digging-back  `} style={{ overflowX: "scroll" }}>
      <div>
        <div className="safe-digging-header">
          <div className="uplaod-box">
            <div className="uplaod-box-heading">Logs</div>

            <div className="portkey-box">
              <div>
                <button>
                  {" "}
                  <img
                    style={{ height: "25px", margin: "5px" }}
                    src={resetIcon}
                    alt="reg"
                  />
                </button>
              </div>
              <div style={{ display: "flex" }} className="form-outline  ">
                <div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    style={{ width: "600px" }}
                  />
                </div>
                <button className="btn">Search</button>
              </div>
              <div>
                <select
                  style={{ width: "150px" }}
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected>select </option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                  <label class="form-check-label" for="flexSwitchCheckDefault">
                    Live logs
                  </label>
                </div>
              </div>
              <div>
                <button>Export</button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <table className="custom-table">
            <thead className="custom-table-header ">
              <th>TimeStamp</th>
              <th>Model</th>
              <th>Path</th>
              <th>user</th>
              <th>Tokens</th>
              <th>Status</th>
              <th>Feedback Account</th>
            </thead>
            <tbody className="custom-table-body">
              <tr class="gap-row-header"></tr>
              {Loading ? (
                <>
                  {[...Array(5)].map((_, rowIndex) => (
                    <tr key={rowIndex}>
                      {[...Array(14)].map((_, colIndex) => (
                        <td key={colIndex} className="placeholder-glow">
                          <div className="placeholder col-12 my-2">..</div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  {displayedData.map((rowData) => (
                    <>
                      <tr key={rowData.id} className="custom-table-row">
                        <td>{rowData.email_received_time} </td>
                        <td>{rowData.email_receive_from} </td>
                        <td>{rowData.subject} </td>
                        <td>{rowData.no_of_attachment} </td>
                        <td>{rowData.project_name} </td>
                        <td>{rowData.type_drawing} </td>
                        <td>{rowData.email_extraction} </td>
                      </tr>

                      <tr class="gap-row"></tr>
                    </>
                  ))}
                </>
              )}
            </tbody>
          </table>
          {Loading ? null : (
            <div className="pagination-container">
              <ReactPaginate
                pageCount={pageCount}
                onPageChange={handlePageChange}
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                containerClassName="pagination-container"
                pageClassName="pagination-page"
                activeClassName="pagination-active"
                breakClassName="pagination-break"
                previousClassName="pagination-previous"
                nextClassName="pagination-next"
                previousLinkClassName={"page-next-prev"}
                nextLinkClassName={"page-next-prev"}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default PortLogs;
