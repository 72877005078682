import { useEffect } from "react";

function InteractToolsLlogs({ application, filename, filesize }) {
  useEffect(() => {
    const currentTimeUTC = new Date().toISOString();

    const options = {
      hour12: false,
      timeZone: "UTC",
      timeZoneName: "short",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    const currentTime = new Date(currentTimeUTC).toLocaleString(
      "en-US",
      options
    );

    const userName = sessionStorage.getItem("Name");

    const newLog = {
      currentTime,
      userName,
      application,
      filename,
      filesize,
    };

    let storedLogs = JSON.parse(localStorage.getItem("UserLogsArray"));

    if (!Array.isArray(storedLogs) || !storedLogs.length) {
      storedLogs = [];
    }

    storedLogs.unshift(newLog);

    localStorage.setItem("UserLogsArray", JSON.stringify(storedLogs));
  }, [application, filename, filesize]);

  return null;
}

export default InteractToolsLlogs;
