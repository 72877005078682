import React from "react";
import ChatWithFiles from "../../../Components/ChatWith/ChatWithFiles";

export default function ChatWithTxt() {
  return (
    <ChatWithFiles
      appName="TextTalk"
      type="TXT"
      sliceNumber={4}
      fileType=".txt"
      apiSummary="textsummary"
      apiQuestion="textquestion"
      modalContent={
        <>
          <p>
            1) Upload or Drag the TXT.
            <br></br>
            2) The uploaded TXT is displayed to the right.
            <br></br>
            3) A ChatBot with a TXT summary and sample questions are displayed
            to the right of the TXT.
            <br></br>
            4) Ask questions and get the answer from the ChatBot.
            <br></br>
            5) Other features like Bookmark, Reset, Delete, Share, and Export
            are also present.
            <br></br>
          </p>
        </>
      }
      accept=".txt"
      maxsize={32}
      dis="Interactive Textual Analysis Tool"
    />
  );
}
