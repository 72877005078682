import React from "react";
import ChatWithFiles from "../../../Components/ChatWith/ChatWithFiles";

export default function ChatWithDB() {
  return (
    <ChatWithFiles
      appName="DataSpeak"
      type="DB"
      sliceNumber={8}
      fileType=".sqlite3"
      apiSummary="dbsummary"
      apiQuestion="dbquestion"
      modalContent={
        <>
          <p>
            1) Upload or Drag the DB.
            <br></br>
            2) The uploaded DB is displayed to the right.
            <br></br>
            3) A ChatBot with a DB summary and sample questions are displayed to
            the right of the DB.
            <br></br>
            4) Ask questions and get the answer from the ChatBot.
            <br></br>
            5) Other features like Bookmark, Reset, Delete, Share, and Export
            are also present.
            <br></br>
          </p>
        </>
      }
      accept=".sqlite3"
      maxsize={32}
      dis="AI-Driven Database Dialogue"
    />
  );
}
